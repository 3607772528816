import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'currentTime'
})
export class currentTimePipe implements PipeTransform {
  transform(data: any, startTime: string, duration: string): boolean {
    if (Object.keys(data).length === 0) {
      return false;
    }
    var currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
    var startTimeLocal = moment.utc(startTime).local().format('YYYY-MM-DD HH:mm:ss');
    var endTime = moment.utc(startTime)
      .add(duration, 'seconds').local()
      .format('YYYY-MM-DD HH:mm:ss');
    if (
      moment(currentTime).isSameOrAfter(startTimeLocal) &&
      moment(currentTime).isBefore(endTime)
    ) {
      return true;
    } else {
      return false;
    }
  }
}
