import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BaseComponent } from '@base/component/base.component';
import { HeaderService } from '@header/services/header.service';
import * as moment from 'moment';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthguardService extends BaseComponent implements CanActivate {

    refereshToken: {};

    constructor(private headerService: HeaderService) {
        super();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('canActivate');
        return this.getAuthenticateToken();
    }

    getAuthenticateToken(): any {
        console.log('guest');
        return this.headerService.getToken().pipe(map(res => {
            if (res !== undefined) {
                this.setGuestToken('Bearer ' + res.data['token']);
                var body = res.data['token'];
                var base64Url = body.split('.')[1];
                var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join(''));
                this.refereshToken = JSON.parse(jsonPayload);

                var dt = new Date(this.refereshToken['exp'] * 1000);
                BaseComponent.guestTime = moment(dt).subtract(5, 'seconds').format('HH:mm:ss').toString();
                console.log('guest');
            }

            // return this.getAuthenticate();

        }));
        // return true;

    }

    getAuthenticate(): boolean {
        if (BaseComponent.guestTime !== '') {
            return true;
        }
    }

}