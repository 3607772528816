import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HomeComponent } from '../component/home.component';
import { HomeService } from '@home/services/home.service';
import { RouterModule } from '@angular/router';
import { BaseModule } from '@base/module/base.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { GoToVideoPage } from '@home/component/pipes/gotovideopage.pipe';
import { AuthguardService } from '@base/services/auth_gard.service';
import { GotoCarousel } from '@home/component/pipes/gotocarousel.pipe';

@NgModule({
    declarations: [HomeComponent, GoToVideoPage, GotoCarousel],
    imports: [CommonModule, RouterModule, InfiniteScrollModule],
    exports: [],
    providers: [HomeService, AuthguardService]
})

export class HomeModule {

}
