import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetGroupDetailsComponent } from '@asset-detail/component/asset-group-details.component';
import { AssetGroupDetailService } from '@asset-detail/services/asset-group-details.service';

@NgModule({
  declarations: [AssetGroupDetailsComponent],
  imports: [
    CommonModule
  ],
  exports: [AssetGroupDetailsComponent],
  providers: [AssetGroupDetailService]
})
export class AssetGroupDetailsModule { }
