import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';

@Component({
  selector: 'app-premium',
  templateUrl: '../view/premium.component.html',
  styleUrls: ['../css/premium.component.css']
})
export class PremiumComponent extends BaseComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
