import { FooterService } from '@footer/services/footer.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { HeaderService } from '@header/services/header.service';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from '@angular/forms';
import { NavigationData, NavigationUpdate } from '@header/model/header.model';
import { ActivatedRoute, Router, Params, NavigationEnd } from '@angular/router';
import { GetUserProfile } from '@profile/model/profile.model';
import { ProfileService } from '@profile/service/profile.service';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: '../view/header.component.html',
  styleUrls: ['../css/header.component.css']
})
export class HeaderComponent extends BaseComponent implements OnInit {
  public registrationForm: FormGroup = new FormGroup({
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    dob: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  });

  public loginform: FormGroup = new FormGroup({
    email_id: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  forgotPasswordform: FormGroup = new FormGroup({
    email_id: new FormControl('', [Validators.required, Validators.email])
  });

  searchValue: string;

  constructor(
    public formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public router: Router,
    public _router: Router,
    _activatedRoute: ActivatedRoute
  ) {
    super();
    // this.route = _activatedRoute;
    this.router = _router;
    this.profileService = ServiceLocator.injector.get(ProfileService);
    this.headerService = ServiceLocator.injector.get(HeaderService);
    this.footerService = ServiceLocator.injector.get(FooterService);
  }

  public NavigationParams = new URLSearchParams();
  public PageCategoryParams = new URLSearchParams();
  public headerService: HeaderService;
  public isLogin: boolean;
  navigationData: NavigationData;
  public currentUrl = '';
  public currentPageCategoryParams: string = '';
  public profileService: ProfileService;
  public footerService: FooterService;
  public isProfileImage: string = '';
  activePath: string;
  refereshToken: {};
  logo: string;

  ngOnInit() {
    if (
      BaseComponent.baseUser === undefined ||
      Object.keys(BaseComponent.baseUser).length === 0
    ) {
      this.getProfile();
    } else {
      this.isProfileImage = BaseComponent.baseUser.picture;
    }

    this.getAuthenticateToken(true);

    if (localStorage.getItem('user_token') !== null) {
      this.getNewUserToken();
    }

    setInterval(() => {
      if (localStorage.getItem('user_token') === null) {
        var curruentTime = moment().format('HH:mm:ss');
        if (curruentTime === BaseComponent.guestTime) {
          this.getAuthenticateToken(false);
        }
      }
    }, 1000);


    setInterval(() => {
      if (localStorage.getItem('user_token') !== null) {
        var curruentTime = moment().format('HH:mm:ss');
        if (curruentTime === BaseComponent.userTime) {
          this.getNewUserToken();
        }
      }
    }, 1000);


    this.isLogin = BaseComponent.isLogin;

    this.headerService.reloadHeader.subscribe(res => {
      console.log('reloadHeader');
      this.getAuthenticateToken(true);
      // this.getNavigation();

    });

    this.headerService.setLogin.subscribe(res => {
      this.isLogin = res;
      if (res) {
        this.getProfile();
      }
    });

    this.headerService.setActivePath.subscribe(res => {
      if (res !== undefined && res !== '') {
        this.activePath = res;
      }
    });

    // $('.list-menu ul li a').click(function () {
    //   $(this).addClass('active');
    // });

    // console.log('-=--------------header');
    // this.route.queryParams.subscribe((params: Params) => {
    //   this.currentUrl = this.router.routerState.snapshot.url;
    //   console.log(this.currentUrl);
    //   this.currentPageCategoryParams = params['path'];
    //   console.log(params);
    //   if (params['path'] != null) {
    //     setTimeout(() => {
    //       const caarousalRequest = {
    //         path: params['path'],
    //         type: params['type']
    //       };
    //       if (this.navigationData != null) {
    //         this.headerService.setHomePagePath.emit(caarousalRequest);
    //       }
    //     }, 10);
    //   }
    // });

    this.headerService.setProfileImg.subscribe(res => {
      if (res) {
        this.isProfileImage = res;
      } else {
        console.log('Error');
      }
    });

    $(document).mouseup(function (e) {

      var searchSuggestion = $(".searchInputMobile");
      var searchMobile = $(".search-box-mobile");
      if (!searchMobile.is(e.target) && searchMobile.has(e.target).length === 0) {
        if (!searchSuggestion.is(e.target) && searchSuggestion.has(e.target).length === 0) {
          $(".search-box-mobile").fadeOut(500);
        }

      }
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        var text = event.url;

        var currentActivePath = text.replace('/', '');
        this.activePath = currentActivePath.toLowerCase();
        // if (event.urlAfterRedirects.substr(1).toLocaleLowerCase() != 'live' &&
        //   event.urlAfterRedirects.substr(1).toLocaleLowerCase() != 'channels' &&
        //   event.urlAfterRedirects.substr(1).toLocaleLowerCase() != 'movies' &&
        //   event.urlAfterRedirects.substr(1).toLocaleLowerCase() != 'events') {
        //   this.activePath = '';
        // }
      }
    });

  }

  public gotoAuthentication(): void {
    localStorage.setItem('videoUrl', this.router.url)
    this.router.navigate(['auth']);
  }

  public gotoProfile(url): void {
    const myurl = `${url}`;
    this.router.navigateByUrl(myurl);
  }

  public userSignUp(url): void {
    const myurl = `${url}`;
    this.router.navigateByUrl(myurl);
  }

  public goToHomePage() {
  const navigationData = this.navigationData.data;
  for (const navigate of navigationData) {
      if (navigate.title.trim().toUpperCase() === 'HOME') {
        // this.router.navigate([''], {
        //   queryParams: { path: navigate.path, type: navigate.type }
        // });
        this.router.navigate(['', navigate.title.trim().toLowerCase()]);
        return;
      }
    }
  }

  public getNavigation(): void {
    // this.NavigationParams.set('deviceTypeId', '1');
    // this.NavigationParams.set('langaugeCode', '1');
    const navigationObject = {
      deviceTypeId : 1,
      langaugeCode : 1
    }
    this.headerService.getNavigation(navigationObject).subscribe(
      res => {
        if (res !== undefined) {
          this.navigationData = res.data;
          this.logo = res.data.logo;
          if(this.logo != undefined) {
            this.footerService.reloadFooter.emit(res.data.logo);
          }
          this.footerService.reloadFooter.subscribe(res => {
            console.log('reloadFooter');
          });
          BaseComponent.globalNavigationData = res.data;

          // var data = btoa(res);
          // console.log(data);
          // var jsondata = atob(data);
          // console.log(JSON.parse(JSON.stringify(jsondata)));

          for (const navigate of this.navigationData.data) {
            if (navigate.title.trim().toUpperCase() === 'HOME') {
              var url = this.router.url.split('?')[0];
              if (url == '/' || url == '')
                // this.router.navigate([''], {
                //   queryParams: { path: navigate.path, type: navigate.type }
                // });
                this.router.navigate(['', navigate.title.trim().toLowerCase()]);
              // this.getPageCategorie(navigate, false);
              // const caarousalRequest = {
              //   path: navigate.path,
              //   type: navigate.type
              // };
              // this.headerService.setHomePagePath.emit(caarousalRequest);
              return;
            } activatedRoute: ActivatedRoute
          }
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  public getPageCategorie(navigation: NavigationUpdate, reset: boolean): void {
    $('#sidebar').removeClass('active');
    $('.overlay').removeClass('active');
    // if (reset) {
    //   if (this.router.url !== '') {
    //     if (navigation.path == '18355') {
    //       this.router.navigate(['vuuzle-tv'], {
    //         queryParams: { path: navigation.path }
    //       });
    //     } else {
    //       this.router.navigate([''], {
    //         queryParams: { path: navigation.path, type: navigation.type }
    //       });
    //       const caarousalRequest = {
    //         path: navigation.path,
    //         type: navigation.type
    //       };
    //       this.headerService.setHomePagePath.emit(caarousalRequest);
    //     }
    //   } else {
    //     if (this.currentPageCategoryParams === navigation.path) {
    //       const caarousalRequest = {
    //         path: navigation.path,
    //         type: navigation.type
    //       };
    //       this.headerService.setHomePagePath.emit(caarousalRequest);
    //     }
    //   }
    // } else {
    //   if (this.router.url === '') {
    //     if (navigation.path == '18355') {
    //       this.router.navigate(['vuuzle-tv'], {
    //         queryParams: { path: navigation.path }
    //       });
    //     } else {
    //       this.router.navigate([''], {
    //         queryParams: { path: navigation.path, type: navigation.type }
    //       });
    //     }
    //   }
    // }
    console.log(navigation);
    // if (navigation.path == 'primaryNavigationType') {
    //   // this.router.navigate(['vuuzle-tv'], {
    //   //   queryParams: { path: navigation.path, type: navigation.type }
    //   // });
    //   this._router.navigate(['channels']);
    //   return;
    // } else {
      // this.router.navigate(['menu'], {
      //   queryParams: { path: navigation.path, type: navigation.type }
      // });
     if (navigation.primaryNavigationType == 'Live') {
      this.router.navigate(['ThorvsEddie']);
     }
     else {
      this.router.navigate(['', navigation.title.split(" ").join("").toLowerCase()]);
      return;
     }
  }

  public getAuthenticateToken(isNavigate: boolean): void {
    this.headerService.getToken().subscribe(
      res => {
        if (res !== undefined) {
          this.setGuestToken('Bearer ' + res);
          var body = res;
          var base64Url = body.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
          this.refereshToken = JSON.parse(jsonPayload);

          var dt = new Date(this.refereshToken['exp'] * 1000);
          BaseComponent.guestTime = moment(dt).subtract(50, 'seconds').format('HH:mm:ss').toString();
          if (isNavigate) {
            this.getNavigation();
          }

        }
      },
      err => {
        console.log(err);
      }
    );
  }

  public getNewUserToken(): void {
    this.headerService.getUserToken().subscribe(
      res => {
        if (res !== undefined) {
          this.refereshToken = null;
          this.setUserToken('Bearer ' + res.data.token);
          var body = res.data.token;
          var base64Url = body.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
          this.refereshToken = JSON.parse(jsonPayload);
          var dt = new Date(this.refereshToken['exp'] * 1000);
          BaseComponent.userTime = moment(dt).subtract(50, 'seconds').format('HH:mm:ss').toString();

        }
      },
      err => {
        console.log(err);
      }
    );
  }

  onKeydown(event, value) {
    if (event.key === 'Enter') {
      this.suggestions(value);
      $('#searchInput').val('');
      $('.searchInputMobile').val('');
      $(".search-box-mobile").fadeOut(500);
    }
  }

  suggestions(keyword) {
    if (keyword.trim().length >= 3) {
      const routeUrl = this.router.url;
      if (!routeUrl.includes('/search')) {
        this.router.navigate(['/search', keyword]);
      } else {
        this.headerService.setSearchPath.emit(keyword);
        this.router.navigate(['/search', keyword]);
      }
    } else {
      this.errMessageResponse('Please enter minimum 3 characters');
    }
  }

  public getProfile(): void {
    var userToken = this.getUserToken();
    if (userToken !== '' && userToken !== null) {
      const getUserProfile = new GetUserProfile();
      getUserProfile.uid = this.getUserId()
      this.profileService.getUserProfile(getUserProfile.uid).subscribe(
        res => {
          if (res !== undefined && res.success === true) {
            BaseComponent.userEmail = res.data[0].emailId;
            BaseComponent.userFName = res.data[0].firstName;
            BaseComponent.userLName = res.data[0].lastName;
            BaseComponent.baseUser = res.data[0];
            this.isProfileImage = BaseComponent.baseUser.picture;
            BaseComponent.isLogin = true;
            this.isLogin = true;
          }
        },
        err => {
          console.log(err);
        }
      );
    }

  }

  public closeModel(): void {
    $('#sidebar').removeClass('active');
    $('.overlay').removeClass('active');
  }
}
