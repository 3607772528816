import { Usage } from './../../home/model/pagecategory.model';
import { environment } from '@environments/environment';
import { DOCUMENT } from '@angular/common';
import { PageData } from '@home/model/pagecategory.model';
import { BaseComponent } from '@base/component/base.component';
import { Component, OnInit, Renderer2, Inject, OnDestroy } from '@angular/core';
import { ServiceLocator } from '@base/constants/service-locator';
import { VideoPageService } from '@video-page/services/videopage.service';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
// import * as THEOplayer from '../../../assets/vendor/bootstrap/js/THEOplayer.js';

declare var videojs: any;
declare var $: any;
declare var THEOplayer:any;

@Component({
  selector: 'app-video-page-player',
  templateUrl: '../view/video-page-player.component.html',
  styleUrls: ['../css/video-page-player.component.css']
})
export class VideoPagePlayerComponent extends BaseComponent implements OnInit, OnDestroy {

  currentId: any;
  assetData: any = [];
  user: boolean;

  videourl: any;
  videoPoster: any;
  setBookMark = false;
  bookmarkDuration: any;
  watchListTimeBitmovin = 5;
  addedToWatchList = 0;
  watchMovie = false;
  watchTrailer = false;
  videoFinish = false;
  videoError: boolean;
  trailerPlayBackUrl: string;
  casUserWatchAsset: number;
  geoStatus: number;
  isPremium: number;
  isShowVideo: boolean;
  rented: boolean;
  contentType: string;
  subScriptionData = [];
  handler: any;
  posterImage = false;
  onInitloader = true;
  trailerStart: boolean;
  trailerVideoError: boolean;
  // playerObject: any;
  player;
  constructor(activateRoute: ActivatedRoute,
              private rendrer: Renderer2,
              private videoPageService: VideoPageService,
              private cookieService: CookieService,
              @Inject(DOCUMENT) private document: Document) {
    super();
    this.route = activateRoute;
  }

  ngOnInit() {
    this.rendrer.removeClass(this.document.body, 'transperent-layout');
    this.rendrer.addClass(this.document.body, 'hide-header');
    this.rendrer.addClass(this.document.body, 'hide-footer');
    if (localStorage.getItem('user_token')) {
      this.user = true;
    } else {
      this.user = false;
    }
    this.watchMovie = false;
    // this.isShowVideo = false;
    $(document).ready(function () {
      $(this).scrollTop(0);

    });
    this.route.params.subscribe(params => {
      this.videoFinish = false;
      this.currentId = Number(params.id);
      this.videourl = '';
      this.addedToWatchList = 0;
      this.assetData = undefined;
      this.trailerPlayBackUrl = '';
      this.videoPoster = '';
      this.casUserWatchAsset = 0;
      this.subScriptionData = null;
      window.scroll(0, 0);
      this.getAssetDetail(this.currentId);
    });
  }

  getAssetDetail(id) {
    const assetDetailRequest = { deviceTypeId: '1' };
    // const width = window.innerWidth;
    // const height = window.innerHeight;
    const cookie = this.cookieService.get('readDisclaimer');
    var dnt = 0;
    if (cookie == 'true') {
        dnt = 1;
      } else {
        dnt = 0;
      }
    // const userAgent = window.navigator.userAgent;
    const assetDetailFiterString = '?filter=' + encodeURI(JSON.stringify(assetDetailRequest));
    // + '&width=' + width + '&height=' + height + '&dnt=' + dnt + '&ua=' + userAgent;
    this.videoPageService.getAssetDetails(id, assetDetailFiterString).subscribe(res => {
      if (res !== undefined && res.success == true) {
        console.log(res.data[0])
      this.assetData = res.data[0];
      this.videoPoster = this.assetData.horizontalFilePath;
      this.videourl = this.assetData.playbackUrl;
      this.bookmarkDuration = this.assetData.bookmarkDuration;
      if (this.bookmarkDuration == null) {
        this.bookmarkDuration = '0';
      }
      this.trailerPlayBackUrl = this.assetData.mapTrailerVideo;
      this.isPremium = this.assetData.isPremium;
      this.casUserWatchAsset = this.assetData.casUserWatchAsset;
      this.geoStatus = this.assetData.geoStatus;
      this.contentType = this.assetData.contentType;
      this.onInitloader = false;
      // this.geoStatus = 0;
      // this.isPremium = 1;
      // this.casUserWatchAsset = 0;
      if (this.geoStatus == 1) {
        if (this.user == true && !this.isPremium) {
          this.watchMovie = true;
          this.isShowVideo = true;
          this.setBookMark = true;
          this.watchTrailer = false;
          this.showVideo(this.videourl, this.videoPoster, this.watchMovie, this.watchTrailer);
        } else if (this.user == true && this.isPremium && this.casUserWatchAsset) {
          this.watchMovie = true;
          this.isShowVideo = true;
          this.setBookMark = true;
          this.watchTrailer = true;
          this.showVideo(this.videourl, this.videoPoster, this.watchMovie, this.watchTrailer);
        } else if (this.user == false && !this.isPremium) {
          this.watchMovie = true;
          this.isShowVideo = true;
          this.setBookMark = false;
          this.watchTrailer = false;
          this.showVideo(this.videourl, this.videoPoster, this.watchMovie, this.watchTrailer);
        } 
        else if (this.trailerPlayBackUrl !=''  && this.isPremium && !this.casUserWatchAsset) {
          this.watchMovie = true;
          this.isShowVideo = true;
          this.setBookMark = true;
          this.watchTrailer = true;
          this.showVideo(this.trailerPlayBackUrl, this.videoPoster, this.watchMovie, this.watchTrailer);
        }
    
        else if (this.user == true && this.isPremium && !this.casUserWatchAsset) {
          this.watchMovie = false;
          this.watchTrailer = true;
          this.isShowVideo = false;
          this.setBookMark = false;
          this.subScriptionData = null;
          const subscriptionRequest = { 'deviceTypeId': '1', 'assetId': this.currentId };
          const subscriptionFiterString = '?filter=' + encodeURI(JSON.stringify(subscriptionRequest));
          this.videoPageService.getSubScriptionList(subscriptionFiterString).subscribe(res => {
            if (res != undefined && res.success == true) {
              const rentPlan = res.data.data;
              for (let plan of rentPlan) {
                if (plan.subscriptionType == 'TVOD') {
                  this.rented = true;
                  break;
                }
              }
            }
          });
          this.showVideo(this.videourl, this.videoPoster, this.watchMovie, this.watchTrailer);
        } else if (this.user == false && this.isPremium) {
          this.watchMovie = false;
          this.isShowVideo = false;
          this.setBookMark = false;
          this.subScriptionData = null;
          const subscriptionRequest = { 'deviceTypeId': '1', 'assetId': this.currentId };
          const subscriptionFiterString = '?filter=' + encodeURI(JSON.stringify(subscriptionRequest));
          this.videoPageService.getSubScriptionList(subscriptionFiterString).subscribe(res => {
            if (res != undefined && res.success == true) {
              const rentPlan = res.data.data;
              for (let plan of rentPlan) {
                if (plan.subscriptionType == 'TVOD') {
                  this.rented = true;
                  break;
                }
              }
            }
          });
          this.showVideo(this.videourl, this.videoPoster, this.watchMovie, this.watchTrailer);
        }
      } else {
        this.watchMovie = false;
        this.setBookMark = false;
        this.isShowVideo = false;
        $('#premium-id').hide();
        $('#image-display').hide();
        // $('#pip-close').hide();
      }
      }
    }, err => {
      this.onInitloader = false;
      console.log(err);
    });
  }

  showVideo(playbackUrl, poster, watchMoview, watchTrailer) {
    var self = this;
//     this.playerObject = videojs(document.querySelector('.video-js'), {
//   language: 'es'
// });
    if(watchMoview == true && this.geoStatus == 1) {
      const element = document.querySelector('.theoplayer-container');
      this.player = new THEOplayer.Player(element, {
        libraryLocation: 'https://cdn.myth.theoplayer.com/c69d7e91-49de-4237-aa42-da5a20c2723f/',
        license: 'sZP7IYe6T6fzIKCiTQfoTmzzIDCZFSaLTQh-ClhrIOzoIubL3Dhr3uaZIlf6FOPlUY3zWokgbgjNIOf9fKBL0LftCoakFSXl0Sa-3QIK36z_CDIgFSUg3L5k3o46Clho0ZfVfK4_bQgZCYxNWoryIQXzImf90SCt3SBkTSfi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3laoTSBk0lfZ0ueoFOPeWok1dDrLYtA1Ioh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfKxqWDXNWG3ybojkbK3gflNWf6E6FOPVWo31WQ1qbta6FOPzdQ4qbQc1sD4ZFK3qWmPUFOPLIQ-LflNWfK1zWDikfgzVfG3gWKxydDkibK4LbogqW6f9UwPkIYz',
        mutedAutoplay: 'all'
      });
     const Addurl= 'https://www10.smartadserver.com/ac?siteid=412186&pgid=1384397&fmtid=100892&ab=2&tgt=&oc=1&out=vast3&ps=1&pb=0&visit=S&vcn=s&vph=1080&vpw=1920&vpmt=2&vpaidv=0&vpaidt=0&srl=www.segi.tv.stage.dctinc.net&buid=com.segi.tv&appname=segi_tv&uid=560288&tmstp=1622227563';

      this.player.source = {
        sources: [
          {
            src: playbackUrl, // 'https://cdn.theoplayer.com/video/elephants-dream/playlist-single-audio.m3u8',
            type: 'application/x-mpegurl', // sets type to MPEG-DASH,
            lowLatency: false
          }],
          ads: [
            {
              sources: Addurl,
              timeOffset: "start"
            },
            {
              sources: Addurl,
              timeOffset: "start"
            },
            {
              sources: Addurl,
              timeOffset: "00:03:30"
            },
            {
              sources: Addurl,
              timeOffset: "00:07:00"
            },
            {
              sources: Addurl,
              timeOffset: "00:15:00"
            },
            {
              sources: Addurl,
              timeOffset: "00:45:00"
            },
            {
              sources: Addurl,
              timeOffset: "end"
            }
          ]
      };

      this.player.autoplay = true
      if (self.addedToWatchList == 0) {
            if (self.user == true) {
              self.addToWatchlist();
            }
          }
      // this.playerObject.src({src: playbackUrl, type: 'application/x-mpegURL'});
      // this.playerObject.poster(poster);

    //   const addToBookmarkJavascript = function (current_playback_position) {
    //   if (current_playback_position != 0) {
    //     if (self.setBookMark) {
    //       if (self.user == true) {
    //         const pageRequest = {
    //           assetId: self.assetData.path,
    //           duration: current_playback_position
    //         };
    //         self.videoPageService.addToBookmark(pageRequest).subscribe(result => {
    //           if (result !== undefined && result.success == true) {
    //             self.bookmarkDuration = result.data.duration;
    //           }
    //         });
    //       }
    //     }
    //   }
    // };

  //     const addToBookmarkOnTimeUpdateJavascript = function () {
  //   let currPlaybackTime = self.playerObject.currentTime();
  //   if (currPlaybackTime != 0) {
  //     let diffInTime = 0;
  //     if (parseInt(currPlaybackTime) > parseInt(self.bookmarkDuration)) {
  //       diffInTime = parseInt(currPlaybackTime) - parseInt(self.bookmarkDuration);
  //     } else {
  //       diffInTime = parseInt(self.bookmarkDuration) - parseInt(currPlaybackTime);
  //     }
  //     if (diffInTime >= self.watchListTimeBitmovin) {
  //       // addToBookmarkJavascript(currPlaybackTime);
  //     }
  //   }
  // };

  //     const onTimeChanged = function() {
  //   if (self.setBookMark) {
  //     addToBookmarkOnTimeUpdateJavascript();
  //   }
  //   if (self.addedToWatchList == 0) {
  //     if (self.user == true) {
  //       self.addToWatchlist();
  //       self.playerObject.off('timeupdate', () => { });
  //     }
  //   }
  // };

    //   const onTimePaused = function() {
    //   let currPlaybackTime = self.playerObject.currentTime();
    //   // addToBookmarkJavascript(currPlaybackTime);
    // };

    //   const onVideoPlay = function() {

    // };

      const onFinished = function() {
      self.videoFinish = true;
    };

      const onError = function() {
      self.videoFinish = true;
      self.videoError = true;
    };
      // const onLoadedData = function() {
      //   self.seekPlayer(0, true, self.setBookMark);
      // }

    //   if(this.contentType != 'live') {
    //   self.playerObject.on('timeupdate', onTimeChanged);
    //   self.playerObject.on('pause', onTimePaused);
    //   self.playerObject.on('ended', onFinished);
    // }
      // self.playerObject.on('play', onVideoPlay);
      // self.playerObject.on('error', onError);
      // self.playerObject.on('loadedmetadata', onLoadedData);
    //   setTimeout(()=> {
    //     self.playerObject.play();
    // }, 1000);
    } else if(watchTrailer == true && this.geoStatus == 1) {
      // self.playerObject.src({src: playbackUrl, type: 'application/x-mpegURL'});
      // self.playerObject.poster(poster);

      const onTrailerVideoPlay = function() {
      $('#premium-id').hide();
      $('#image-display').hide();
      self.trailerStart = true;
    };

      const onTrailerFinished = function() {
      $('#premium-id').show();
      $('#image-display').show();
      self.trailerStart = false;
    };

      const onTrailerError = function() {
      $('#premium-id').hide();
      $('#image-display').hide();
    };

  //     self.playerObject.on('ended', onTrailerFinished);
  //     self.playerObject.on('play', onTrailerVideoPlay);
  //     self.playerObject.on('error', onTrailerError);
  //     setTimeout(()=> {
  //     self.trailerStart = true;
  //     self.playerObject.play();
  // }, 1000);
  }
  }

  // seekPlayer(duration, videoDuration: boolean, setBookMark: boolean) {

  //   if (videoDuration) {
  //     if (setBookMark) {
  //       if((this.playerObject.duration() - this.bookmarkDuration) > 30) {
  //         this.playerObject.currentTime(this.playerObject.currentTime() + this.bookmarkDuration);
  //       } else {
  //         this.playerObject.currentTime(this.playerObject.currentTime() + duration);
  //       }
  //     }
  //   } else {
  //     this.playerObject.currentTime(this.playerObject.currentTime() + duration);
  //   }

  // }

  public addToWatchlist(): void {
    if (this.watchMovie == true) {
      const pageRequest = {
        assetId: this.assetData.path
      };
      // let filterString = "?filter="+encodeURI(JSON.stringify(pageRequest));
      this.videoPageService.addToWatchlist(pageRequest).subscribe(
        res => {
          if (res !== undefined && res.success == true) {
            // this.videopageData.watchedCount += 1;
          }
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  checkout(item, e) {
    this.handler = (<any>window).StripeCheckout.configure(
      {
        key: environment.stripeKey,
        locale: 'auto',
        token: (token: any) => {
          let cardToken = token.id;
          const orderReq = {
            orderId: cardToken,
            assetId: this.currentId,
            userId: this.getUserId(),
            subscriptionId: item.path,
            isRecurring: 1,
            billingName: BaseComponent.userFName,
            billingEmail: BaseComponent.userEmail,
            status: 'Active',
            usageType: 'Paid',
            isActive: 1,
            paymentMethod: 'stripe'
          };
          this.videoPageService.placeOrder(orderReq).subscribe(
            res => {
              if (res !== undefined && res.success === true) {
                this.successResponse(res.message);
                $('#rent-list').modal('hide');
                this.ngOnInit();
                // this.successMessageResponse();
                // this.successMessageResponse('remove to fav');
              } else if (res !== undefined && res.success === false) {
                this.errMessageResponse(res.message);
              }
            },
            err => {
              console.log(err);
            }
          );
        },
      }
    );
    let totalAmount = item.rate * 100;
    if (totalAmount > 0) {
      this.handler.open({
        name: 'Nation Traffic',
        description: item.title,
        amount: totalAmount,
        email: BaseComponent.userEmail
      });
    }
    console.log(this.handler);

    e.preventDefault();
  }

  getPremiumForSubscriptionList() {
    this.subScriptionData = null;
    const subscriptionRequest = { 'deviceTypeId': '1', 'assetId': this.currentId };
    const subscriptionFiterString = '?filter=' + encodeURI(JSON.stringify(subscriptionRequest));
    this.videoPageService.getSubScriptionList(subscriptionFiterString).subscribe(res => {
      if (res != undefined && res.success == true) {
        const rentPlan = res.data.data;
        for (let plan of rentPlan) {
          if (plan.subscriptionType == 'TVOD') {
            this.subScriptionData.push(plan);
          }
        }
      }
    });
  }

  goRent() {
    if (BaseComponent.isLogin == true) {
      $('#rent-list').modal('show');
      this.getPremiumForSubscriptionList();
    } else {
      localStorage.setItem('videoUrl', this.router.url);
      this.router.navigate(['auth']);
    }
  }

  public gotoVideoPage(pagedata: PageData): void {
    // if (this.playerObject !== undefined) {
    //   this.playerObject.pause();
    // }

    // $('#posterImage').css('display', 'none');
    // if (pagedata.type === 'asset') {
      this.router.navigate(['/video', pagedata.title.split(/\s/).join(''), pagedata.path]);
    // } else if (pagedata.type === 'asset_group') {
    //   this.router.navigate(['/season', pagedata.path]);
    // }
  }

  ngOnDestroy(): void {
    if (this.user == true && this.assetData) {
      // if(this.playerObject != undefined) {
      //   const pageRequest = {
      //     assetId: this.assetData.path,
      //     duration: this.playerObject.currentTime()
      //   };
      //   this.videoPageService.addToBookmark(pageRequest).subscribe(result => res => {
      //     if (res !== undefined && res.success == true) {
      //     }
      //   },
      //     err => {
      //       console.log(err);
      //     }
      //   );
      // }
    }
    // if (this.playerObject !== undefined) {
    //   this.playerObject.dispose();
    // }
    if (this.player != undefined) {
      this.player.destroy();                       
    }
  }

}
