
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiService } from '@base/services/base.api.service';
import { BaseComponent } from '@base/component/base.component';
import { ServiceLocator } from '@base/constants/service-locator';
import { METHOD_REFERENCE, API_REFERENCE, API_PATH } from '@base/constants/base.constants';
import { GetUserProfile, GetProfileResponse, ChangePassword, ChangePasswordResponse } from '@profile/model/profile.model';


@Injectable()
export class ProfileService {
    public apiService: ApiService;
    public baseComponent: BaseComponent;

    constructor() {
        this.apiService = ServiceLocator.injector.get(ApiService);
        this.baseComponent = ServiceLocator.injector.get(BaseComponent);
    } 


    public getUserProfile(uid): Observable<GetProfileResponse> {
        const options = this.baseComponent.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['profile']['get'] + uid,
            {}, null, null, null, false, true, API_PATH);
        return this.apiService.apiCall(options);
    }

    public createNewPassword(changePassword: ChangePassword): Observable<ChangePasswordResponse> {
        const options = this.baseComponent.getRequestOptions(
            METHOD_REFERENCE['POST'], API_REFERENCE['profile']['passwordChange'],
            changePassword, null, null, null, false, true, API_PATH);
        return this.apiService.apiCall(options);
    }

    public getWatchlist(watchListObject): Observable<any> {
        const options = this.baseComponent.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['profile']['getwatchlist'] + watchListObject,
            {}, null, null, null, false, true, API_PATH);
        return this.apiService.apiCall(options);
    }

    public getfavourite(): Observable<any> {
        const options = this.baseComponent.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['profile']['getfavourite'] ,
            {}, null, null, null, false, true, API_PATH);
        return this.apiService.apiCall(options);
    }

    public getOrderListing(): Observable<any> {
        const options = this.baseComponent.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['profile']['orderListing'],
            {}, null, null, null, false, true, API_PATH);
        return this.apiService.apiCall(options);
    }

    public downloadInvoice(fiterString): Observable<any> {
        const options = this.baseComponent.getRequestOptions(
            METHOD_REFERENCE['GET'], API_REFERENCE['profile']['downloadInvoice'] + fiterString,
            {}, null, null, null, false, true, API_PATH);
        return this.apiService.apiCall(options);
    }
}