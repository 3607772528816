export class VerifyOtp {
    device_type: any;
    otp: any;
    email: any;
    first_name: string;
    last_name: string;
    password: any;
    dob: any;
}

export class NavigationUpdate { //Tejash
    // path: string;
    // alias: string;
    // type: string;
    // device: string;
    // same_for_all_orientation: boolean;
    // order_by: string;
    // sorting: string;
    // display_indexing: number;
    // orientation: Orientation[];
    // menu_category: [];

    path: string;
    title: string;
    sameForAllZone: number;
    sameForAllProductType: number;
    exclusionText: string;
    label: string;
    description: string;
    mapWithHeaderOrBurgerMenu: string;
    isCarouselApplicable: number
    displayIndexing: number;
    primaryNavigationType: string;
    fileName: string;
    filePath: string;
    type: string;
    menuCategory: []
}

export class Orientation {
    name: string;
    title: string;
    label: string;
    is_visible: boolean;
    description: string;
    is_header: boolean;
    is_burger: boolean;
    is_carousal: boolean;
    image: string;
}

export class NavigationData {
    data: NavigationUpdate[];
}
