import { ShowSchedule } from './../model/schedule.model';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFilter',
  pure: true
})
export class DateFilterPipe implements PipeTransform {
  tempscheduleList: ShowSchedule[];
  scheduleFilterList: ShowSchedule;
  // nullData: ShowSchedule;

  transform(showData: ShowSchedule[], selectedDate: string): ShowSchedule[] {
    var scheduleFilter: ShowSchedule[];
    if (showData == undefined || showData.length == 0) {
      scheduleFilter = [];
      for (let i = 0; i <= 50; i++) {
        var nullData = {
          title: '',
          description: '',
          external_id: '',
          start: '',
          asset: '',
          offset: 0,
          duration: '',
          type: '',
          date: '',
          dateChange: ''
        };
        scheduleFilter.push(nullData);
      }
      return scheduleFilter;
    }

    this.tempscheduleList = JSON.parse(JSON.stringify(showData));
    scheduleFilter = this.tempscheduleList.filter(
      content =>
        moment.utc(content.start).local().format('ddd | MMM Do YYYY') == selectedDate
    );
    if (scheduleFilter.length > 50) {
      scheduleFilter.splice(50, scheduleFilter.length);
    }

    var schedulelength = 50 - scheduleFilter.length;
    for (let i = 0; i <= schedulelength; i++) {
      var nullData = {
        title: '',
        description: '',
        external_id: '',
        start: '',
        asset: '',
        offset: 0,
        duration: '',
        type: '',
        date: '',
        dateChange: ''
      };
      scheduleFilter.push(nullData);
    }
    return scheduleFilter;
  }
}
