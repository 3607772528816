import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';

@Component({
  selector: 'app-live-concert',
  templateUrl: '../view/live-concert.component.html',
  styleUrls: ['../css/live-concert.component.css']
})
export class LiveConcertComponent extends BaseComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
  }

}
