import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { FooterLinkContentService } from '../services/footerLinkContent.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { FooterLinkContentModule } from '../module/footerLinkContent.module';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-footer-content',
  templateUrl: '../view/footerLinkContent.component.html',
  styleUrls: ['../css/footerLinkContent.component.css']
})

export class FooterLinkContentComponent extends BaseComponent implements OnInit {

  footerLinkContentService: FooterLinkContentService;
  footerLinkContentData: any;
  footerLinkContent: any;
  path: any;
  constructor(public router: Router, public activatedRoute: ActivatedRoute,
              private rendrer: Renderer2,
              @Inject(DOCUMENT) private document: Document) {
    super();
    this.footerLinkContentService = ServiceLocator.injector.get(FooterLinkContentService);
    // this.activatedRoute.queryParams.subscribe((res: any) => {
    //   this.path = res.path;
    // });
    this.route = activatedRoute;
  }

  ngOnInit() {
    this.rendrer.removeClass(this.document.body, 'transperent-layout');
    this.rendrer.removeClass(this.document.body, 'hide-header');
    this.rendrer.removeClass(this.document.body, 'hide-footer');
    this.route.params.subscribe((params: Params) => {
      this.path = params['path'];
    });
    this.getFooterLinksContentData(this.path);
  }

  public getFooterLinksContentData(path): void {
    const pageRequest = {
      path: this.path,
      deviceTypeId: "1",
      langId: "1"
    }

    let fiterString = "?filter="+encodeURI(JSON.stringify(pageRequest));
    this.footerLinkContentService.getFooterLinkContent(path,fiterString).subscribe(
      (res: any) => {
        if (res !== undefined) {
          this.footerLinkContentData = res.data[0];
          if (this.footerLinkContentData) {
            this.footerLinkContent = res.data[0];
            document.getElementById('html').insertAdjacentHTML('beforeend', this.footerLinkContent.description);
          }
        }
      },
      err => {
        console.log(err);
      }
    );

  }

}
