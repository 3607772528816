import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'utcTolocal'
})
export class UtcToLocalTimePipe implements PipeTransform {
    transform(startTime: string): string {
        if (startTime !== '') {
            return moment.utc(startTime).local().format('hh:mm a');
        } else {
            return '';
        }

    }
}
