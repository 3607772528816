import { API_PATH, API_PATH_DOLLYWOOD } from './../constants/base.constants';
import { environment } from '@environments/environment';
import { OnInit, Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ServiceLocator } from '@base/constants/service-locator';
import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { ToastService } from '@base/services/toast.service';
import { GetProfileResponse, ProfileData } from '@profile/model/profile.model';
import { NavigationData } from '@header/model/header.model';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class BaseComponent {
  public static user: string;
  public static isSignUp: boolean;
  public static isLogin: boolean;
  public static profileComplete: boolean=true;
  public static userEmail: string;
  public static userFName: string;
  public static userLName: any;
  public static forgotPasswordEmail: any;
  public static baseUser: ProfileData;
  public static globalNavigationData: NavigationData;
  public static logo: string;

  public static guestTime: string;
  public static userTime: string;

  public router: Router;
  public route: ActivatedRoute;
  public imagePrefix = environment.imagePrefix;

  public userToken: string;
  public guestToken: string;
  // public toastService: ToastService;
  public toastr: ToastrService

  constructor() {
    this.router = ServiceLocator.injector.get(Router);
    this.route = ServiceLocator.injector.get(ActivatedRoute);
    // this.toastService = ServiceLocator.injector.get(ToastService);
    this.toastr = ServiceLocator.injector.get(ToastrService);
  }
  public setGuestToken(token: string) {
    localStorage.setItem('guest_token', token);
  }

  public setUserToken(token: string) {
    localStorage.setItem('user_token', token);
  }
  public getUserToken() {
    return localStorage.getItem('user_token');
  }

  public getGuestToken() {
    return localStorage.getItem('guest_token');
  }

  public getUserId() {
    return localStorage.getItem('user');
  }

  public setUserId(user: string) {
    localStorage.setItem('user', user);
  }

  public successResponse(message) {
    this.toastr.success(message, 'Success');
  }

  public errMessageResponse(message) {
    this.toastr.error(message, 'Error');
  }

  public warningMessageResponse(message: string) {
    this.toastr.warning(message, 'Warning');
  }

  public successWithoutTitleResponse(message) {
    this.toastr.success(message, '');
  }



  public getRequestOptions(
    method: any,
    url: any,
    data: any,
    params: URLSearchParams = new URLSearchParams(),
    headers: Headers = new Headers(),
    isFormData: boolean = false,
    isUrlParams: boolean = false,
    reportProgress: boolean = false,
    apiUrl: string = ''
  ) {
    const headerSettings: { [name: string]: string | string[] } = {};

    if (headers != null && !isFormData) {
      headerSettings['Content-Type'] = 'application/json';
    }

    if (this.getUserToken()) {
      headerSettings['Authorization'] = this.getUserToken();
    } else if (this.getGuestToken()) {
      headerSettings['Authorization'] = this.getGuestToken();
    }
    // headerSettings['Access-Control-Allow-Origin'] = 'http://localhost:4200';
    const header = new HttpHeaders(headerSettings);
    const httpInit = {
      headers: header,
      search: params
    };

    return new HttpRequest(method, apiUrl + url, data, httpInit);
  }

  // public getFacebookPixle() {
  //   console.log('getFacebookPixle');
  //   (function () {
  //     var a;
  //     a = 'c=22e01187cbbde2a&host=a.clickcertain.com&wld=x.clickcertain.com&ccid=5a278114-eaa5-429c-a4e4-8e19ec8f1b21&geo=IN&rid=4cb2e761-e12b-448b-abe3-d0d72ec9dba8';
  //     (function () {
  //       var b, c, d, e, f, g, h, i, j, k, l, m, n;
  //       k = function (a) {
  //         var b, c, d, e;
  //         b = [];
  //         for (c = d = 0, e = a; 0 <= e ? d < e : d > e; c = 0 <= e ? ++d : --d) b.push(c);
  //         return b;
  //       };
  //       i = function (a) {
  //         var b, c, d, e, f;
  //         b = a.split("&");
  //         f = {};
  //         for (c = 0, d = b.length; c < d; c++) {
  //           e = b[c];
  //           e = e.split("=");
  //           f[e[0]] = e[1];
  //         }
  //         return f;
  //       };
  //       g = function (a) {
  //         var b, c, d, e, f, g;
  //         d = a[0];
  //         b = a[1];
  //         g = k(d.length);
  //         for (e = 0, f = d.length; e < f; e++) {
  //           c = d[e];
  //           g[d[c]] = b[c];
  //         }
  //         return g.join('');
  //       };
  //       h = function () {
  //         var b, c;
  //         b = "x.clickcertain.com";
  //         c = i(a).wld;
  //         if (c != null) return c;
  //         return b;
  //       };
  //       l = function (a) {
  //         var b, c, d, e;
  //         d = 'adnxs';
  //         b = ['secure'];
  //         b.push(d);
  //         e = 'com';
  //         b.push(e);
  //         if ('https:' === document.location.protocol) {
  //           if (a.indexOf("https") === -1) a = a.replace("http://", "https://");
  //           c = h();
  //           if (a.indexOf(c) > -1) a = a.replace(c, b.join('.'));
  //         }
  //         return a;
  //       };
  //       e = function (a) {
  //         var c;
  //         a = b.decode(a);
  //         c = typeof JSON !== "undefined" && JSON !== null ? JSON.parse(a) : void 0;
  //         if (c == null) c = eval("(" + a + ")");
  //         return c;
  //       };
  //       j = function (b) {
  //         var c, d, f, h, i;
  //         i = [];
  //         for (d = 0, f = b.length; d < f; d++) {
  //           c = b[d];
  //           h = g(c);
  //           n(e(h));
  //           i.push(a);
  //         }
  //         return i;
  //       };
  //       m = function (a) {
  //         var b;
  //         b = "x.clickcertain.com";
  //         if (a.indexOf(b) > -1) a = a.replace(b, h());
  //         a = l(a);
  //         return a;
  //       };
  //       c = function (b) {
  //         var c, d;
  //         d = "brighttag.com/";
  //         if (b.indexOf(d) > -1) {
  //           c = i(a);
  //           b = b + "&ccid=" + c.ccid + "&geo=" + c.geo + "&rid=" + c.rid;
  //         }
  //         return b;
  //       };
  //       n = function (a) {
  //         var b, d, e, f, g, h, i, j, k, l, n, o;
  //         for (d = 0, g = a.length; d < g; d++) {
  //           n = a[d];
  //           k = n[0];
  //           l = n[1];
  //           j = document.createElement(k);
  //           for (e = 0, h = l.length; e < h; e++) {
  //             i = l[e];
  //             f = i[0];
  //             o = i[1];
  //             if (f === "src") {
  //               o = m(o);
  //               o = c(o);
  //             }
  //             j[f] = o;
  //           }
  //           b = document.getElementsByTagName('body')[0];
  //           b.appendChild(j);
  //         }
  //       };
  //       f = [
  //         [
  //           [8, 43, 7, 21, 31, 17, 36, 10, 46, 45, 16, 44, 38, 28, 32, 39, 2, 14, 27, 23, 29, 1, 9, 40, 18, 5, 12, 30, 19, 34, 41, 20, 13, 0, 33, 24, 26, 25, 3, 42, 15, 37, 6, 4, 11, 35, 22],
  //           ["iLCA", "mE", "ic3R5bGU", "o", "mNvbS9w", "dLCBbI", "mV", "d", "dXQ==", "1", "eTpub25lOyJ", "iXV", "tcGl4ZWw", "dLCBbInNyYyIsICIvL3Mu", "eD9z", "mZHNpZD0yM", "i", "o", "iMSJ", "i", "dGhlYnJpZ2h0", "1s", "i", "mUwMTE4N2N", "mhl", "iwgW", "aWJpbGl0", "dGFnL", "aW", "o", "iY", "d", "eTp", "W", "aXRlPU5pV3NWY0", "d2lk", "iLCA", "dGg", "aW", "mRlM", "aWRkZW47ZGlzcGxh", "mZXJyZXI9Y2M", "1s", "1nI", "mlz", "mc", "dCIsICIxIl0sIFs"]
  //         ]
  //       ];
  //       b = {
  //         _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',
  //         decode: function (a) {
  //           var c, d, e, f, g, h, i, j, k;
  //           k = '';
  //           j = 0;
  //           a = a.replace(/[^A-Za-z0-9\+\/\=]/g, '');
  //           while (j < a.length) {
  //             f = this._keyStr.indexOf(a.charAt(j++));
  //             g = this._keyStr.indexOf(a.charAt(j++));
  //             h = this._keyStr.indexOf(a.charAt(j++));
  //             i = this._keyStr.indexOf(a.charAt(j++));
  //             c = (f << 2) | (g >> 4);
  //             d = ((g & 15) << 4) | (h >> 2);
  //             e = ((h & 3) << 6) | i;
  //             k = k + String.fromCharCode(c);
  //             if (h !== 64) k = k + String.fromCharCode(d);
  //             if (i !== 64) k = k + String.fromCharCode(e);
  //           }
  //           k = b._utf8_decode(k);
  //           return k;
  //         },
  //         _utf8_decode: function (a) {
  //           var b, c, d, e, f, g;
  //           g = '';
  //           f = 0;
  //           b = c = d = 0;
  //           while (f < a.length) {
  //             b = a.charCodeAt(f);
  //             if (b < 128) {
  //               g += String.fromCharCode(b);
  //               f++;
  //             } else if ((b > 191) && (b < 224)) {
  //               d = a.charCodeAt(f + 1);
  //               g += String.fromCharCode(((b & 31) << 6) | (d & 63));
  //               f += 2;
  //             } else {
  //               d = a.charCodeAt(f + 1);
  //               e = a.charCodeAt(f + 2);
  //               g += String.fromCharCode(((b & 15) << 12) | ((d & 63) << 6) | (e & 63));
  //               f += 3;
  //             }
  //           }
  //           return g;
  //         }
  //       };
  //       d = function () {
  //         return j(f);
  //       };
  //       if (document.readyState === "complete" || document.readyState === "interactive") d();
  //       else if (window.addEventListener) window.addEventListener("load", d, false);
  //       else if (window.addEventListener) { window.addEventListener("onload", d); }
  //       else d();
  //       return a;
  //     })();
  //   }).call(this);
  //   //cached
  // }
  public getFacebookPixle() {
    console.log('getFacebookPixle');
    (function () {
      var a;
      a = 'c=22e01187cbbde2a&host=a.clickcertain.com&wld=x.clickcertain.com&ccid=5a278114-eaa5-429c-a4e4-8e19ec8f1b21&geo=IN&rid=4cb2e761-e12b-448b-abe3-d0d72ec9dba8';
      (function () {
        var b, c, d, e, f, g, h, i, j, k, l, m, n;
        k = function (a) {
          var b, c, d, e;
          b = [];
          for (c = d = 0, e = a; 0 <= e ? d < e : d > e; c = 0 <= e ? ++d : --d) b.push(c);
          return b;
        };
        i = function (a) {
          var b, c, d, e, f;
          b = a.split("&");
          f = {};
          for (c = 0, d = b.length; c < d; c++) {
            e = b[c];
            e = e.split("=");
            f[e[0]] = e[1];
          }
          return f;
        };
        g = function (a) {
          var b, c, d, e, f, g;
          d = a[0];
          b = a[1];
          g = k(d.length);
          for (e = 0, f = d.length; e < f; e++) {
            c = d[e];
            g[d[c]] = b[c];
          }
          return g.join('');
        };
        h = function () {
          var b, c;
          b = "x.clickcertain.com";
          c = i(a).wld;
          if (c != null) return c;
          return b;
        };
        l = function (a) {
          var b, c, d, e;
          d = 'adnxs';
          b = ['secure'];
          b.push(d);
          e = 'com';
          b.push(e);
          if ('https:' === document.location.protocol) {
            if (a.indexOf("https") === -1) a = a.replace("http://", "https://");
            c = h();
            if (a.indexOf(c) > -1) a = a.replace(c, b.join('.'));
          }
          return a;
        };
        e = function (a) {
          var c;
          a = b.decode(a);
          c = typeof JSON !== "undefined" && JSON !== null ? JSON.parse(a) : void 0;
          if (c == null) c = eval("(" + a + ")");
          return c;
        };
        j = function (b) {
          var c, d, f, h, i;
          i = [];
          for (d = 0, f = b.length; d < f; d++) {
            c = b[d];
            h = g(c);
            n(e(h));
            i.push(a);
          }
          return i;
        };
        m = function (a) {
          var b;
          b = "x.clickcertain.com";
          if (a.indexOf(b) > -1) a = a.replace(b, h());
          a = l(a);
          return a;
        };
        c = function (b) {
          var c, d;
          d = "brighttag.com/";
          if (b.indexOf(d) > -1) {
            c = i(a);
            b = b + "&ccid=" + c.ccid + "&geo=" + c.geo + "&rid=" + c.rid;
          }
          return b;
        };
        n = function (a) {
          var b, d, e, f, g, h, i, j, k, l, n, o;
          for (d = 0, g = a.length; d < g; d++) {
            n = a[d];
            k = n[0];
            l = n[1];
            j = document.createElement(k);
            for (e = 0, h = l.length; e < h; e++) {
              i = l[e];
              f = i[0];
              o = i[1];
              if (f === "src") {
                o = m(o);
                o = c(o);
              }
              j[f] = o;
            }
            b = document.getElementsByTagName('body')[0];
            b.appendChild(j);
          }
        };
        f = [
          [
            [8, 43, 7, 21, 31, 17, 36, 10, 46, 45, 16, 44, 38, 28, 32, 39, 2, 14, 27, 23, 29, 1, 9, 40, 18, 5, 12, 30, 19, 34, 41, 20, 13, 0, 33, 24, 26, 25, 3, 42, 15, 37, 6, 4, 11, 35, 22],
            ["iLCA", "mE", "ic3R5bGU", "o", "mNvbS9w", "dLCBbI", "mV", "d", "dXQ==", "1", "eTpub25lOyJ", "iXV", "tcGl4ZWw", "dLCBbInNyYyIsICIvL3Mu", "eD9z", "mZHNpZD0yM", "i", "o", "iMSJ", "i", "dGhlYnJpZ2h0", "1s", "i", "mUwMTE4N2N", "mhl", "iwgW", "aWJpbGl0", "dGFnL", "aW", "o", "iY", "d", "eTp", "W", "aXRlPU5pV3NWY0", "d2lk", "iLCA", "dGg", "aW", "mRlM", "aWRkZW47ZGlzcGxh", "mZXJyZXI9Y2M", "1s", "1nI", "mlz", "mc", "dCIsICIxIl0sIFs"]
          ]
        ];
        b = {
          _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',
          decode: function (a) {
            var c, d, e, f, g, h, i, j, k;
            k = '';
            j = 0;
            a = a.replace(/[^A-Za-z0-9\+\/\=]/g, '');
            while (j < a.length) {
              f = this._keyStr.indexOf(a.charAt(j++));
              g = this._keyStr.indexOf(a.charAt(j++));
              h = this._keyStr.indexOf(a.charAt(j++));
              i = this._keyStr.indexOf(a.charAt(j++));
              c = (f << 2) | (g >> 4);
              d = ((g & 15) << 4) | (h >> 2);
              e = ((h & 3) << 6) | i;
              k = k + String.fromCharCode(c);
              if (h !== 64) k = k + String.fromCharCode(d);
              if (i !== 64) k = k + String.fromCharCode(e);
            }
            k = b._utf8_decode(k);
            return k;
          },
          _utf8_decode: function (a) {
            var b, c, d, e, f, g;
            g = '';
            f = 0;
            b = c = d = 0;
            while (f < a.length) {
              b = a.charCodeAt(f);
              if (b < 128) {
                g += String.fromCharCode(b);
                f++;
              } else if ((b > 191) && (b < 224)) {
                d = a.charCodeAt(f + 1);
                g += String.fromCharCode(((b & 31) << 6) | (d & 63));
                f += 2;
              } else {
                d = a.charCodeAt(f + 1);
                e = a.charCodeAt(f + 2);
                g += String.fromCharCode(((b & 15) << 12) | ((d & 63) << 6) | (e & 63));
                f += 3;
              }
            }
            return g;
          }
        };
        d = function () {
          return j(f);
        };
        if (document.readyState === "complete" || document.readyState === "interactive") d();
        else if (window.addEventListener) window.addEventListener("load", d, false);
        else if (window.addEventListener) { window.addEventListener("onload", d); }
        else d();
        return a;
      })();
    }).call(this);
    //cached
  }

}
