import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ApiService {
  constructor(public http: HttpClient) { }
  apiCall(httpRequest: any): Observable<any> {
    return this.http.request(httpRequest).pipe(
      map((response: HttpEvent<any>) => this.getAPIResponse(response)));
  }
  getAPIResponse(httpEvent: HttpEvent<any>) {
    switch (httpEvent.type) {
      case HttpEventType.Sent:
        break;
      case HttpEventType.ResponseHeader:
        break;
      case HttpEventType.DownloadProgress:
        break;
      case HttpEventType.Response:
        var body = httpEvent.body;
        var base64Url = body.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
      // return httpEvent.body;
    }
  }

  apiCallWihtourtEncryption(httpRequest: any): Observable<any> {
    return this.http.request(httpRequest).pipe(
      map((response: HttpEvent<any>) => this.getAPIResponseWihtourtEncryption(response)));
  }
  getAPIResponseWihtourtEncryption(httpEvent: HttpEvent<any>) {
    switch (httpEvent.type) {
      case HttpEventType.Sent:
        break;
      case HttpEventType.ResponseHeader:
        break;
      case HttpEventType.DownloadProgress:
        break;
      case HttpEventType.Response:
        return httpEvent.body;
    }
  }

}
