import { ApiService } from '@base/services/base.api.service';
import { Injectable } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { ServiceLocator } from '@base/constants/service-locator';
import { Observable } from 'rxjs';
import { API_REFERENCE, METHOD_REFERENCE, API_PATH } from '@base/constants/base.constants';
import { UpdateProfile, UpdateProfileResponse } from '../model/edit-profile.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class EditProfileService {
    public apiService: ApiService;
    public baseComponent: BaseComponent;

    constructor() {
        this.apiService = ServiceLocator.injector.get(ApiService);
        this.baseComponent = ServiceLocator.injector.get(BaseComponent);
    }

    public updateProfile(updateProfile): Observable<any> {
        const options = this.baseComponent.getRequestOptions(
            METHOD_REFERENCE['POST'], API_REFERENCE['profile']['update'],
            updateProfile, null, null, null, false, true, API_PATH);
        return this.apiService.apiCall(options);
    }

    public uploadUserProfileImage(formData, userID: string): Observable<any> {
        const options = this.baseComponent.getRequestOptions(
            METHOD_REFERENCE['POST'], API_REFERENCE['profile']['uploadProfilePicture'] + userID,
            formData, null, null, null, false, true, API_PATH);
        return this.apiService.apiCall(options);
    }

    public updateUserProfileImage(formData): Observable<any> {
        const options = this.baseComponent.getRequestOptions(
            METHOD_REFERENCE['POST'], API_REFERENCE['profile']['updateProfilePicture'],
            formData, null, null, null, false, true, API_PATH);
        return this.apiService.apiCall(options);
    }

    public removeProfile(urlParam): Observable<any> {
        const options = this.baseComponent.getRequestOptions(
            METHOD_REFERENCE['POST'], API_REFERENCE['profile']['removeProfile'],
            urlParam, null, null, null, false, true, API_PATH);
        return this.apiService.apiCall(options);
    }
}
