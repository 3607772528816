import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationComponent } from '@authentication/component/authentication.component';
import { BaseModule } from '@base/module/base.module';
import { RouterModule } from '@angular/router';
import { AuthenticationService } from '@authentication/service/authentication.service';
import { SetNewPasswordComponent } from '@authentication/component/setnepassword.component';
import { ForgotPasswordComponent } from '@authentication/component/forgotpassword.component';
import {
  SocialLoginModule,
  AuthServiceConfig,
  FacebookLoginProvider,
  GoogleLoginProvider,
  LoginOpt
} from 'angularx-social-login';
import { environment } from '@environments/environment';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const googleLoginOptions: LoginOpt = {
  prompt: "select_account"
};
const config = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.facebookAuthId)
  },
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(
      environment.googleAuthKey,
      googleLoginOptions
    )
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AuthenticationComponent,
    ForgotPasswordComponent,
    SetNewPasswordComponent
  ],
  imports: [CommonModule, RouterModule, BaseModule, SocialLoginModule, BsDatepickerModule.forRoot(),
    BrowserAnimationsModule],
  providers: [
    AuthenticationService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ],
  exports: [
    AuthenticationComponent,
    ForgotPasswordComponent,
    SetNewPasswordComponent
  ]
})
export class AuthenticationModule { }
