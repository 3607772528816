import { CookieService } from 'ngx-cookie-service';
import { DOCUMENT } from '@angular/common';
import { Component, OnInit, OnDestroy, Inject, Renderer2 } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { AssetGroupDetailService } from '@asset-detail/services/asset-group-details.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { AssetGroupDetail, AssetCategotyDetail } from '@asset-detail/model/asset-group-detail.model';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { AssetGroupListing } from '@asset-detail/model/asset-group-listing.model';
import { VideoPageService } from '@video-page/services/videopage.service';
import { VideoPage, CastCrews, VideoPageData } from '@video-page/model/video-page';
import { HeaderService } from '@header/services/header.service';
import { environment } from '@environments/environment';
// import * as THEOplayer from '../../../assets/vendor/bootstrap/js/THEOplayer.js';


declare var THEOplayer: any;
declare var videojs: any;
declare var $: any;
@Component({
  selector: 'app-asset-group-details',
  templateUrl: '../view/asset-group-details.component.html',
  styleUrls: ['../css/asset-group-details.component.css']
})
export class AssetGroupDetailsComponent extends BaseComponent implements OnInit, OnDestroy {


  assetGroupService: AssetGroupDetailService;
  videoService: VideoPageService;
  assetGroupData: AssetGroupDetail;
  assetCategoryData: AssetCategotyDetail;
  assetGroupList: AssetGroupListing;
  skip: number;
  assetPath: string;
  videoPageData: VideoPageData;
  // playerObject: any;
  videoURL: string;
  posterImage: string;
  videoTitle: string;
  videoDescription: string;
  director: string;
  cast: string;
  castCrewData: CastCrews;
  viewMore: boolean;
  watchListTimeBitmovin: number = 5;
  addedToWatchList: any = 0;
  bookmarkDuration: any;
  loading: boolean;
  mainloading = true;
  headerService: HeaderService;
  trailerPlayBackUrl: string;
  isShowVideo: boolean = false;
  watchMovie: boolean = false;
  setBookMark: boolean = false;
  showVideoError: boolean = false;
  isShowImage: boolean = false;
  geoStatus: number;
  isIncreaseCount: boolean = false;
  showButton: boolean;
  playTimeOut: any;
  bitMovieUnsubsribe: any;
  noGenre: boolean;
  isUserLikes: any;
  videoPath: any;
  seasonPath: any = 0;
  currentIndex = 0;
  seconds = 5;
  videoFinish: boolean;
  replay: any;
  secondInterval: any;
  secondsTimeout: any;
  clickOntrailer = false;
  csaiAdServer: string;
  lastVideo = false;
  user: boolean;
  genreTitle = '';
  player;
  tvodData: any;
  tvodStatus = false;
  isUserLoggedIn = false;
  isPremium: boolean;
  casUserWatchAsset: boolean;
  rented: boolean = false;
  subScriptionData = [];
  playMovie = false;
  isVideoPlay = false;
  allowToWatchPremiumContent = false;
  tvodasset=false;
  tvodRate:any;
  tvodDays:any;
  currentpath :string;
  handler: any;
  pathId : any;
  assetType :any;
  assetgroupSubscriptionId: number;
  assetgeoStatus: boolean = false;
  assetGroup: any;
  isassetGroupPremium :boolean = false;
  assetID : string;
  redirectsearchUrl: any;
  redirectfavouriteUrl: any;
  redirectwatchListUrl: any;
  redirectpagecategoryUrl: any;
  expirydate: any;
  currentSeasonPath: any;

  constructor(activateRoute: ActivatedRoute,
    private rendrer: Renderer2,
    private cookieService: CookieService,
    @Inject(DOCUMENT) private document: Document) {
    super();
    this.assetGroupService = ServiceLocator.injector.get(AssetGroupDetailService);
    this.videoService = ServiceLocator.injector.get(VideoPageService);
    this.headerService = ServiceLocator.injector.get(HeaderService);
    this.route = activateRoute;
  }

  ngOnInit() {
    this.rendrer.removeClass(this.document.body, 'transperent-layout');
    this.rendrer.removeClass(this.document.body, 'hide-header');
    this.rendrer.removeClass(this.document.body, 'hide-footer');
    this.redirectsearchUrl = localStorage.getItem('searchUrl');
    this.redirectfavouriteUrl = localStorage.getItem('favouriteUrl');
    this.redirectwatchListUrl = localStorage.getItem('watchListUrl');
    this.redirectpagecategoryUrl = localStorage.getItem('pagecategoryUrl');
    if (localStorage.getItem('user_token')) {
      this.user = true;
      this.isUserLoggedIn = true;
    } else {
      this.user = false;
      this.isUserLoggedIn = false;
    }
    // window.onscroll = this.adjustPlayer;
    this.mainloading = true;
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
    this.videoPageData = null;
    this.route.params.subscribe((params: Params) => {
      this.lastVideo = false;
      this.videoFinish = false;
      this.clickOntrailer = false;
      window.scroll(0, 0);
      this.currentIndex = 0;
      this.assetPath = params['path'];
      this.checkPlanValidity();
      this.getAssetGroupDetails(this.assetPath);
      window.scroll(0, 0);
    });

  }

  // seekPlayer(duration, videoDuration: boolean, setBookMark: boolean) {
  //   if (videoDuration) {
  //     if (setBookMark) {
  //       if ((this.playerObject.getDuration() - this.bookmarkDuration) > 30) {
  //         this.playerObject.seek(this.playerObject.currentTime()() + this.bookmarkDuration);
  //       } else {
  //         this.playerObject.seek(this.playerObject.currentTime()() + duration);
  //       }
  //     }
  //   } else {
  //     this.playerObject.seek(this.playerObject.currentTime()() + duration);
  //   }
  // }

  public getAssetGroupDetails(path: string): void {

    const assetGroupDetailRequest = {
      "path": path
    };
    let genreFiterString = "?filter=" + encodeURI(JSON.stringify(assetGroupDetailRequest));
    this.assetGroupService.getAssetGroupDetails(genreFiterString).subscribe(res => {
      if (res !== undefined && res.success == true) {
        this.assetGroupData = res;
        if (this.assetGroupData.data[0].isPremium) {
          this.isassetGroupPremium = true;
          this.assetGroup = "assetGroup";
          if (!this.assetGroupData.data[0].casUserWatchAsset) {
           if (this.assetGroupData.data[0].subscription_type) {
             this.assetgroupSubscriptionId = this.assetGroupData.data[0].subscription_id;
           } else {
             this.assetgroupSubscriptionId = 0;
           }
          }
        }
       this.assetgeoStatus = this.assetGroupData.data[0].geoStatus;
       if (localStorage.getItem('seasonPath') !== null) {
        this.currentSeasonPath = localStorage.getItem('seasonPath').toString();
        localStorage.removeItem('seasonPath');
      } else {
        this.currentSeasonPath = this.assetGroupData.data[0].assetCategory[0].path.toString();
      }
        this.getAssetCategoryDetails(this.currentSeasonPath, true);
      }
    },
      err => {
        console.log(err);
      });
  }

  public getAssetGroupListing(skip: number, assetCategory: AssetCategotyDetail, setData: boolean, startVideo): void {
    this.clickOntrailer = false;
    const assetGroupListingRequest = {
      "path": assetCategory.path,
      "type": "asset_category",
      "deviceTypeId": "1"
    };
    this.skip = this.skip + skip;
    if (setData) {
      this.loading = true;
    }
    let assetGroupListingString = "?filter=" + encodeURI(JSON.stringify(assetGroupListingRequest)) + "&start=" + this.skip + "&limit=24";
    this.assetGroupService.getAssetListing(assetGroupListingString).subscribe(res => {
      if (res !== undefined && res.success === true) {

        if (res.data.length == 24) {
          this.viewMore = true;
        } else {
          this.viewMore = false;
        }

        if (setData) {
          res.data.forEach(record => {
            this.assetGroupList.data.push(record);
          });
          this.loading = false;

        } else {
          this.assetGroupList = res;
          if (startVideo != false) {
           // this.getVideoDetails(this.assetGroupList.data[0].path, this.currentIndex);
           if(this.assetGroupList.data.length > 0 && (this.redirectsearchUrl == null || this.redirectsearchUrl == undefined) && (this.redirectfavouriteUrl == null || this.redirectfavouriteUrl == undefined) && (this.redirectwatchListUrl == null || this.redirectwatchListUrl == undefined) && (this.redirectpagecategoryUrl == null || this.redirectpagecategoryUrl == undefined)){
            this.getVideoDetails(this.assetGroupList.data[0].path, this.currentIndex);
          }
          else if( this.assetGroupList.data.length > 0 && (this.redirectsearchUrl != null && this.redirectsearchUrl != 'undefined'))
          {
            this.seasonPath = localStorage.getItem('searchUrlCat');
            console.log("searchUrl", this.redirectsearchUrl);
            this.getVideoDetails(this.redirectsearchUrl,0);
            localStorage.removeItem('searchUrl');
            localStorage.removeItem('searchUrlCat');
            this.redirectsearchUrl = null;
          }
          else if(this.assetGroupList.data.length > 0 && this.redirectfavouriteUrl != null || this.redirectfavouriteUrl != undefined)
          {
            console.log("redirectfavouriteUrl", this.redirectfavouriteUrl);
            this.seasonPath = localStorage.getItem('favouriteUrlCat');
            this.getVideoDetails(this.redirectfavouriteUrl,0);
            this.redirectfavouriteUrl = null;
            localStorage.removeItem('favouriteUrl');
            localStorage.removeItem('favouriteUrlCat');
          }
          else if( this.assetGroupList.data.length > 0 && this.redirectwatchListUrl != null || this.redirectwatchListUrl != undefined)
          {
            console.log("watchlist", this.redirectwatchListUrl);
            this.seasonPath = localStorage.getItem('watchListUrlCat');
            this.getVideoDetails(this.redirectwatchListUrl,0);
            localStorage.removeItem('watchListUrl');
            localStorage.removeItem('watchListUrlCat');
            this.redirectwatchListUrl = null;
          }
          else if( this.assetGroupList.data.length > 0 && this.redirectpagecategoryUrl != null || this.redirectpagecategoryUrl != undefined)
          {
            console.log("pagecategoryUrl", this.redirectpagecategoryUrl);
            this.getVideoDetails(this.redirectpagecategoryUrl,0);
            localStorage.removeItem('pagecategoryUrl');
            this.redirectpagecategoryUrl = null;
          }

            this.loading = false;
          }
        }
        if (this.assetGroupList.data.length <= 0 ) {
          let index = this.assetGroupData.data[0].assetCategory.findIndex(x => x.path !== assetCategory.path);
          this.assetGroupData.data[0].assetCategory.splice(index - 1, 1);
          this.mainloading = false;
          this.getAssetCategoryDetails(this.assetGroupData.data[0].assetCategory[0].path, true);

        }
      //  this.categoryList = this.assetGroupData.data[0].assetCategory;    
      }
    },
      err => {
        console.log(err);
      });
  }

  public getAssetCategoryDetails(assetPath: string, startvideo): void {
    this.currentIndex = 0;
    this.seconds = 5;
    this.assetGroupList = undefined;
    this.clickOntrailer = false;
    // this.playerObject = undefined;
    // this.mainloading = true;
    // window.scroll(0,0);
    this.seasonPath = assetPath;
    const assetCategoryDetailRequest = {
      "path": assetPath
    };
    let assetCategoryFilterString = "?filter=" + encodeURI(JSON.stringify(assetCategoryDetailRequest));
    this.assetGroupService.getAssetCategoty(assetCategoryFilterString).subscribe(res => {
      if (res !== undefined && res.success === true) {
        this.assetCategoryData = res.data[0];
        this.skip = 0;
        this.getAssetGroupListing(0, this.assetCategoryData, false, startvideo);
      }
    },
      err => {
        console.log(err);
      });
  }

  public getVideoDetails(videoPath: string, currenIndex): void {
    this.lastVideo = false;
    this.csaiAdServer = '';
    this.clickOntrailer = false;
    this.currentIndex = currenIndex;
    this.videoFinish = false;
    this.seconds = 5;
    this.genreTitle = '';
    this.bookmarkDuration = null;
    if (localStorage.getItem('videoId') !== null) {
      this.videoPath = localStorage.getItem('videoId').toString();
      localStorage.removeItem('videoId');
    } else {
      this.videoPath = videoPath.toString();
    }
  //  this.videoPath = videoPath.toString();    this.getCastCrew(this.videoPath);
    // this.mainloading = true;
    // if (this.playerObject != undefined) {
    //   this.playerObject.destroy();
    //   this.playerObject = undefined;
    // }
    // const width = window.innerWidth;
    // const height = window.innerHeight;
    // const cookie = this.cookieService.get('readDisclaimer');
    // var dnt = 0;
    // if (cookie == 'true') {
    //   dnt = 1;
    // } else {
    //   dnt = 0;
    // }
    const assetDetailRequest = { "deviceTypeId": "1" };
    // const userAgent = window.navigator.userAgent;
    const assetDetailFiterString = "?filter=" + encodeURI(JSON.stringify(assetDetailRequest));
    // + '&width=' + width + '&height=' + height + '&dnt=' + dnt + '&ua=' + userAgent;
    this.videoService.getAssetDetails(this.videoPath, assetDetailFiterString).subscribe(res => {
      if (res !== undefined && res.success === true) {
        // if (this.player != undefined) {
        //   this.player.destroy();
        // }
        if (this.player !== undefined) {
          this.player.destroy();
          this.player = undefined;
        }
        this.videoPageData = res.data[0];
        this.addedToWatchList = this.videoPageData.isUserWatched;
        this.videoURL = this.videoPageData.playbackUrl;
        this.posterImage = this.videoPageData.horizontalFilePath;
        this.trailerPlayBackUrl = this.videoPageData.mapTrailerVideo;
        this.isUserLikes = this.videoPageData.isUserLikes;
        this.isPremium = this.videoPageData.isPremium;
        this.casUserWatchAsset = this.videoPageData.casUserWatchAsset;
        this.currentpath = this.videoPageData.path;
        // this.csaiAdServer = this.videoPageData.csaiAdServer;

        // if (this.trailerPlayBackUrl == '') {
        //   this.showButton = false;
        // } else if (this.trailerPlayBackUrl == null) {
        //   this.showButton = false;
        // } else if (!this.trailerPlayBackUrl) {
        //   this.showButton = false;
        // } else {
        //   this.showButton = true;
        // }
        this.bookmarkDuration = this.videoPageData.bookmarkDuration;
        this.getGenreTitle(this.videoPageData);
        if (this.bookmarkDuration == null) {
          this.bookmarkDuration = 0;
        }
        this.geoStatus = this.videoPageData.geoStatus;
        if (this.user == true && this.geoStatus && !this.isPremium) {
          $('#premium-id').removeClass('premium-vod');
          console.log("1");
          this.watchMovie = true;
          this.playMovie = true;
          this.isShowVideo = true;
          this.setBookMark = true;
          this.isShowImage = false;
          this.showVideoError = false;
          $('#image-display').hide();    
          $('.video-js').css('display', 'block');   
          $('.DESCDiv').css('display', 'block');     
          $('.TVODDiv').css('display', 'none');
          this.showVideo(this.videoURL, this.posterImage, true);
          this.isIncreaseCount = true;
        } else if (this.user == true && this.geoStatus && this.isPremium && this.casUserWatchAsset) {
          console.log("2");
          $('#premium-id').removeClass('premium-vod');
          this.watchMovie = true;
          this.playMovie = true;
          this.isShowVideo = true;
          this.setBookMark = true;
          this.isShowImage = false;
          this.showVideoError = false;
          $('#image-display').hide();  
          this.isVideoPlay = true; 
          $('.video-js').css('display', 'block');  
          $('.DESCDiv').css('display', 'block');         
         $('.TVODDiv').css('display', 'none');
          this.showVideo(this.videoURL, this.posterImage, true);
          this.isIncreaseCount = true;
        } else if (this.user == true && !this.geoStatus) {
          console.log("3");
          this.showVideoError = true;
          this.isShowImage = false;
          this.isShowVideo = false;
          this.player = undefined;
          this.watchMovie = false;
          this.playMovie = false;
          this.setBookMark = false;
        } else if (this.user == false && this.geoStatus && !this.isPremium) {
          $('#premium-id').removeClass('premium-vod');
          console.log("4");        
          this.watchMovie = true;
          this.playMovie = true;
          this.isShowVideo = true;
          this.setBookMark = false;
          this.isShowImage = false;
          this.showVideoError = false;
          $('#image-display').hide();
          $('.video-js').css('display', 'block');
          $('.DESCDiv').css('display', 'block');
          $('.TVODDiv').css('display', 'none');
          this.showVideo(this.videoURL, this.posterImage, true);
          this.isIncreaseCount = false;

        } else if (this.user == false && !this.geoStatus) {
          console.log("5");
          this.showVideoError = true;
          this.isShowImage = false;
          this.isShowVideo = false;
          this.player = undefined;
          this.watchMovie = false;
          this.playMovie = false;
          this.setBookMark = false;
          this.isIncreaseCount = false;
        } else if (this.user == true && this.geoStatus && this.isPremium && !this.casUserWatchAsset) {
          console.log("6");
          this.watchMovie = false;
          this.playMovie = false;
          this.isVideoPlay = false;
          this.isShowVideo = true;
          this.setBookMark = true;
          this.isShowImage = true;
          this.showVideoError = false;
          this.subScriptionData = null;
          $('.player-container').css('display', 'none');
          $('.video-js').css('display', 'none');
          $('.DESCDiv').css('display', 'none');
         // $('.bannerInfo').css('display', 'block');
          $('#image-display').show();
          $('.TVODDiv').css('display', 'none');
          
            const subscriptionRequest = { "deviceTypeId": "1", "assetId": this.videoPageData.path };
            const subscriptionFiterString = "?filter=" + encodeURI(JSON.stringify(subscriptionRequest));
          this.videoService.getSubScriptionList(subscriptionFiterString).subscribe(res => {
              if (res != undefined && res.success == true) {
                this.subScriptionData = res.data.data;
                if ( this.subScriptionData ) {
                  this.tvodasset = true;
                  this.tvodRate = this.subScriptionData[0].rate;
                  this.tvodDays = this.subScriptionData[0].validityDays;
                }

                const rentPlan = res.data.data;
                for (let plan of rentPlan) {                    
                  if (plan.subscriptionType == "TVOD") {
                    this.rented = true;  
                    if (this.isVideoPlay == false && !this.casUserWatchAsset) { 
                    $('.TVODDiv').css('display', 'block');
                    $('.video-js').css('display', 'none');
                    $('.DESCDiv').css('display', 'none');
                    this.isShowImage = true;
                    }
                    break;
                  }
                 
                }
              }
            })      
        } else if (this.user == false && this.geoStatus && !this.isPremium) {
          $('#premium-id').removeClass('premium-vod');
          $('#image-display').hide();
          console.log("7");
          this.watchMovie = true;
          this.playMovie = true;
          this.isShowVideo = true;
          this.setBookMark = false;
          this.isShowImage = false;
          this.showVideoError = false;
          this.showVideo(this.videoURL, this.posterImage, true);
        } else if (this.user == false && this.geoStatus && this.isPremium) {
          console.log("8");
          this.watchMovie = false;
          this.playMovie = false;
          this.isShowVideo = false;
          this.setBookMark = false;
          this.isShowImage = true;
          this.showVideoError = false;
          this.subScriptionData = null;
          $('.video-js').css('display', 'none');
          $('.DESCDiv').css('display', 'none');
          $('.TVODDiv').css('display', 'none');
          const subscriptionRequest = { "deviceTypeId": "1", "assetId": res.data[0].path };
          const subscriptionFiterString = "?filter=" + encodeURI(JSON.stringify(subscriptionRequest));
          this.videoService.getSubScriptionList(subscriptionFiterString).subscribe(res => {
            if (res != undefined && res.success == true) {
              this.subScriptionData = res.data.data;
              if ( this.subScriptionData ) {
              this.tvodasset = true;
              this.tvodRate = this.subScriptionData[0].rate;
              this.tvodDays = this.subScriptionData[0].validityDays;
              }
              const rentPlan = res.data.data;
             
              for (let plan of rentPlan) {
                if (plan.subscriptionType == "TVOD") {
                  this.rented = true;  
                  if (this.isVideoPlay == false) {   
                  $('.TVODDiv').css('display', 'block'); 
                  this.isShowImage = true;
                  $('#image-display').show();              
                  }
                  break;
                }                                  
              }
            }
          })
          if (this.isVideoPlay == true){
            $('.video-js').css('display', 'block');
            $('.DESCDiv').css('display', 'block');
            $('.TVODDiv').css('display', 'none');
               this.watchMovie = true;
               this.playMovie =true;
          }
          if (this.allowToWatchPremiumContent == true) {
            this.watchMovie = true; 
            this.playMovie =true; 
          }
          $('#image-display').show;
          this.showVideo(this.videoURL, this.posterImage, false);
        }
        // if (this.geoStatus == 1) {
        //   if (this.user == true) {
        //     this.watchMovie = true;
        //     this.isShowVideo = true;
        //     this.setBookMark = true;
        //     $('#image-display').hide();
        //     this.showVideo(this.videoURL, this.posterImage, this.watchMovie);
        //   } else if (this.user == false) {
        //     this.watchMovie = true;
        //     this.isShowVideo = true;
        //     this.setBookMark = false;
        //     $('#image-display').hide();
        //     this.showVideo(this.videoURL, this.posterImage, this.watchMovie);
        //   }
        // } else {
        //   this.watchMovie = false;
        //   this.setBookMark = false;
        //   this.isShowVideo = false;
        //   $('#image-display').hide();
        // }
        setTimeout(() => {
          window.scroll(0, 0);
        }, 30);
        this.mainloading = false;
      }

    },
      err => {
        console.log(err);
      });
  }

  // public showTrailer(): void {
  //   if (this.playerObject !== undefined) {
  //     this.playerObject.unload();
  //     this.playerObject = undefined;
  //   }
  //   this.clickOntrailer = true;
  //   $(document).ready(function () {
  //     $(this).scrollTop(0);
  //   });
  //   this.showVideo(this.trailerPlayBackUrl, this.posterImage, true);
  //   this.isShowVideo = true;
  //   this.setBookMark = false;
  //   this.isShowImage = false;
  //   this.showVideoError = false;
  //   this.isIncreaseCount = false;
  // }

  // public showMovie(): void {
  //   if (this.playerObject !== undefined) {
  //     this.playerObject.unload();
  //     this.playerObject = undefined;
  //   }
  //   this.videoFinish = false;
  //   this.clickOntrailer = false;
  //   $(document).ready(function () {
  //     $(this).scrollTop(0);
  //   });

  //   if ((BaseComponent.baseUser !== undefined &&
  //     Object.keys(BaseComponent.baseUser).length !== 0) && this.geoStatus) {
  //     this.isShowImage = false;
  //     this.showVideoError = false;
  //     this.setBookMark = true;
  //     this.isShowVideo = true;
  //     this.showVideo(this.videoURL, this.posterImage, true);
  //     this.isIncreaseCount = true;
  //   } else if ((BaseComponent.baseUser !== undefined &&
  //     Object.keys(BaseComponent.baseUser).length !== 0) && !this.geoStatus) {
  //     this.isShowVideo = false;
  //     this.isShowImage = false;
  //     this.showVideoError = true;
  //     this.playerObject = undefined;
  //     this.isIncreaseCount = true;

  //   } else if (this.geoStatus) {
  //     this.watchMovie = true;
  //     this.isShowVideo = true;
  //     this.setBookMark = false;
  //     this.showVideoError = false;
  //     this.showVideo(this.videoURL, this.posterImage, true);
  //     this.isIncreaseCount = true;

  //   } else if (!this.geoStatus) {
  //     this.showVideoError = true;
  //     this.isShowImage = false;
  //     this.isShowVideo = false;
  //     this.watchMovie = false;
  //     this.setBookMark = false;
  //     this.playerObject = undefined;
  //     this.isIncreaseCount = true;

  //   }

  // }

  showVideo(videoUrl, posterImage, watchMovie): void {

    var self = this;
    // this.playerObject = videojs(document.querySelector('.video-js'));
    if (watchMovie == true && this.geoStatus == 1) {
      if (this.player != undefined) {
        this.player.destroy();
      }
      const element = document.querySelector('.theoplayer-container');
      this.player = new THEOplayer.Player(element, {
        libraryLocation: 'https://cdn.myth.theoplayer.com/c69d7e91-49de-4237-aa42-da5a20c2723f/',
        license: 'sZP7IYe6T6fzIKCiTQfoTmzzIDCZFSaLTQh-ClhrIOzoIubL3Dhr3uaZIlf6FOPlUY3zWokgbgjNIOf9fKBL0LftCoakFSXl0Sa-3QIK36z_CDIgFSUg3L5k3o46Clho0ZfVfK4_bQgZCYxNWoryIQXzImf90SCt3SBkTSfi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3laoTSBk0lfZ0ueoFOPeWok1dDrLYtA1Ioh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfKxqWDXNWG3ybojkbK3gflNWf6E6FOPVWo31WQ1qbta6FOPzdQ4qbQc1sD4ZFK3qWmPUFOPLIQ-LflNWfK1zWDikfgzVfG3gWKxydDkibK4LbogqW6f9UwPkIYz'
        // mutedAutoplay: 'all',
      });

        // this.player.autoplay = true;
      this.player.source = {
        sources: [
          {
            src: videoUrl, // 'https://cdn.theoplayer.com/video/elephants-dream/playlist-single-audio.m3u8',
            type: 'application/x-mpegurl', // sets type to MPEG-DASH,
            lowLatency: false,
          }]
      };
     
      if (self.user == true) {
          self.addToWatchlist();             
      }
      // this.playerObject.src({src: videoUrl, type: 'application/x-mpegURL'});
      // this.playerObject.poster(posterImage);
      // this.playerObject = player;

      // const addToBookmarkJavascript = function (current_playback_position) {
      //   if (current_playback_position != 0) {
      //     if (self.setBookMark) {
      //       if (self.user == true) {
      //         const pageRequest = {
      //           assetId: self.videoPageData.path,
      //           duration: current_playback_position
      //         };
      //         self.videoService.addToBookmark(pageRequest).subscribe(result => {
      //           if (result !== undefined && result.success == true) {
      //             self.bookmarkDuration = result.data.duration;
      //           }
      //         });
      //       }
      //     }
      //   }
      // };

      // const addToBookmarkOnTimeUpdateJavascript = function () {
      //   let currPlaybackTime = self.playerObject.currentTime();
      //   let diffInTime = 0;
      //   if (parseInt(currPlaybackTime) > parseInt(self.bookmarkDuration)) {
      //     diffInTime = parseInt(currPlaybackTime) - parseInt(self.bookmarkDuration);
      //   } else {
      //     diffInTime = parseInt(self.bookmarkDuration) - parseInt(currPlaybackTime);
      //   }
      //   if (diffInTime >= self.watchListTimeBitmovin) {
      //     addToBookmarkJavascript(currPlaybackTime);
      //   }
      // };

      //   const onTimeChanged = function (data) {
      //     if(self.user == true && self.videoFinish == false) {
      //     if ((self.playerObject.currentTime() - self.bookmarkDuration) > 5) {
      //       addToBookmarkOnTimeUpdateJavascript();
      //     }
      //     if (self.addedToWatchList == 0) {
      //     if (self.user == true) {
      //       self.addToWatchlist();
      //       self.playerObject.off('timeupdate', () => { });
      //     }
      //   }
      // }
      // };

      //   var onTimePaused = function (data) {
      //     let currPlaybackTime = self.playerObject.currentTime();
      //     addToBookmarkJavascript(currPlaybackTime);
      // };

      var onVideoPlay = function (current_playback_position) {
        // console.log('getFacebookPixle call');
        // self.getFacebookPixle();
      };

      var onFinished = function () {
        self.seconds = 5;
        self.videoFinish = true;
        self.replay = false;
        self.setBookMark = false;
        self.watchMovie = false;
        clearInterval(self.secondInterval);
        clearTimeout(self.secondsTimeout);
        if (self.assetGroupList.data.length > 0) {
          if (self.assetGroupList.data.length != self.currentIndex + 1) {
            self.secondInterval = setInterval(() => {
              self.seconds = self.seconds - 1;
              if (self.seconds == 1) {
                clearInterval(self.secondInterval);
              }
            }, 1000);
            self.secondsTimeout = setTimeout(() => {
              if (self.replay == false) {
                self.videoFinish = false;
                self.mainloading = true;
                self.nextVideo();
              }
            }, 5000);
          } else {
            self.lastVideo = true;
          }
        }
      };
      var onError = function () {
        self.seconds = 5;
        self.videoFinish = true;
        self.replay = false;
        if (self.assetGroupList.data.length > 0) {
          if (self.assetGroupList.data.length != self.currentIndex + 1) {
            self.secondInterval = setInterval(() => {
              self.seconds = self.seconds - 1;
              if (self.seconds == 1) {
                clearInterval(self.secondInterval);
              }
            }, 1000);
            self.secondsTimeout = setTimeout(() => {
              if (self.replay == false) {
                self.videoFinish = false;
                self.mainloading = true;
                self.nextVideo();
              }
            }, 5000);
          }
        }
      };
      // const onLoadedData = function() {
      //   self.seekPlayer(0, true, self.setBookMark);
      // };
      // self.playerObject.on('timeupdate', onTimeChanged);
      // self.playerObject.on('pause', onTimePaused);
      // self.playerObject.on('ended', onFinished);
      // self.playerObject.on('play', onVideoPlay);
      // self.playerObject.on('error', onError);
      // self.playerObject.on('loadedmetadata', onLoadedData);
      // setTimeout(() => {
      //   self.playerObject.play();
      // }, 1000);

      // if (this.playerObject === undefined) {
      // this.playerObject.load(source).then(function (player) {
      //   self.seekPlayer(0, true, self.setBookMark);
      //   if (autoPlay) {
      //     setTimeout(() => {
      //       self.playerObject.play();
      //     }, 1000);
      //   }
      // }, function (reason) { });
      // this.bitMovieUnsubsribe = this.playerObject.load(source).then(function (player) {
      //   var container = $('.player-container');
      //   var playerHeight = $('#bitmovin-player').height();
      //   self.seekPlayer(0, true, self.setBookMark);
      //   if (autoPlay) {
      //     setTimeout(() => {
      //       self.playerObject.play();
      //     }, 1000);
      //   }
      //   if (container.height() <= playerHeight) {
      //     container.height(playerHeight);
      //   }
      //   self.playTimeOut = setTimeout(() => {
      //     if (self.playerObject !== undefined) {
      //       self.playerObject.play();
      //     }
      //   }, 1000);

      // }, function (reason) {
      //   // console.log(reason);
      // });

      // }, 1.5);
    }
  }

  // seekPlayer(duration, videoDuration: boolean, setBookMark: boolean) {

  //   if (videoDuration) {
  //     if (setBookMark) {
  //       if((this.playerObject.duration() - this.bookmarkDuration) > 30) {
  //         this.playerObject.currentTime(this.bookmarkDuration);
  //       } else {
  //         this.playerObject.currentTime(this.playerObject.currentTime() + duration);
  //       }
  //     }
  //   } else {
  //     this.playerObject.currentTime(this.playerObject.currentTime() + duration);
  //   }

  // }

  nextVideo() {
    this.setBookMark = false;
    this.watchMovie = false;
    for (let i = 0; i < this.assetGroupList.data.length; i++) {
      if (i == this.currentIndex) {
        const currentVideo = this.assetGroupList.data.filter((videoObj) => {
          return videoObj.path == this.videoPageData.path;
        });
        if (currentVideo.length > 0 && this.assetGroupList.data.length > i + 1) {
          this.getVideoDetails(this.assetGroupList.data[i + 1].path, i + 1);
        } else {
          this.getVideoDetails(this.assetGroupList.data[i].path, i);
        }
        break;
      }
    }
  }

  replayVideo() {
    // $('#premium-id').removeClass('premium-vod');
    // alert('loglog');
    // console.log('loglog');
    this.seconds = 5;
    this.mainloading = true;
    this.replay = true;
    // this.watchMovie = true;
    this.videoFinish = false;
    clearInterval(this.secondInterval);
    clearTimeout(this.secondsTimeout);
    this.getVideoDetails(this.videoPath, this.currentIndex);
  }

  // adjustPlayer() {
  //   const container = $('.player-container');

  //   /* extract constants for better readabilty */
  //   const lowerEdge = container.height();
  //   const switchToMinPlayerPos = lowerEdge - (window.innerHeight / 16);
  //   const currentScrollPos = document.body.scrollTop || document.documentElement.scrollTop;

  //   /* toggle the css-class responsible for the player moving to the lower right corner */
  //   if (currentScrollPos > switchToMinPlayerPos) {
  //     $('.player-switch').addClass('fixed-player');
  //     if (document.getElementById("pip-close")) {
  //       document.getElementById("pip-close").onclick = function () { closePip() };
  //     }
  //   } else {
  //     $('.player-switch').removeClass('fixed-player');
  //   }
  //   function closePip() {
  //     window.scroll(0, 0)
  //   }
  // }

  public getCastCrew(assetpath: string): void {
    const pageRequest = { "assetId": assetpath };
    let fiterString = "?filter=" + encodeURI(JSON.stringify(pageRequest));
    this.videoService.getCastCrews(fiterString).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          this.castCrewData = res.data;
          this.cast = '';
          this.director = '';
          if (this.castCrewData !== null && this.castCrewData.data) {
            for (const castcrew of this.castCrewData.data) {
              if (castcrew.character[0].professionType == 'Director') {
                if (this.director === '') {
                  this.director = ' ' + this.director + castcrew.firstName;
                } else {
                  this.director = this.director + ', ' + castcrew.firstName;
                }

              } else if (castcrew.character[0].professionType == 'Cast') {
                if (this.cast === '') {
                  this.cast = ' ' + this.cast + castcrew.firstName;
                } else {
                  this.cast = this.cast + ', ' + castcrew.firstName;
                }
              }
            }
          }
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  public addTofavourite(path: string): void {
    let userId: any = this.getUserId();
    if (userId > 0) {
      if (this.geoStatus) {
        // const pageRequest = {
        //   //user_id: this.getUserId(),
        //   asset_id: path
        // };
        const pageRequest = {
          assetId: path
        };

        this.videoService.addTofavourite(pageRequest).subscribe(
          res => {
            if (res !== undefined && res.success === true) {
              // this.videopageData.isUserLikes = true;
              this.isUserLikes = 1;
              this.successResponse(res.message);
              //this.successMessageResponse();
              //this.successMessageResponse('remove to fav');
            } else if (res !== undefined && res.success === false) {
              this.errMessageResponse(res.message);
            }
          },
          err => {
            console.log(err);
          }
        );

      }
    } else {
      this.warningMessageResponse('Please sign in to Add to Favourite');
    }
  }

  public removeTofavourite(path): void {
    let userId: any = this.getUserId();
    if (userId > 0) {
      if (this.geoStatus) {
        const pageRequest = {
          assetId: path
        };

        this.videoService.removeTofavourite(pageRequest).subscribe(
          res => {
            if (res !== undefined && res.success === true) {
              this.isUserLikes = 0;
              this.successResponse(res.message);
            }
          },
          err => {
            console.log(err);
          }
        );
      }

    }

  }

  public addToWatchlist(): void {
    const pageRequest = {
      assetId: this.videoPageData.path
    };
    // let filterString = "?filter="+encodeURI(JSON.stringify(pageRequest));
    this.videoService.addToWatchlist(pageRequest).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          // this.videopageData.watchedCount += 1;
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  public getGenreTitle(videopageData): string {
    var genreTitle = '';
    const genres = videopageData.genre;
    if (genres.length > 0) {
      for (let orientation of genres) {
        if (genreTitle != '') {
          genreTitle += ', ';
        }
        genreTitle += orientation.genreName;
      }
    } else {
      this.noGenre = true;
    }
    this.genreTitle = genreTitle;
    return this.genreTitle;


  }

  public goToHomePage(): void {
    this.headerService.reloadHeader.emit(true);
    this.router.navigate(['']);
  }

  ngOnDestroy(): void {
    clearTimeout(this.secondsTimeout);
    clearInterval(this.secondInterval);
    if (this.player != undefined) {
      this.player.destroy();
    }
    // if (this.user == true && this.videoPageData) {
    // const pageRequest = {
    //   assetId: this.videoPageData.path,
    //   duration: this.playerObject.currentTime()
    // };
    // this.videoService.addToBookmark(pageRequest).subscribe(result => res => {
    //   if (res !== undefined && res.success == true) {
    //   }
    // },
    //   err => {
    //     console.log(err);
    //   }
    // );
    // }
    // if (this.playerObject !== undefined) {
    //   this.playerObject.dispose();
    // }
  }

  public gotToLoginPage(): void {
    this.warningMessageResponse('Inicia sesión para ver este contenido');
    this.router.navigate(['auth']);
  }

  public facebookShare(e) {
    e.preventDefault();
    var facebookWindow = window.open('https://www.facebook.com/sharer/sharer.php?u=' + window.location.href, 'facebook-popup', 'height=350,width=600');
    if (facebookWindow.focus) {
      facebookWindow.focus();

    }
    return false;
  }

  public twitterShare(e) {
    e.preventDefault();
    var twitterWindow = window.open('https://twitter.com/share?url=' + window.location.href, 'twitter-popup', 'height=350,width=600');
    if (twitterWindow.focus) { twitterWindow.focus(); }
    return false;
  }

  checkPlanValidity() {
    if (this.isUserLoggedIn) {
      const uid = this.getUserId();      
      const requestParameters = {
        userId: uid
      };
      const requestObject = '?filter=' + encodeURI(JSON.stringify(requestParameters));
      this.assetGroupService.CheckPlanValidityForSVODTVOD(requestObject).subscribe(response => {
        if (response !== undefined) {
          console.log('Plan Validity Response -> ', response);
        if (response.data[0] != null && response.data[0].TVOD != null &&  response.data[0].TVOD.length > 0) {
            this.tvodStatus = true;
            // this.isVideoPlay = false;
          }
          
        }
      });
    }    
  }

  goRent(assetType) { 
    if (BaseComponent.isLogin == true) {
      $('#rent-list').modal('show');
      this.getPremiumForSubscriptionList(assetType);
    } else {
      localStorage.setItem('videoUrl', this.router.url)
      localStorage.setItem('videoId', this.currentpath);
      localStorage.setItem('seasonPath', this.seasonPath);
      this.router.navigate(['auth']);
    }
  }

  getPremiumForSubscriptionList(assetType) {
    this.subScriptionData = null;
    this.assetType = assetType
    if(assetType=="assetGroup"){
       this.pathId = this.route.snapshot.params.path;
    } else{
      this.pathId = this.currentpath
    }
    const subscriptionRequest = { "deviceTypeId": "1", "assetId": this.pathId ,assetType: assetType };
    const subscriptionFiterString = "?filter=" + encodeURI(JSON.stringify(subscriptionRequest));
    // this.videoService.getSubScriptionList(subscriptionFiterString).subscribe(res => {
    this.videoService.getSubScriptionList(subscriptionFiterString).subscribe(res => {
      if (res != undefined && res.success == true) {
        // this.subScriptionData = res.data.data;
        this.subScriptionData = res.data.data;
        if ( this.subScriptionData ) {
        this.tvodasset = true;
        this.tvodRate = this.subScriptionData[0].rate;
        this.tvodDays = this.subScriptionData[0].validityDays;
        }
      }
    })
  }

  checkout(item, e) {
    if (this.isassetGroupPremium == true){
      this.assetType = 'assetGroup';
       this.assetID = this.assetPath
    }
    else {
      this.assetID = this.currentpath;
    }
    this.handler = (<any>window).StripeCheckout.configure(
      {
        key: environment.stripeKey,
        locale: "auto",
        token: (token: any) => {
          let cardToken = token.id;
          const orderReq = {
            orderId: cardToken,
            type: this.assetType,
            assetId: this.assetID.toString(),
            userId: this.getUserId(),
            subscriptionId: item.path,
            isRecurring: 1,
            billingName: BaseComponent.userFName,
            billingEmail: BaseComponent.userEmail,
            status: "Active",
            usageType: "Paid",
            isActive: 1,
            paymentMethod: "stripe"
          };
          this.loading = true;
          this.videoService.placeOrder(orderReq).subscribe(
            res => {
              if (res !== undefined && res.success === true) {
                this.successResponse(res.message);
                this.expirydate = res.data.expirydate
                $('#rent-list').modal('hide');
                localStorage.setItem('videoId', this.pathId);
                localStorage.setItem('seasonPath', this.seasonPath);
                $('#confirm-payment').modal('show'); 
                this.loading = false;
                //this.successMessageResponse();
                //this.successMessageResponse('remove to fav');
              } else if (res !== undefined && res.success === false) {
                this.errMessageResponse(res.message);
                this.loading = false;
              }
            },
            err => {
              console.log(err);
              this.loading = false;
            }
          );
        },
      }
    );
    let totalAmount = item.rate * 100;
    if (totalAmount > 0) {
      this.handler.open({
        name: "Segi TV",
        description: item.title,
        amount: totalAmount,
        email: BaseComponent.userEmail
      });
    }
    console.log(this.handler);

    e.preventDefault();
  }

  confirmpayment(){
    $('#confirm-payment').modal('hide');
    this.ngOnInit();
  }

}
