import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GenresListingComponent } from '../component/genres-listing.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [GenresListingComponent],
    imports: [CommonModule, RouterModule],
    exports: [GenresListingComponent],
    providers: []
})

export class GenresListingModule {

}
