import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@base/services/base.api.service';
import { BaseComponent } from '@base/component/base.component';
import { ServiceLocator } from '@base/constants/service-locator';
import { METHOD_REFERENCE, API_REFERENCE, API_PATH } from '@base/constants/base.constants';
@Injectable({
  providedIn: 'root'
})
export class VuuzleTvService {

    public apiService: ApiService;
    public baseComponent: BaseComponent;
    constructor() {
        this.apiService = ServiceLocator.injector.get(ApiService);
        this.baseComponent = ServiceLocator.injector.get(BaseComponent);
    }

    public getListing(urlParmas): Observable<any> {
      const filter =  encodeURI(JSON.stringify(urlParmas));
        const options = this.baseComponent.getRequestOptions(
          METHOD_REFERENCE['GET'], API_REFERENCE['vuuzleTv']['listing'] +"?filter=" + filter,
          {}, null, null, null, false, true, API_PATH);
        return this.apiService.apiCall(options);
    }
    public getAsset(urlParmas): Observable<any> {
      const options = this.baseComponent.getRequestOptions(
        METHOD_REFERENCE['GET'], API_REFERENCE['asset']['assetdetails'] + urlParmas,
        {}, null, null, null, false, true, API_PATH);
      return this.apiService.apiCall(options);
    }
    public getChannel(urlParmas): Observable<any> {
      const filter =  encodeURI(JSON.stringify(urlParmas));
      const options = this.baseComponent.getRequestOptions(
        METHOD_REFERENCE['GET'], API_REFERENCE['vuuzleTv']['channel'] +"?filter=" + filter,
        {}, null, null, null, false, true, API_PATH);
      return this.apiService.apiCall(options);
    }
}