import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FootergenreComponent } from '../component/footergenre.component';
import { FooterService } from '@footer/services/footer.service';



@NgModule({
  declarations: [FootergenreComponent],
  imports: [
    CommonModule
  ],
  providers: [FooterService],
  exports: [FootergenreComponent]
})
export class FootergenreModule { }
