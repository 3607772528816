import { DOCUMENT } from '@angular/common';
import * as moment from 'moment';
import {
  Component,
  OnInit,
  NgZone,
  SimpleChanges,
  OnDestroy,
  Renderer2,
  inject,
  Inject
} from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { NavigationUpdate, NavigationData } from '@header/model/header.model';
import {
  PageCategoryData,
  PageCategory,
  PageData,
  AssetGroupClassification
} from '@home/model/pagecategory.model';
import {
  VideoPage, VideoPageData
} from '@video-page/model/video-page';
import { HeaderService } from '@header/services/header.service';
import { HomeService } from '@home/services/home.service';
import { Carousal, CurousalResponse, carouselData } from '@home/model/carousal';
import { ServiceLocator } from '@base/constants/service-locator';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { VideoPageService } from '@video-page/services/videopage.service';
import { Params, Router, ActivatedRoute } from '@angular/router';
import { FooterData } from '@footer/model/footer.model';

declare var $: any;
declare const bitmovin: any;

@Component({
  selector: 'app-home',
  templateUrl: '../view/home.component.html',
  styleUrls: ['../css/home.component.css']
})
export class HomeComponent extends BaseComponent implements OnInit, OnDestroy {
  public carousalResponse: carouselData[] = [];
  public caroselVideoResponse: carouselData[] = [];
  videopageData: VideoPageData;
  genresData: FooterData;
  genresDataList: FooterData;
  showImage = false;
  videoImage = '';
  currentPage: any;
  carouselLength: any;
  refereshToken: any;
  constructor(private ngZone: NgZone,
    _activatedRoute: ActivatedRoute,
    private rendrer: Renderer2,
    @Inject(DOCUMENT) private document: Document) {
    super();
    this.headerService = ServiceLocator.injector.get(HeaderService);
    this.homeService = ServiceLocator.injector.get(HomeService);
    this.videopageService = ServiceLocator.injector.get(VideoPageService);
    this.route = _activatedRoute;
  }

  public contactform: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    mobile_number: new FormControl('', [Validators.required])
  });


  public PageCategoryParams = new URLSearchParams();

  pageCategoryData: PageCategoryData;
  headerService: HeaderService;
  public homeService: HomeService;
  public pageData: PageCategoryData;
  public navigation: string;
  public selectedGenreMap: {} = {};
  pagelist: PageData[];
  videopageService: VideoPageService;
  navigationData: NavigationData;
  public NavigationParams = new URLSearchParams();
  // initialLoadItems: number = 2;
  // skippedItems: number = 0;
  // pageCategoriesLoading: boolean;
  skip: number;
  params: any;
  videoSection: boolean;
  showHomeVideo: boolean;
  isPageCategory = false;
  onInitloader = true;
  title: any;
  ismore:boolean;
  iscategory:boolean;
  

  ngOnInit() {
    // this.initialLoadItems = 2;
    // this.skippedItems = 0;
    // this.pageCategoriesLoading = false;
    $(window).scroll(function () {
      if ($(window).scrollTop() + $(window).height() == $(document).height()) {
      }
    });
    $(document).ready(function () {
      $(this).scrollTop(0);
    });

    $(document).ready(function () {
      $('#lightSlider').lightSlider({
        gallery: true,
        item: 1,
        loop: false,
        slideMargin: 0,
        thumbItem: 24
      });
    });
    this.sliderScript();
    // this.headerService.setHomePagePath.subscribe(res => {
    //   if (res !== undefined) {
    //     window.scroll(0, 0);
    //     console.log(res);
    //     console.log('=-------------------------------------==============');
    //     this.getPageCategorie(res);
    //     this.getCarousalResponse(res['path'], res['type']);
    //   } else {
    //     console.log('Error');
    //   }
    // });
    this.route.params.subscribe((params: Params) => {
      this.onInitloader = true;
      this.caroselVideoResponse = [];
      this.pageCategoryData = null;
      this.carousalResponse = null;
      this.showImage = false;
      this.videoImage = '';
      this.currentPage = params.name;
      $('#volume-off').hide();
      $('#volume-on').hide();
      this.rendrer.removeClass(this.document.body, 'transperent-layout');
      this.rendrer.removeClass(this.document.body, 'hide-header');
      this.rendrer.removeClass(this.document.body, 'hide-footer');
      $(document).ready(function () {
        $(this).scrollTop(0);
        window.scroll(0, 0);
      });
      this.navigationData = null;
      const filterNavigation: NavigationData = new NavigationData();

      if (
        BaseComponent.globalNavigationData === undefined ||
        Object.keys(BaseComponent.globalNavigationData).length === 0
      ) {
        this.getAuthenticateToken();
        setTimeout(() => {       
        const pageRequest = {
          deviceTypeId: 1,
          langaugeCode: 1
        };
        this.headerService.getNavigation(pageRequest).subscribe(result => {
          if (result !== undefined && result.success == true) {
            this.navigationData = result.data;
            filterNavigation.data = this.navigationData.data.filter(
              res => res.title.split(' ').join('').toLocaleLowerCase() == params.name.split(' ').join('').toLocaleLowerCase()
            );
            this.showHomeVideo = false;
            this.setNavigationData(filterNavigation);
            return true;
          } else if (result !== undefined && result.success == false) {
          }
        });
      }, 2000);
      } else if (
        BaseComponent.globalNavigationData !== undefined &&
        Object.keys(BaseComponent.globalNavigationData).length !== 0
      ) {
        filterNavigation.data = BaseComponent.globalNavigationData.data.filter(
          res => res.title.split(' ').join('').toLocaleLowerCase() == params.name.split(' ').join('').toLocaleLowerCase()
        );
        this.caroselVideoResponse = [];
        this.carousalResponse = null;
        this.showHomeVideo = false;
        this.setNavigationData(filterNavigation);
        return true;
      }
    });
  }

  public setNavigationData(navigate: NavigationData): void {


    if (navigate !== undefined && navigate.data.length) {
      this.title = navigate.data[0].title;
      const requestParam = {
        path: navigate.data[0].path,
        type: navigate.data[0].type
      };
      this.params = requestParam;
      this.skip = 0;
      if (this.title.trim().toUpperCase() === 'MORE') {
       // debugger;
        this.ismore=true;
        this.getGeners();
        this.iscategory=false;
        // this.headerService.setActivePath.emit(navigate.data[0].title);
      } else {
        this.ismore=false;
        this.iscategory=false;
      this.getPageCategorie(requestParam, false, 0);
      this.getCarousalResponse(requestParam);
      this.headerService.setActivePath.emit(navigate.data[0].title);
    }
  }
  }

  refreshListingTooltip(): void {
    // $(".box-container-1").tooltipster({
    //   interactive: true,
    //   maxWidth: 267
    // });
  }

  public getCarousalResponse(requestParam): void {

    this.carousalResponse = null;
    this.caroselVideoResponse = null;
    const carousal = new Carousal();
    // carousal.device_type = "2";
    // carousal.path = path;
    // carousal.type = type;

    if (requestParam !== undefined) {
      this.navigation = requestParam;
    }

    const carouselRequest = {
      path: this.navigation['path'],
      type: this.navigation['type'],
      deviceTypeId: '1'
    };
    // this.pageCategoryData = null;
    let fiterString = '?filter=' + encodeURI(JSON.stringify(carouselRequest));

    this.homeService.getMainCarousal(fiterString).subscribe(
      res => {
        if (res !== undefined && res.success === true) {

          this.carousalResponse = res.data.filter(data => data.carouselType == 'Image' || data.carouselType == 'Video');
          // this.caroselVideoResponse = res.data.filter(data => data.carouselType == 'Video');
          // if (this.caroselVideoResponse.length > 0) {
          // $('#volume-off').hide();
          // $('#volume-on').hide();
          if (this.carousalResponse.length == 0) {
            this.rendrer.removeClass(this.document.body, 'transperent-layout');
          } else {
            this.rendrer.addClass(this.document.body, 'transperent-layout');
          }
          // this.carouselLength = true;
          // this.showImage = true;
          // this.videoImage = this.carousalResponse[0].filePath;
          window.scroll(0, 0);
          this.onInitloader = false;
          // this.showHomeVideo = true;
          // this.showImage = true;
          // this.sliderScript();
          // }
          //   this.shortCarouselVideo(this.caroselVideoResponse[0]);
          // } else {
          //   this.videopageData = null;
          // }
          // if (this.caroselVideoResponse.length == 0) {
          //   this.ngZone.run(() => {
          //     setTimeout(() => {
          //       this.sliderScript();
          //       $('#lightSlider').lightSlider({
          //         gallery: true,
          //         locator: true,
          //         item: 1,
          //         loop: false,
          //         slideMargin: 0,
          //         thumbItem: 24
          //       });
          //     }, 500);
          //   });
          // }
          this.ngZone.run(() => {
            setTimeout(() => {
              this.sliderScript();
              $('#lightSlider').lightSlider({
                gallery: true,
                locator: true,
                item: 1,
                loop: false,
                slideMargin: 0,
                thumbItem: 24
              });
            }, 500);
          });
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  getParallaxImage(image: string): string {
    return image;
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   let log: string[] = [];

  //   if (changes['catPath']) {
  //       if (changes['catPath'].currentValue !== undefined)
  //       {
  //           this.initialLoadItems = 2;
  //           this.skippedItems = 0;
  //           this.pageCategoryData = [];
  //           this.getPageCategorie(this.params);

  //       }
  //     }
  //   }
  refreshHorizontalCarousel(pageCategoryId: string): void {
    $('#page_category_list_' + pageCategoryId).owlCarousel({
      loop: false,
      margin: 21,
      nav: true,
      navText: [
        '<img src="./assets/images/arrow-back.png">',
        '<img src="./assets/images/arrow-next.png">'
      ],
      autoplay: false,
      autoHeight: false,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 2,
          nav: false
        },
        768: {
          items: 4
        },
        1000: {
          items: 5
        }
      }
    });
  }

  refreshVerticalCarousel(pageCategoryId: string): void {
    $('#page_category_list_' + pageCategoryId).owlCarousel({
      loop: false,
      margin: 13,
      nav: true,
      navText: [
        '<img src="./assets/images/arrow-back.png">',
        '<img src="./assets/images/arrow-next.png">'
      ],
      autoplay: false,
      autoHeight: false,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 2,
          nav: false
        },
        768: {
          items: 3
        },
        1000: {
          items: 6
        }
      }
    });
  }

  refreshCustomCarousel(pageCategoryId: string): void {
    $("#page_category_list_" + pageCategoryId).owlCarousel({
      loop: false,
      margin: 15,
      nav: true,
      navText: [
        '<img src="./assets/images/arrow-back.png">',
        '<img src="./assets/images/arrow-next.png">'
      ],
      autoplay: false,
      autoHeight: false,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 3,
          nav: false
        },
        768: {
          items: 5
        },
        1000: {
          items: 6
        }
      }
    });
  }



  refreshListingCarousel(pageCategoryId: string): void {
    $('#page_category_listing_' + pageCategoryId).owlCarousel({
      loop: false,
      margin: 20,
      nav: false,
      navText: [
        '<img src="./assets/images/arrow-back.png">',
        '<img src="./assets/images/arrow-next.png">'
      ],
      rtl: false,
      autoplay: false,
      autoHeight: false,
      autoWidth: true,
      responsive: {
        0: {
          nav: false,
          rtl: false
        },
        768: {
          items: 3
        },
        1000: {
          items: 6
        }
      }
    });
  }

  refreshSubCategoryCarousel(pageCategoryId: string): void {
    $('#page_subcategory_' + pageCategoryId).owlCarousel({
      loop: false,
      margin: 20,
      nav: false,
      navText: [
        '<img src="./assets/images/arrow-back.png">',
        '<img src="./assets/images/arrow-next.png">'
      ],
      rtl: false,
      autoplay: false,
      autoHeight: false,
      autoWidth: true,
      responsive: {
        0: {
          nav: false,
          rtl: false
        },
        768: {
          items: 3
        },
        1000: {
          items: 3
        }
      }
    });
  }

  public getPageCategorie(
    navigation: {},
    IsScrrol: boolean,
    skip: number
  ): void {
    if (navigation !== undefined) {
      this.navigation = navigation['path'];
    }

    const pageCategoryRequest = {
      deviceTypeId: '1',
      path: navigation['path'],
      type: navigation['type']
    };
    this.skip = this.skip + skip;
    // this.pageCategoryData = null;
    let fiterString = '?filter=' + encodeURI(JSON.stringify(pageCategoryRequest)) + '&start=' + this.skip + '&limit=' + 2 + '&showCount=' + 9;
    this.homeService.getPageCategories(fiterString).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          console.log(res);
          const pageCategory: PageCategoryData = new PageCategoryData();
          if (IsScrrol) {
            if (res.data.length > 0) {
              res.data.forEach(record => {
                pageCategory.data = this.pageCategoryData.data.filter(
                  content => content.path == record.path
                );
              });
              if (pageCategory.data.length === 0) {
                res.data.forEach((record, index) => {
                  this.pageCategoryData.data.push(record);
                  // pageCategory.data[0].is_loading = true;
                  if (record.genre.length > 0) {
                    this.getDefualtPageList(record.path);
                  } else {
                    const pageCategory: PageCategoryData = new PageCategoryData();
                    pageCategory.data = this.pageCategoryData.data.filter(
                      content => content.path === record.path
                    );
                    pageCategory.data[0].data = res.data[index].data;
                    pageCategory.data[0].isGenrePath = 0;
                    // this.isPageCategory = true;
                    pageCategory.data[0].isPageCategory = true;

                    if (pageCategory.data[0].data.length > 1) {
                      pageCategory.data[0].isDisplay = true;
                    } else if (pageCategory.data[0].genre.length > 0) {
                      pageCategory.data[0].isDisplay = true;
                    } else if (pageCategory.data[0].assetGroupClassification.length > 0) {
                      pageCategory.data[0].isDisplay = true;
                    } else {
                      pageCategory.data[0].isDisplay = false;
                    }
                    // pageCategory.data[0].is_loading = false;
                    $('#page_category_list_' + pageCategory.data[0].path).trigger(
                      'destroy.owl.carousel'
                    );

                    setTimeout(() => {
                      if (
                        pageCategory.data[0].genre.length != 0 ||
                        pageCategory.data[0].assetGroupClassification.length != 0
                      ) {
                        this.refreshSubCategoryCarousel(pageCategory.data[0].path);
                      }

                      if (
                        pageCategory.data[0].displayType == 'Horizontal' &&
                        pageCategory.data[0] !== null &&
                        pageCategory.data[0].data.length > 1
                      ) {
                        this.refreshHorizontalCarousel(pageCategory.data[0].path);
                      } else if (
                        pageCategory.data[0].displayType == 'Vertical' &&
                        pageCategory.data[0] !== null &&
                        pageCategory.data[0].data.length > 1
                      ) {
                        this.refreshVerticalCarousel(pageCategory.data[0].path);
                      } else if (
                        pageCategory.data[0].displayType == "Custom" &&
                        pageCategory.data[0] !== null &&
                        pageCategory.data[0].data.length > 1
                      ) {
                        this.refreshCustomCarousel(pageCategory.data[0].path);
                      } else if (
                        pageCategory.data[0].displayType == 'Listing' &&
                        pageCategory.data[0] !== null &&
                        pageCategory.data[0].data.length > 1
                      ) {
                        this.refreshListingCarousel(pageCategory.data[0].path);
                        this.refreshListingTooltip();
                      }
                    }, 50);
                  }
                });
              }
            }
          } else {
            this.pageCategoryData = res;
            // pageCategory.data[0].is_loading = true;
            this.pageCategoryData.data.forEach((pagelist, index) => {
              if (pagelist.genre.length > 0) {
                this.getDefualtPageList(pagelist.path);
              } else {
                const pageCategory: PageCategoryData = new PageCategoryData();
                pageCategory.data = this.pageCategoryData.data.filter(
                  content => content.path === pagelist.path
                );

                pageCategory.data[0].is_loading = true;
                pageCategory.data[0].data = pagelist.data;
                pageCategory.data[0].isGenrePath = 0;
                pageCategory.data[0].is_loading = false;
                // this.isPageCategory = true;
                pageCategory.data[0].isPageCategory = true;
                if (pageCategory.data[0].data.length > 1) {
                  pageCategory.data[0].isDisplay = true;
                } else if (pageCategory.data[0].genre.length > 0) {
                  pageCategory.data[0].isDisplay = true;
                } else if (pageCategory.data[0].assetGroupClassification.length > 0) {
                  pageCategory.data[0].isDisplay = true;
                } else {
                  pageCategory.data[0].isDisplay = false;
                }
                $('#page_category_list_' + pageCategory.data[0].path).trigger(
                  'destroy.owl.carousel'
                );

                setTimeout(() => {
                  if (
                    pageCategory.data[0].genre.length != 0 ||
                    pageCategory.data[0].assetGroupClassification.length != 0
                  ) {
                    this.refreshSubCategoryCarousel(pageCategory.data[0].path);
                  }

                  if (
                    pageCategory.data[0].displayType == 'Horizontal' &&
                    pageCategory.data[0] !== null &&
                    pageCategory.data[0].data.length > 1
                  ) {
                    this.refreshHorizontalCarousel(pageCategory.data[0].path);
                  } else if (
                    pageCategory.data[0].displayType == 'Vertical' &&
                    pageCategory.data[0] !== null &&
                    pageCategory.data[0].data.length > 1
                  ) {
                    this.refreshVerticalCarousel(pageCategory.data[0].path);
                  } else if (
                    pageCategory.data[0].displayType == "Custom" &&
                    pageCategory.data[0] !== null &&
                    pageCategory.data[0].data.length > 1
                  ) {
                    this.refreshCustomCarousel(pageCategory.data[0].path);
                  } else if (
                    pageCategory.data[0].displayType == 'Listing' &&
                    pageCategory.data[0] !== null &&
                    pageCategory.data[0].data.length > 1
                  ) {
                    this.refreshListingCarousel(pageCategory.data[0].path);
                    this.refreshListingTooltip();
                  }
                }, 50);
              }
            });
          }
        } else if (res !== undefined && !res.success) {
          console.log(res);
        }
        // this.pageCategoriesLoading = false;
      },
      err => {
        console.log(err);
      }
    );
  }

  public gotoPremiumVideo(): void {
    this.router.navigate(['premium']);
  }

  public getDefualtPageList(path: string): void {

    console.log('gener===>', path);
    const pageListRequest = { deviceTypeId: '1', path: path, type : 'page_category',genre: [],langId : '1'};

    $('#page_subcategory_' + path + ' li a').removeClass('active');

    $('#page_subcategory_item_' + path + ' a').addClass('active');
    const pageCategory: PageCategoryData = new PageCategoryData();
    pageCategory.data = this.pageCategoryData.data.filter(
      content => content.path === path
    );
    pageCategory.data[0].is_loading = true;
    let fiterString = '?filter=' + encodeURI(JSON.stringify(pageListRequest)) + '&start=0&limit=7';
    this.homeService.getDefualtPageList(fiterString).subscribe(
      res => {
        if (res !== undefined && res.success === true) {
          pageCategory.data = this.pageCategoryData.data.filter(
            content => content.path == path
          );
          pageCategory.data[0].isGenrePath = 0;
          pageCategory.data[0].data = res.data[0].data;
          // this.isPageCategory = true;
          pageCategory.data[0].isPageCategory = true;
          if (pageCategory.data[0].data.length > 1) {
            pageCategory.data[0].isDisplay = true;
          } else {
            pageCategory.data[0].isDisplay = false;
          }
          console.log('pageCategory.data[0].data', pageCategory.data[0].data);
          pageCategory.data[0].is_loading = false;
          $('#page_category_list_' + pageCategory.data[0].path).trigger(
            'destroy.owl.carousel'
          );

          setTimeout(() => {
            if (
              pageCategory.data[0].genre.length != 0 ||
              pageCategory.data[0].assetGroupClassification.length != 0
            ) {
              this.refreshSubCategoryCarousel(pageCategory.data[0].path);
            }

            if (
              pageCategory.data[0].displayType == 'Horizontal' &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshHorizontalCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType == 'Vertical' &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshVerticalCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType == "Custom" &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshCustomCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType == 'Listing' &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshListingCarousel(pageCategory.data[0].path);
              this.refreshListingTooltip();
            }
          }, 50);
        }
      },
      err => {
        console.log(err);
      }
    );
  }
  // isGenerClicked(){
  //   if(Object.keys(this.selectedGenreMap).length > 0){
  //     return true;
  //   }
  //   return false;
  // }
  public getGenrePageList(genrepath: string, path: string): void {
    // this.selectedGenreMap['path'] = genrepath;
    // console.log("this.selectedGenreMap[path]", this.selectedGenreMap)
    const genredataRequest = {
      'path': path,
      'deviceTypeId': '1',
      'genre': [genrepath],
      'langId' : '1'
    };
    let genreFiterString = '?filter=' + encodeURI(JSON.stringify(genredataRequest)) + '&start=0&limit=7';

    $('#page_subcategory_' + path + ' li a').removeClass('active');

    $('#page_subcategory_item_' + path + '_' + genrepath + ' a').addClass(
      'active'
    );

    const pageCategory: PageCategoryData = new PageCategoryData();
    pageCategory.data = this.pageCategoryData.data.filter(
      content => content.path == path
    );
    pageCategory.data[0].isGenrePath = genrepath;
    pageCategory.data[0].is_loading = true;
    this.homeService.getDefualtPageList(genreFiterString).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          pageCategory.data[0].isPageCategory = false;
          pageCategory.data[0].data = res.data[0].data;
          pageCategory.data[0].is_loading = false;
          $('#page_category_list_' + pageCategory.data[0].path).trigger(
            'destroy.owl.carousel'
          );

          setTimeout(() => {
            if (
              pageCategory.data[0].genre.length !== 0 ||
              pageCategory.data[0].assetGroupClassification.length !== 0
            ) {
              this.refreshSubCategoryCarousel(pageCategory.data[0].path);
            }

            if (
              pageCategory.data[0].displayType === 'Horizontal' &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshHorizontalCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType === 'Vertical' &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshVerticalCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType === "Custom" &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshCustomCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType === 'Listing' &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshListingCarousel(pageCategory.data[0].path);
              this.refreshListingTooltip();
            }
          }, 50);
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  public getAssetGroupList(assetpath: AssetGroupClassification, path: string): void {
    const pageListRequest = {
      deviceTypeId: '1',
      assetGroupClassification: assetpath,
      path: path,
      limit: 7,
      langId : '1'
    };
    $('#page_subcategory_' + path + ' li a').removeClass('active');

    $('#page_subcategory_item_' + path + '_' + assetpath + ' a').addClass(
      'active'
    );

    this.homeService.getDefualtPageList(pageListRequest).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          const pageCategory: PageCategoryData = new PageCategoryData();
          pageCategory.data = this.pageCategoryData.data.filter(
            content => content.path === path
          );
          pageCategory.data[0].data = res.data[0].data;
          $('#page_category_list_' + pageCategory.data[0].path).trigger(
            'destroy.owl.carousel'
          );

          setTimeout(() => {
            if (
              pageCategory.data[0].genre.length != 0 ||
              pageCategory.data[0].assetGroupClassification.length != 0
            ) {
              this.refreshSubCategoryCarousel(pageCategory.data[0].path);
            }

            if (
              pageCategory.data[0].displayType == 'Horizontal' &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshHorizontalCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType == 'Vertical' &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshVerticalCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType == "Custom" &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshCustomCarousel(pageCategory.data[0].path);
            } else if (
              pageCategory.data[0].displayType == 'Listing' &&
              pageCategory.data[0] !== null &&
              pageCategory.data[0].data.length > 1
            ) {
              this.refreshListingCarousel(pageCategory.data[0].path);
              this.refreshListingTooltip();
            }
          }, 50);
        }
      },
      err => {
        console.log('errror');
        console.log(err);
      }
    );
  }

  gotoAsset(asset) {

    if (asset.clickThrough != null && asset.clickThrough != '') {
      window.open(asset.clickThrough, '_blank');
    } else if (asset.type == 'asset') {
      const title = asset.title;
      this.router.navigate(['/video', title.replace(/\s/g, ''), asset.path]);
    } else if (asset.type == 'asset_group') {
      this.router.navigate(['/season', asset.path]);
    }
  }
  gotoCarouselAsset(asset) {
    this.router.navigate([asset.clickThrough]);
  }

  public gotoVideoPage(pagedata: PageData, carousel: carouselData): void {
    console.log(pagedata);
    if (carousel !== null) {
      if (carousel.type === 'asset' && carousel.path !== null) {
        // this.router.navigate(['/video-page'], {
        //   queryParams: { path: contents.path }
        // });

        // pending
        // this.router.navigate([
        //   "/video",
        //   contents..substr(1),
        //   contents.path
        // ]);

        // return '/video-page/' + contents.path;
      } else if (carousel.type === 'asset_group') {
        this.router.navigate(['/season', carousel.path]);
        // return '/asseet-group/' + contents.path;
      }
    } else {
      if (pagedata.type === 'asset_group') {
        this.router.navigate(['/season', pagedata.path]);
        // return '/asseet-group/' + pagedata.path;
      }
     else if (pagedata.assetType.toLowerCase()  === 'asset' && pagedata.asset_group_id == null) {
        this.router.navigate(['/video', pagedata.title.split(/\s/).join(''), pagedata.path]);
      }
      else if (pagedata.asset_group_id != null && pagedata.asset_group_id > 0) {
        localStorage.setItem('pagecategoryUrl', pagedata.path);
        this.router.navigate(['/season', pagedata.asset_group_id]);
      }
    }
  }

  public openGenresListing(path: string, genrePath): void {
    const myurl = '/geners';
    let param = {
      navigationpath: this.params.path,
      path: path
    };
    if (genrePath != 0) {
      // param["genrePath"] = this.selectedGenreMap['path'];
      this.router.navigate([myurl, param['navigationpath'], param['path'], genrePath]);
    } else {
      this.router.navigate([myurl, param['navigationpath'], param['path']]);
    }

    // this.router.navigate([myurl], {
    //   queryParams: param
    // });
  }

  public bindPageListing(pagedata: PageData[]): PageData[] {
    this.pagelist = null;
    if (pagedata.length !== 0) {
      pagedata.forEach((data, index) => {
        if (index !== 0) {
          if (this.pagelist != null) {
            this.pagelist = this.pagelist.concat(data);
          } else {
            this.pagelist = [];
            this.pagelist = this.pagelist.concat(data);
          }
        }
      });
    }

    return this.pagelist;
  }

  public addTofavourite(assetPath: string, pagecategory, imagenumber, index): void {
    console.log(assetPath);
    console.log(pagecategory);
    console.log(index);
    if (
      BaseComponent.baseUser !== undefined &&
      Object.keys(BaseComponent.baseUser).length !== 0
    ) {
      const pageRequest = {
        assetId: assetPath
      };
      this.videopageService.addTofavourite(pageRequest).subscribe(
        res => {
          if (res !== undefined && res.success == true) {
            this.successResponse(res.message);
            const indexofCategory = this.pageCategoryData.data.findIndex((categoryIndex) => {
              return categoryIndex.path == pagecategory.path;
            });
            if (imagenumber == 1) {
              return this.pageCategoryData.data[indexofCategory].data[0].isUserLikes = 1;
            } else if (imagenumber == 6) {
              let indexData = index + 1;
              return this.pageCategoryData.data[indexofCategory].data[indexData].isUserLikes = 1;
            }
            // pageCategory.data[0].is_loading = true;
            // return;
          } else if (res !== undefined && res.success == false) {
            this.errMessageResponse(res.message);
            // return;
          }
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.warningMessageResponse('Inicia sesión para agregarlo a tus favoritos');
    }
  }

  public removeTofavourite(assetPath: string, pagecategory, imagenumber, index): void {
    console.log(assetPath);
    console.log(pagecategory);
    console.log(index);
    if (
      BaseComponent.baseUser !== undefined &&
      Object.keys(BaseComponent.baseUser).length !== 0
    ) {
      const pageRequest = {
        assetId: assetPath
      };
      this.videopageService.removeTofavourite(pageRequest).subscribe(
        res => {
          if (res !== undefined && res.success == true) {
            this.successResponse(res.message);
            const indexofCategory = this.pageCategoryData.data.findIndex((categoryIndex) => {
              return categoryIndex.path == pagecategory.path;
            });
            if (imagenumber == 1) {
              return this.pageCategoryData.data[indexofCategory].data[0].isUserLikes = 0;
            } else if (imagenumber == 6) {
              let indexData = index + 1;
              return this.pageCategoryData.data[indexofCategory].data[indexData].isUserLikes = 0;
            }
          } else if (res !== undefined && res.success == false) {
            this.errMessageResponse(res.message);
            // return;
          }
        },
        err => {
          console.log(err);
        }
      );
    } else {
      this.warningMessageResponse('Please Login To Add To Favorites');
    }
  }

  onScroll() {
    if (this.params !== undefined) {
      this.getPageCategorie(this.params, true, 2);
    }

    // this.pageCategoriesLoading = true;
    // this.skippedItems = this.skippedItems + this.initialLoadItems;
    // this.getPageCategorie(this.params);
  }


  shortCarouselVideo(caroselVideoResponse) {
    var self = this;
    this.showHomeVideo = true;
    // this.route.params.subscribe((params: Params) => {
    this.videopageData = null;
    // if (params.name.toLocaleLowerCase() == "home") {
    var carouselVideoPath = 0;
    carouselVideoPath = caroselVideoResponse.path;

    const assetDetailRequest = { 'deviceTypeId': '1' };
    const assetDetailFiterString = '?filter=' + encodeURI(JSON.stringify(assetDetailRequest));
    this.videopageService.getAssetDetails(carouselVideoPath, assetDetailFiterString).subscribe(
      res => {
        if (res !== undefined && res.success === true) {
          this.videopageData = res.data[0];
          $('#volume-off').hide();
          $('#volume-on').hide();
          if (res.data.length > 0) {
            $('#volume-off').hide();
            $('#volume-on').hide();
            this.showImage = true;
            this.showHomeVideo = true;
          }
        } else {
          $('#volume-off').hide();
          $('#volume-on').hide();
          this.showImage = true;
          this.showHomeVideo = true;
        }
        //     })
        // }
      }, err => {
        $('#volume-off').hide();
        $('#volume-on').hide();
        this.carouselLength = false;
        this.showImage = true;
        this.showHomeVideo = true;
      });
  }

  ngOnDestroy() {
    console.log('ngOnDestroy');
  }

  sliderScript() {
    $(document).ready(function () {
      $('.homeSlider').owlCarousel({
        loop: true,
        margin: 21,
        nav: true,
        navText: [
          '<img src="./assets/images/arrow-back.png">',
          '<img src="./assets/images/arrow-next.png">'
        ],
        autoplay: true,
        autoHeight: false,
        autoplayHoverPause: true,
        responsive: {
          0: {
            items: 1,
            nav: false
          },
          768: {
            items: 1
          },
          1000: {
            items: 1
          }
        }
      });
    });
  }
  checkIsLive(startDate, endDate, timeZone) {
    if (timeZone == null) {
      timeZone = "-5:00";
    }
    let offset = timeZone;

    let [h, m] = offset.split(':');

    let hours1 = Number.parseInt(h);
    let minutes1 = Number.parseInt(m);

    let totalMinutes = hours1 * 60 + (hours1 < 0 ? (-minutes1) : minutes1);

    let localStartDate = moment(moment.utc(startDate).local().format('YYYY-MM-DD HH:mm:ss')).subtract(totalMinutes, 'minutes').toDate();
    let localEndDate = moment(moment.utc(endDate).local().format('YYYY-MM-DD HH:mm:ss')).subtract(totalMinutes, 'minutes').toDate();
    // console.log('start date==>',localStartDate);
    // console.log('end date==>',localEndDate);
    if (moment().isBetween(localStartDate, localEndDate)) {
      return true;
    } else {
      return false;
    }
  }

  public getGeners(): void {
   // debugger;
    const pageRequest = {
      "langId":1
    };
    const genresFiterString = "?filter=" + encodeURI(JSON.stringify(pageRequest));
    this.homeService.getGenres(genresFiterString).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          //debugger;
          this.genresData = res.data.data;
          this.onInitloader = false;
          console.log(this.genresData);
        }
      },
      err => {
        console.log(err);
      }
    );

  }
  public navigateCategory(item):void{
    this.router.navigate(["category/"+item.title+"/"+item.path]);      
  }

  public getAuthenticateToken(): void {
    this.headerService.getToken().subscribe(
      res => {
        if (res !== undefined) {
          this.setGuestToken('Bearer ' + res);
          var body = res;
          var base64Url = body.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
          this.refereshToken = JSON.parse(jsonPayload);

          var dt = new Date(this.refereshToken['exp'] * 1000);
          BaseComponent.guestTime = moment(dt).subtract(50, 'seconds').format('HH:mm:ss').toString();
         

        }
      },
      err => {
        console.log(err);
      }
    );
  }

}


