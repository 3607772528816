import { Channel, ShowSchedule, Schedules } from './../model/schedule.model';
import { Params, NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy, Renderer2, Inject } from '@angular/core';
import { BaseComponent } from '../../base/component/base.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { VuuzleTvService } from '../services/vuuzle-tv.service';
import * as moment from 'moment';
import { HeaderService } from '@header/services/header.service';
import { ServiceLocator } from '@base/constants/service-locator';
import {
  ScheduleData,
  ScheduleResponse
} from '@vuuzle-tv/model/schedule.model';
import { NavigationData } from '@header/model/header.model';
import { DOCUMENT } from '@angular/common';
// import * as THEOplayer from '../../../assets/vendor/bootstrap/js/THEOplayer.js';
import { environment } from '@environments/environment';

declare var $: any;
declare const bitmovin: any;
declare const THEOplayer: any;

@Component({
  selector: 'app-vuuzle-tv',
  templateUrl: '../view/vuuzle-tv.component.html',
  styleUrls: ['../css/vuuzle-tv.component.css']
})
export class VuuzleTvComponent extends BaseComponent implements OnInit, OnDestroy {
  playerObject: any;
  path: any;
  type: string;
  limit = '';
  skip = 0;
  listingData: Channel;
  userId: any;
  videoDetails: any;
  schedule: any;
  scheduleList: ScheduleResponse;
  tempscheduleList: ScheduleResponse;
  scheduleFilterList: ScheduleResponse;
  someDateString: any;
  filtered_date: any = [];
  tempFiltered_date: any = [];
  headerService: HeaderService;
  selectedDate: string;
  selectedChannel: string;
  activeChannel: any = {};
  dateList: any = [];
  loading: boolean;
  showDateCarouse: boolean;
  dateCarouselLength: number;
  dateCarouselCount: number;
  curruntTitle: string;
  curruntDescription: string;
  sameForAllZone = 0;
  showVideoError: boolean = false;
  isShowImage: boolean = false;
  showVideo: boolean = false;
  geoStatus: boolean = false;
  navigationData: NavigationData;
  public NavigationParams = new URLSearchParams();
  curruntTime: string;
  videoUrl: string;
  posterImage: string;
  startTime: string;
  destroyInterval: any;
  bitMovieUnsubsribe: any;
  clearTimeOut: any;
  playTimeOut: any;
  resizeTimeout = -1;
  channelCurrentPath = 0;
  player;
  constructor(
    private http: HttpClient,
    public router: Router,
    public route: ActivatedRoute,
    private vuuzleTvService: VuuzleTvService,
    private rendrer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    super();
    this.headerService = ServiceLocator.injector.get(HeaderService);
    this.route = route;
  }

  ngOnInit() {
    console.log("test")
    this.rendrer.removeClass(this.document.body, 'transperent-layout');
    this.rendrer.removeClass(this.document.body, 'hide-header');
    this.rendrer.removeClass(this.document.body, 'hide-footer');
   // window.onscroll = this.adjustPlayer;
    $('#posterImage').css('display', '');
    this.loading = true;
    this.showDateCarouse = false;

    $(document).ready(function () {
      setTimeout(() => {
        $('tbody').scroll(function (e) {
          $('thead').css("left", -$("tbody").scrollLeft()); //fix the thead relative to the body scrolling
          $('thead th:nth-child(1)').css("left", $("tbody").scrollLeft()); //fix the first cell of the header
          $('tbody td:nth-child(1)').css("left", $("tbody").scrollLeft()); //fix the first column of tdbody
        });
      });
    }, 1500);

    this.userId = this.getUserId();
    $(document).ready(function () {
      $(this).scrollTop(0);
    });

    $('#date-listing li').click(function () {
      $(this).css('background', '#008FD5');
    });

    $('#page_category_list').owlCarousel({
      loop: false,
      margin: 13,
      nav: true,
      navText: [
        '<img src="./assets/images/arrow-back.png">',
        '<img src="./assets/images/arrow-next.png">'
      ],
      autoplay: false,
      autoHeight: false,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 2,
          nav: false
        },
        768: {
          items: 3
        },
        1000: {
          items: 6
        }
      }
    });

    this.route.params.subscribe((params: Params) => {
      this.channelCurrentPath = params.path;
      this.navigationData = null;
      const filterNavigation: NavigationData = new NavigationData();

      if (BaseComponent.globalNavigationData === undefined || Object.keys(BaseComponent.globalNavigationData).length === 0) {
        const navigationObject = {
          deviceTypeId : 1,
          langaugeCode : 1
        }
        this.headerService.getNavigation(navigationObject).subscribe(
          result => {
            if (result !== undefined && result.success == true) {
              this.navigationData = result.data;
              filterNavigation.data = this.navigationData.data.filter(res =>
                res.primaryNavigationType == 'Live'
              );
              this.setNavigationData(filterNavigation);
              return true;
            }
          });
      } else if (BaseComponent.globalNavigationData !== undefined && Object.keys(BaseComponent.globalNavigationData).length !== 0) {
        filterNavigation.data = BaseComponent.globalNavigationData.data.filter(res =>
          res.primaryNavigationType == 'Live'
          );
        this.setNavigationData(filterNavigation);
        return true;
      }
    });
  }


  public setNavigationData(navigate: NavigationData): void {
    if (navigate !== undefined && navigate !== null) {
      this.path = navigate.data[0].path;
      this.type = navigate.data[0].type;
      console.log(navigate.data[0])
      this.headerService.setActivePath.emit(navigate.data[0].title);
      if (this.listingData == undefined) {
      //this.getListing();
      this.playstaticvideo();

     }
    }
  }

  playstaticvideo(){
    this.loading = false;
    this.showVideo = true;
    const element = document.querySelector('.theoplayer-container');
    this.player = new THEOplayer.Player(element, {
      libraryLocation: 'https://cdn.myth.theoplayer.com/c69d7e91-49de-4237-aa42-da5a20c2723f/',
      license: 'sZP7IYe6T6fzIKCiTQfoTmzzIDCZFSaLTQh-ClhrIOzoIubL3Dhr3uaZIlf6FOPlUY3zWokgbgjNIOf9fKBL0LftCoakFSXl0Sa-3QIK36z_CDIgFSUg3L5k3o46Clho0ZfVfK4_bQgZCYxNWoryIQXzImf90SCt3SBkTSfi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3laoTSBk0lfZ0ueoFOPeWok1dDrLYtA1Ioh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfKxqWDXNWG3ybojkbK3gflNWf6E6FOPVWo31WQ1qbta6FOPzdQ4qbQc1sD4ZFK3qWmPUFOPLIQ-LflNWfK1zWDikfgzVfG3gWKxydDkibK4LbogqW6f9UwPkIYz'
    });

    this.player.source = {
      sources: [
        {
          src: 'https://content.uplynk.com/channel/ext/22951fb5d6784289bd499e229e81c84c/80.m3u8?tc=1&exp=1646225852575&rn=8024786309&ct=c&ad=live&oid=22951fb5d6784289bd499e229e81c84c&eid=80&euid=80&sig=44355263f9fd6fe0253604c84c378bc219bbf514c6ad5dd5ffde95c619ce6b78',
          type: 'application/x-mpegurl', // sets type to MPEG-DASH,
          lowLatency: false
        }],
        ads: [
          {
            sources: environment.theoplayerAddurl,
            timeOffset: "start"
          }
        ]
    };

    this.player.autoplay = true
  }

  dateCarousel(): void {
    let self = this;
    this.showDateCarouse = true;
    $('#date-listing').trigger('destroy.owl.carousel');
    setTimeout(function () {
      $('#date-listing').owlCarousel({
        loop: false,
        margin: 2,
        nav: true,
        autoWidth: true,
        navText: [
          '<img src="./assets/images/arrow-back.png">',
          '<img src="./assets/images/arrow-next.png">'
        ],
        autoplay: false,
        autoplayHoverPause: false
      });
    }, 1000);
  }

  seekPlayer(duration) {
    this.playerObject.seek(this.playerObject.getCurrentTime() + duration);
  }

  videoChannel(playbackUrl, posterUrl) {
    setTimeout(() => {

      let self = this;

      if (this.startTime !== '') {
        let epoch_time = new Date(this.startTime);

        let date = new Date(this.startTime);
        let now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
          date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        let epoch_time_new = Math.round(now_utc / 1000.0);
        epoch_time.setHours(epoch_time.getHours() + 10);
        playbackUrl = playbackUrl + '?ts=' + epoch_time_new;
      }

      // console.log(playbackUrl);
      const source = {
        hls: playbackUrl,
        poster: posterUrl,
        // analytics: {
        //   videoId: this.selectedChannel,
        //   title: this.curruntTitle
        // }
      };

      // analytics: {
      //   key: '26008b07-dea7-4705-b6f8-6819e1db26c7',
      //   videoId: this.selectedChannel,
      //   title: this.curruntTitle
      // },
      if (this.selectedChannel) {
        var vodId = this.selectedChannel
      }
      if (this.curruntTitle) {
        var vodTitle = this.curruntTitle
      }

      const config = {
        key: 'e402008a-94c7-4288-912b-2e4ab92d5d14',
        analytics: {
          key: 'ac814c2b-40b6-4085-8d3d-073db406b5d2',
          videoId: vodId,
          title: vodTitle
        },
        playback: {
          autoplay: true
        },
        events: {
          playerresized: function (ev) {
            /**
             * when the player switches from minimized to full size, update the container size to prevent the page
             * from jumping when scrolling up
             * work with a timeout as the resize event is triggered periodically and when resizing back to the original size,
             * the first few events will have a smaller size as the player is gradually growing.
             */
            if (
              !$('.player-switch').hasClass('fixed-player')
            ) {
              clearTimeout(this.resizeTimeout);
              this.resizeTimeout = setTimeout(function () {
                $('.player-container'
                ).height(ev.height.substr(0, ev.height.indexOf('px')) - 0);
              },
                250
              )
                ;
            }
          }
        }
      };

      let onVideoPlay = function (current_playback_position) {
        // // console.log('getFacebookPixle call');
        // self.getFacebookPixle();
      };

      if (this.playerObject === undefined) {
        const container = document.getElementById('bitmovin-player');
        const player = new bitmovin.player.Player(container, config);
        player.on(bitmovin.player.PlayerEvent.TimeChanged);
        player.on(bitmovin.player.PlayerEvent.Paused);
        player.on(bitmovin.player.PlayerEvent.Play, onVideoPlay);
        this.playerObject = player;
      }
      this.bitMovieUnsubsribe = this.playerObject.load(source).then(function (player) {
        let container = $('.player-container');
        let playerHeight = $('#bitmovin-player').height();
        if (container.height() <= playerHeight) {
          container.height(playerHeight);
        }
        self.playTimeOut = setTimeout(() => {
          if (self.playerObject !== undefined) {
            self.playerObject.play();
          }
        }, 1000);

      }, function (reason) {
        // console.log(reason);
      });
    }, 0.8);

    // setTimeout(() => {
    //   window.scroll(0, 0);
    // }, 30);
  }

  adjustPlayer() {
    const container = $('.player-container');

    /* extract constants for better readabilty */
    const lowerEdge = container.height();
    const switchToMinPlayerPos = lowerEdge - (window.innerHeight / 16);
    const currentScrollPos = document.body.scrollTop || document.documentElement.scrollTop;

    /* toggle the css-class responsible for the player moving to the lower right corner */
    if (currentScrollPos > switchToMinPlayerPos) {
      $('.player-switch').addClass('fixed-player');
      if(document.getElementById("pip-close")) {
        document.getElementById("pip-close").onclick = function() {closePip()};
      }
    } else {
      $('.player-switch').removeClass('fixed-player');
    }
    function closePip() {
      window.scroll(0, 0)
    }
  }

  getListing() {
    this.dateCarouselLength = 0;
    let postBody = {
      path: this.path,
      type: this.type
    };
    this.vuuzleTvService.getListing(postBody).subscribe((result: any) => {
      if (result && result.success == true) {
        this.listingData = result;
        this.dateCarouselLength = this.listingData.data.length;
        this.dateCarouselCount = 0;
        if (this.channelCurrentPath == 0 || this.channelCurrentPath == undefined) {

        if (this.listingData && this.listingData.data.length > 1) {
          this.playVideo(result.data[0], false, false);
          this.selectedChannel = result.data[0].path;
          this.listingData.data.forEach((channel, index) => {
            this.getChannelSchedule(channel.path, index, true, false, true, true);
          });
        }
        this.refreshChannelList();
      } else {
        if (this.listingData && this.listingData.data.length > 1) {
          for (let channelPath of this.listingData.data) {
            if (Number(channelPath.path) == Number(this.channelCurrentPath)) {
              this.playVideo(channelPath, false, false);
              this.selectedChannel = channelPath.path;
              this.listingData.data.forEach((channel, index) => {
                this.getChannelSchedule(channel.path, index, true, false, true, true);
              });
            }
          }
          }
      }
    }
    });
  }

  public refreshChannelList() {
    this.clearTimeOut = setTimeout(() => {
      this.destroyInterval = setInterval(() => {
        this.listingData.data.forEach((channel, index) => {
          this.getChannelSchedule(channel.path, index, false, true, false, false);
        });
      }, 30000);

    }, 60000);

  }

  public getChannelSchedule(channelPath: string, channelIndex: number,
                            setNewDate: boolean, updateDescription: boolean, isupdateVideo: boolean, isUpdateTitleAndDescription: boolean) {
    const assetId = { assetId: channelPath, "deviceTypeId":"1" }
    this.vuuzleTvService
      .getChannel(assetId)
      .subscribe(result => {
        if (result && result.success == true) {
          this.dateCarouselCount = this.dateCarouselCount + 1;
          this.listingData.data[channelIndex].schedule =
            result['data']['data']['schedule'];

          if (this.selectedChannel === channelPath && isUpdateTitleAndDescription) {
            this.getCurruntChannleTitleAndDescription(result['data']['data']['schedule'], isupdateVideo, false);
          }
          if (setNewDate) {
            this.getDateSchedule(result['data']['data']['schedule']);
          } else if (!setNewDate) {
            this.getTempDateSchedule(result['data']['data']['schedule']);
          }

        } else if (result && !result.status) {
          this.dateCarouselCount = this.dateCarouselCount + 1;
        }

      }, err => {
        this.dateCarouselCount = this.dateCarouselCount + 1;
        console.log(err);
      });

  }

  public getCurruntChannleTitleAndDescription(currentChannel: ShowSchedule[], isupdateVideo: boolean,
                                              isSetTilteAndDescription: boolean): boolean {

    let currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
    let dta;

    if (isSetTilteAndDescription) {
      if (isupdateVideo && this.showVideo) {
        this.videoChannel(this.videoUrl, this.posterImage);
      }

      return true;
    } else if (!isSetTilteAndDescription) {
      currentChannel.forEach(res => {
        let startTime = moment.utc(res.start).local().format('YYYY-MM-DD HH:mm:ss');
        let endTime = moment.utc(res.start)
          .add(res.duration, 'seconds').local()
          .format('YYYY-MM-DD HH:mm:ss');

        if (
          moment(currentTime).isSameOrAfter(startTime) &&
          moment(currentTime).isBefore(endTime)
        ) {
          if ((res.title !== '' && res.title !== null) && (res.description !== '' && res.description !== null)) {
            this.curruntTitle = res.title;
            this.curruntDescription = res.description;
            this.curruntTime = res.start;
            if (isupdateVideo && this.showVideo) {
              this.videoChannel(this.videoUrl, this.posterImage);
            }
            dta = true;
          }
          else {
            // console.log("null data title desc");
            this.curruntTitle = '';
            this.curruntDescription = '';
            if (isupdateVideo && this.showVideo) {
              this.videoChannel(this.videoUrl, this.posterImage);
            }
            dta = true;
          }

          return true;
        }
      });
    }


    return dta;
  }
  public getTempDateSchedule(schedule: ShowSchedule[]) {
    schedule.forEach(element => {

      element.date =
        moment.utc(element.start).local().format('ddd') +
        ' | ' +
        moment.utc(element.start).local().format('MMM') +
        ' ' +
        moment.utc(element.start).local().format('Do') +
        ' ' +
        moment.utc(element.start).local().format('YYYY');

      if (this.dateList.indexOf(element.date) == -1) {
        this.dateList.push(element.date);
      }
    });

    this.tempFiltered_date = this.dateList.sort((a, b) => {
      let date1 = parseInt(moment(a).format('YYYYMMDD'));
      let date2 = parseInt(moment(b).format('YYYYMMDD'));

      return date1 - date2;
    });

    if (this.filtered_date.length > this.tempFiltered_date.length ||
      this.filtered_date.length < this.tempFiltered_date.length) {
      this.filtered_date = this.tempFiltered_date;
      this.dateCarousel();
    }

  }

  public getDateSchedule(schedule: ShowSchedule[]) {
    schedule.forEach(element => {

      element.date =
        moment.utc(element.start).local().format('ddd') +
        ' | ' +
        moment.utc(element.start).local().format('MMM') +
        ' ' +
        moment.utc(element.start).local().format('Do') +
        ' ' +
        moment.utc(element.start).local().format('YYYY');

      if (this.dateList.indexOf(element.date) == -1) {
        this.dateList.push(element.date);
      }
    });

    this.filtered_date = this.dateList.sort((a, b) => {
      let date1 = parseInt(moment(a).format('YYYYMMDD'));
      let date2 = parseInt(moment(b).format('YYYYMMDD'));

      return date1 - date2;
    });

    if (this.dateCarouselCount === this.dateCarouselLength) {
      this.dateCarousel();
    }

  }

  playVideo(channel, setEpochTime: boolean, isSetTilteAndDescription: boolean) {
    this.selectedChannel = channel.path;
    window.scrollTo(0, 0);
    if (this.playerObject !== undefined) {
      this.playerObject.pause();
    }

    // $('#posterImage').css('display', 'none');
    this.posterImage = channel.horizontalFilePath;
    this.loading = true;

    // var postBody = {};
    // if (this.userId) {
    //   postBody = {
    //     path: path,
    //     devic: '2',
    //     userId: this.userId
    //   };
    // } else {
    //   postBody = {
    //     path: path,
    //     device_type: '2',
    //     userId: ''
    //   };
    // }
    if (!setEpochTime) {
      this.startTime = '';
    }
    const channelTitle = channel.title.split(/\s/).join('')
    window.history.replaceState({}, '', `/channels/${channelTitle.replace("/","")}/${channel.path}`);
    // this.router.navigate(['/channels', channel.title.split(/\s/).join(''), channel.path]);
    this.vuuzleTvService.getChannel({ assetId: channel.path, "deviceTypeId":"1" }).subscribe(result => {
      if (result && result.success == true) {
        this.schedule = result.data.data.schedule;
        this.scheduleList = result;
        this.scheduleFilterList = JSON.parse(JSON.stringify(result));
        this.getCurruntChannleTitleAndDescription(this.schedule, true, isSetTilteAndDescription);
        let someDateString = moment().format('YYYY/mm/dd');

        this.selectedDate =
          moment(new Date()).format('ddd') +
          ' | ' +
          moment(new Date()).format('MMM') +
          ' ' +
          moment(new Date()).format('Do') +
          ' ' +
          moment(new Date()).format('YYYY');
      }
    });

    this.vuuzleTvService.getAsset(channel.path).subscribe(result => {
      if (result && result.success == true) {
        this.videoDetails = result.data[0];
        this.sameForAllZone = result.sameForAllZone;
        this.geoStatus = this.videoDetails.geoStatus;

        if ((BaseComponent.baseUser !== undefined &&
          Object.keys(BaseComponent.baseUser).length !== 0) && this.geoStatus) {
          this.isShowImage = false;
          this.showVideoError = false;
          this.showVideo = true;
          this.loading = false;
          this.videoUrl = this.videoDetails.playbackUrl;
          this.posterImage = this.videoDetails.horizontalFilePath;
          this.videoChannel(this.videoUrl, this.posterImage);
          // this.getCurruntChannleTitleAndDescription(this.schedule, true, isSetTilteAndDescription);
        } else if ((BaseComponent.baseUser !== undefined &&
          Object.keys(BaseComponent.baseUser).length !== 0) && !this.geoStatus) {
          this.isShowImage = false;
          this.showVideoError = true;
          this.showVideo = false;
          this.loading = false;
          this.playerObject = undefined;
        } else if (this.geoStatus) {
          $('#posterImage').css('display', '');
          // this.isShowImage = true;
          // this.showVideoError = false;
          // this.showVideo = false;
          // this.loading = false;
          // this.playerObject = undefined;
          this.isShowImage = false;
          this.showVideoError = false;
          this.showVideo = true;
          this.loading = false;
          this.videoUrl = this.videoDetails.playbackUrl;
          this.posterImage = this.videoDetails.horizontalFilePath;
          // this.getCurruntChannleTitleAndDescription(this.schedule, true, isSetTilteAndDescription);
          this.videoChannel(this.videoUrl, this.posterImage);
        } else if (!this.geoStatus) {
          this.isShowImage = false;
          this.showVideoError = true;
          this.showVideo = false;
          this.loading = false;
          this.playerObject = undefined;
        }
        this.loading = false;
      }
    });


  }

showDateWiseData(date) {
    this.selectedDate = date;
  }

  public gotToLoginPage(): void {
    this.warningMessageResponse('Inicia sesión para ver este contenido');
    this.router.navigate(['auth']);
  }

  // public goPreviousORNextEpisode(path: string, startTime: string, title: string, description: string) {
  //   if (title !== '') {
  //     // console.log("null data title desc");
  //     this.curruntTitle = title;
  //   } else {
  //     this.curruntTitle = '';
  //   }
  //   if (description !== '') {
  //     // console.log("null data title desc");
  //     this.curruntDescription = description;
  //   } else {
  //     this.curruntDescription = '';
  //   }
  //   this.startTime = startTime;
  //   this.playVideo(path, true, true);
  // }

ngOnDestroy() {
    clearTimeout(this.playTimeOut);
    clearTimeout(this.clearTimeOut);
    clearInterval(this.destroyInterval);
    if (this.playerObject !== undefined) {
      this.playerObject.pause();
      this.playerObject.destroy();
    }
    $('#date-listing').trigger('destroy.owl.carousel');
  }
}
