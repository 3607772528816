export class PageCategory {  //Tejash
    // path: string;
    // display_indexing: number;
    // display_type: string;
    // genre: PageGenre[];
    // asset_group_classification: AssetGroupClassification[];
    // type: string;
    // device: string;
    // same_for_all_orientation: boolean;
    // orientation: PageOrientation[];
    // is_loading: boolean = true;
    path: string;
    title: string;
    description: string;
    isActive: number;
    sameForAllZone: number;
    displayIndexing: string;
    sameForAllProductType: number;
    pcdlmId: number;
    verticalFileName: string;
    verticalFilePath: string;
    horizontalFileName: string;
    horizontalFilePath: string;
    dtypeId: number;
    langId: number;
    exclusionText: string;
    displayType: string;
      orderBy: string;
      isPrimaryNavigationVisible: number;
      isMenuCategoryVisible: number;
      isAssetGroupVisible: number;
      isAssetCategoryVisible: number;
      isAssetVisible: number;
      label: string;
      isVisible: number;
      mapCmsUserWithWatchlist: number;
      mapCmsUserPlaylist: number;
      mapCmsUserWithFavorite: number;
      uploadVerticalImage: string;
      uploadHorizontalImage: number;
      assetMappedCount: number;
      assetGenreMappedCount: number;
      assetGroupMappedCount: number;
      assetCategoryMappedCount: number;
      castMappedCount: number;
      classificationMappedCount: number;
      sorting: string;
      type: number;
      is_loading: boolean = true;
    data: PageData[];
    genre: genres[];
    assetGroupClassification : assetGroupClassificationData[];
    isDisplay?:boolean = true;
    isPageCategory?: boolean = true;
    isGenrePath?: any = 0;
    isPremium: number;
    contentType: string;
}
export class PageData {
    // path: string;
    // type: string;
    // created: string;
    // alias: string;
    // asset_group_classification: AssetGroupClassification[];
    // duration: string;
    // air_date: string;
    // end_date: string;
    // average_rating: number;
    // likes_count: number;
    // watched_count: number;
    // genre: DataGenre[];
    // device: string;
    // same_for_all_orientation: boolean;
    // asset_is_premium: boolean;
    // display_indexing: string;
    // license_config: [];
    // orientation: DataOrientation[];
    // order_by: string;
    // sorting: string;
    // user_can_see_asset: boolean;

          path: any;
          pageCategoryId: any;
          title: any;
          description: any;
          isActive: any;
          adlmId: any;
          deviceName: any;
          vodOrLivePosterImageFileName: any;
          vodOrLivePosterImageFilePath: any;
          vodClosedCaptionFileName: any;
          vodClosedCaptionFilePath: any;
          verticalFileName: any;
          verticalFilePath: any;
          horizontalFileName: any;
          horizontalFilePath: any;
          langaugeId: any;
          asset_category_id: any;
          langaugeName: any;
          langaugeCode: any;
          dtypeId: any;
          langId: any;
          exclusionText: any;
          sameForAllZone: any;
          sameForAllProductType: any;
          displayIndexing: any;
          clickThrough?: string;
          label: any;
          airStartDate: any;
          airStartTimezone: any;
          airEndDate: any;
          airEndTimezone: any;
          offlineDownload: any;
          mapTrailerVideo: any;
          uploadVerticalImage: any;
          uploadHorizontalImage: any;
          seoTitle: any;
          seoKeyword: any;
          seoDescription: any;
          lyrics: any;
          duration: any;
          ratingCount: any;
          totalRating: any;
          likeCount: any;
          isUserLikes: any;
          watchedCount: any;
          isUserWatched: any;
          averageRating: any;
          type: any;
          isPremium: number;
          contentType: string;
          assetType: any;
          asset_group_id:any;
}

export class genres {
    path: string;
    name: string;
}

export class assetGroupClassificationData {
    path: string;
    name: string;
}

export class LicenseConfig {
    device: string;
    package_concurrency: string;
    package_disp_name: string;
    path: string;
    same_for_all_orientation: boolean;
    usage: Usage[];
    orientation: LicenseConfigOrientation[];
}

export class Usage {
    hits: string;
    is_lifetime_free: boolean;
    minutes: string;
}

export class LicenseConfigOrientation {
    allow_offline_download: string;
    allow_offline_view: string;
    casting: string;
    description: string;
    label: string;
    name: string;
    streams: [];
}

export class DataOrientation {
    description: string;
    image: string;
    image_horizontal: string;
    is_visible: boolean;
    label: string;
    name: string;
    title: string;
}
export class PageOrientation {
    description: string;
    is_visible: boolean;
    label: string;
    name: string;
    title: string;
}

export class PageGenre {
    path: string;
    name: string;
}

export class AssetGroupClassification {
    path: string;
    name: string;
}

export class DataGenre {
    path: string;
    type: string;
    alias: string;
    same_for_all_geo_zone: boolean;
    exclusion_text: string;
    same_for_all_device_type: boolean;
    device: string;
    same_for_all_orientation: boolean;
    orientation: DataOrientation[];
    order_by: string;
}

// export class PageCategoryData { 
//     content: PageCategory[];
// }

export class PageCategoryData { //Tejash
    success: boolean;
    message: string;
    data: PageCategory[];
    iat: number;
}

