import { FooterService } from './../../footer/services/footer.service';
import { DOCUMENT } from '@angular/common';
import { Component, OnInit, ViewChild, Renderer2, Inject } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from '@authentication/service/authentication.service';
import {
  VerifyOtp,
  SignIn,
  ForgotPassword,
  registerRequest,
  profileDetailsRequest,
} from '@authentication/model/authentication.model';
import { ServiceLocator } from '@base/constants/service-locator';
import { HeaderService } from '@header/services/header.service';
import {
  AuthService,
  FacebookLoginProvider,
  SocialUser,
  GoogleLoginProvider
} from 'angularx-social-login';
import { BsDatepickerConfig, BsDaterangepickerDirective } from 'ngx-bootstrap/datepicker/public_api';
import * as moment from 'moment';
import { NavigationData } from '@header/model/header.model';
import { environment } from '@environments/environment';
import { Location } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-authentication',
  templateUrl: '../view/authentication.component.html',
  styleUrls: ['../css/authentication.component.css']
})
export class AuthenticationComponent extends BaseComponent implements OnInit {

  @ViewChild('dp', { static: false }) datepicker: BsDaterangepickerDirective;
  bsConfig: Partial<BsDatepickerConfig>;

  maxDate = new Date();
  minDate= new Date();

  getLocation: any = {};

  public authenticationService: AuthenticationService;
  headerService: HeaderService;
  public otp: string;
  public deviceType: number;
  public isLogin: boolean;
  public is_loading: boolean;
  refereshToken: {};
  otpEmail: any;
  lastDestinationUrl = '';
  navigationData: any = [];
  logo: string;
  nationTraffic: string;
  public profileStatus: boolean;
  // resetPasswordAfterLogin:boolean;
  // updateProfileAfterLogin:boolean;
  public user_id: string;
  public footerService: FooterService;
  // private myDatePickerOptions: IMyOptions = {

  //   dateFormat: 'dd.mm.yyyy',

  // };


  public registrationForm: FormGroup = new FormGroup({
    first_name: new FormControl('', [Validators.required,Validators.pattern('[a-zA-Z-$@$!%*?&#]*')]),
    last_name: new FormControl('', [Validators.required,Validators.pattern('[a-zA-Z-$@$!%*?&#]*')]),
    email: new FormControl('', [Validators.required, Validators.email,Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4}))$/)]),
    dob: new FormControl('', [Validators.required,Validators.min(100)]),
    password: new FormControl('', [Validators.required, Validators.pattern(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,15}')])
  });

  public profileDetailsForm: FormGroup = new FormGroup({
    first_name: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    email: new FormControl('', []),
    dob: new FormControl('', []),
    password: new FormControl('', [Validators.required, Validators.pattern(
      '(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9]).{6,}'
    )]),
    confirm_password: new FormControl('', [Validators.required, Validators.pattern(
      '(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9]).{6,}'
    )])
  }, this.passwordMatchValidator);

  public passwordMatchValidator(g: FormGroup) {
    return g.get('password').value === g.get('confirm_password').value ? null : { 'mismatch': true };
  }

  public loginform: FormGroup = new FormGroup({
    email_id: new FormControl('', [Validators.required, Validators.email,Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4}))$/)]),
    password: new FormControl('', [Validators.required,Validators.pattern(
      '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,15}')])
  });

  public otpform: FormGroup = new FormGroup({
    otp: new FormControl('', [Validators.required,Validators.pattern('[0-9]{6}')])
  });

  constructor(private authService: AuthService,
    private rendrer: Renderer2,private location: Location,
    @Inject(DOCUMENT) private document: Document) {
    super();
    this.headerService = ServiceLocator.injector.get(HeaderService);
    this.footerService = ServiceLocator.injector.get(FooterService);
  }

  ngOnInit() {
    this.rendrer.removeClass(this.document.body, 'transperent-layout');
    this.rendrer.addClass(this.document.body, 'hide-header');
    this.rendrer.addClass(this.document.body, 'hide-footer');
    this.lastDestinationUrl = localStorage.getItem('videoUrl');
    localStorage.setItem('check', 'true');
    this.nationTraffic = environment.nationTraffic;

    if (localStorage.getItem('check') == 'true') {
      this.headerService.getGeo().subscribe(data => {
        if (data.success == true) {
          this.getLocation = data.data;
        }
      });
    }

    if (localStorage.getItem('user_token') !== null) {
      this.router.navigate(['', 'home']);
    }

    var auth_msg = localStorage.getItem('auth_msg');
    if (localStorage.getItem('user_token') === null) {
      if (auth_msg !== '' && auth_msg === 'Unauthorized user') {

        this.headerService.getToken().subscribe(
          res => {
            if (res !== undefined) {
              this.setGuestToken('Bearer ' + res.data['token']);

              var body = res.data['token'];
              var base64Url = body.split('.')[1];
              var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
              var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
              }).join(''));
              this.refereshToken = JSON.parse(jsonPayload);

              var dt = new Date(this.refereshToken['exp'] * 1000);
              BaseComponent.guestTime = moment(dt).subtract(50, 'seconds').format('HH:mm:ss').toString();
            }
          });

        localStorage.removeItem('auth_msg');
        this.headerService.setLogin.emit(false);
        this.headerService.reloadHeader.emit(true);
        localStorage.removeItem('user');
        localStorage.removeItem('user_token');
        BaseComponent.baseUser = undefined;
        BaseComponent.isLogin = false;
        // this.warningMessageResponse(auth_msg);
      }
    }

    this.isLogin = BaseComponent.isLogin;
    this.profileStatus = BaseComponent.profileComplete;
    console.log(this.profileStatus);
    this.authenticationService = ServiceLocator.injector.get(
      AuthenticationService
    );
    this.authenticationService = ServiceLocator.injector.get(
      AuthenticationService
    );
    BaseComponent.isSignUp = true;
    var self = this;
    $('.login-controll').click(function () {
      const openDiv = $(this).attr('data-attr');
      $('.form-warper').hide();
      $(openDiv).show();
    });


    $('.auth-class').click(function () {
      $('.auth-class').removeClass('active');
      $(this).addClass('active');

    });

    $('#sign_in_button').click(function () {
      var signUpImage = '../../../assets/images/sign-up.png';
      $('.pop-up-bg').css('background-image', 'url(' + signUpImage + ')');
      $('.login-cross').css('color', 'black');
    });

    $('#sign_up_button').click(function () {
      var signInImage = '../../../assets/images/sign-in.png';
      $('.pop-up-bg').css('background-image', 'url(' + signInImage + ')');
      $('.login-cross').css('color', 'white');
    });

    $('.tgBUtton').click(function () {
      self.registrationForm.reset();
      var own_hid = $(this).attr('data-hidden');
      var own_open = $(this).attr('data-attr');
      var other_hid = $(this).attr('data-hide');
      var other_open = $(this).attr('data-open');

      $('.' + own_hid).hide();
      $('#' + own_open).show();

      $('#' + other_hid).hide();
      $('.' + other_open).css('display', 'flex');
    });

    $(document).ready(function () {
      $(this).scrollTop(0);
    });

    this.navigationData = BaseComponent.globalNavigationData;
    this.minDate=new Date();
      this.minDate.setDate( this.minDate.getDate()-73050);
  }

  ngOnDestroy() {
    localStorage.setItem('check', 'false');
  }

  public gotoForgotPassword(): void {
    this.router.navigateByUrl('forgot-password');
  }

  public checkRegisteredUser(registrationFormValue: FormGroup): void {  //Done
    const pageRequest = { emailId: registrationFormValue.value['email'] };
    this.authenticationService.checkRegistreUser(pageRequest).subscribe(res => {
      if (res !== undefined && res.success === true) {
        this.errMessageResponse(res.message);
        return;
      } else if (res !== undefined && res.success === false) {
        this.registerNewUser();
        // this.sendVerificationOTP(registrationFormValue.value['email']);
      }
    });
  }

  // $(".auth-class").click(function () {
  //   $(".auth-class").removeClass("active");
  //   $(this).addClass("active");
  //   });

  public menuClick() {
    var active_div = $('a.active');
    if (active_div[0].id == 'signIn') {
      $('#signIn').removeClass('active');
      $('#signUp').addClass('active');
      $('#signin').css('display', 'none');
      $('#signup').css('display', 'block');
    }
    if (active_div[0].id == 'signUp') {
      $('#signUp').removeClass('active');
      $('#signIn').addClass('active');
      $('#signin').css('display', 'block');
      $('#signup').css('display', 'none');
    }

    // $('.list-menu-tab ul li a').click(function () {
    //   $('.list-menu-tab ul li').removeClass('active');
    //   $(this)
    //     .parent('li')
    //     .addClass('active');

    //   var active_div = $(this).attr('dtat-target');
    //   $('.tabing').hide();
    //   $('#' + active_div).show();
    // });
    
    // $(".auth-class").click(function () {
    //   $(".auth-class").removeClass("active");
    //   $(this).addClass("active");
    // });

    // $(".login-controll").click(function () {
    //   const openDiv = $(this).attr("data-attr");
    //   $(".form-warper").hide();
    //   $(openDiv).show();
    // });

  }

  sendVerificationOTP(): void {  //Done
    this.is_loading = true;
    const resendOtpObject = {
      emailId: this.otpEmail
    }
    this.authenticationService.sendOTPEmail(resendOtpObject).subscribe(
      response => {
        if (response !== undefined) {
          if (response.success == true) {
            $('#otp').modal('show');
            this.successResponse(response.message);
            this.is_loading = false;
          } else {
            this.errMessageResponse(response.message);
            this.is_loading = false;
          }
        } else {
          console.log(response);
        }
      },
      err => { this.is_loading = false; }
    );
  }

  public resendVerificationOTP(): void {
    this.is_loading = true;

    const resendOtpObject = {
      emailId: this.otpEmail
    }

    this.authenticationService.sendOTPEmail(resendOtpObject).subscribe(
      response => {
        if (response !== undefined) {
          if (response.success === true) {
            this.successResponse(response.message);
            this.is_loading = false;
          } else {
            this.errMessageResponse(response.message);
            this.is_loading = false;
          }
        }
      },
      err => { this.is_loading = false; }
    );
  }

  registerNewUser(): void {
    this.is_loading = true;
    const registerObj = new registerRequest();
    // verifyOtp.email = this.registrationForm.value['email'];
    // verifyOtp.device_type = '2';
    // verifyOtp.first_name = this.registrationForm.value['first_name'];
    // verifyOtp.last_name = this.registrationForm.value['last_name'];
    // verifyOtp.password = this.registrationForm.value['password'];
    // verifyOtp.dob = moment(this.registrationForm.value['dob']).format('MM DD YYYY');
    // verifyOtp.otp = this.otpform.value['otp'];

    // const registerRequestObject = {
    registerObj.emailId = this.registrationForm.value['email'],
      registerObj.password = this.registrationForm.value['password'],
      registerObj.firstName = this.registrationForm.value['first_name'],
      registerObj.lastName = this.registrationForm.value['last_name'],
      registerObj.dateOfBirth = moment(this.registrationForm.value['dob']).format('YYYY-MM-DD'),
      registerObj.loginSource = '3',
      registerObj.deviceType = '1',
      registerObj.city = this.getLocation.city,
      registerObj.state = this.getLocation.region,
      registerObj.country = this.getLocation.country,
      registerObj.zip = this.getLocation.postal,
      // registerObj.roleId = '1'
      // } 
      this.authenticationService.register(registerObj).subscribe(
        res => {
          if (res !== undefined) {
            if (res.success == true) {
              this.otpEmail = this.registrationForm.value['email'];
              $('#otp').modal('show');
              this.successResponse(res.message);
              this.is_loading = false;
              (<any>window).gtag_report_conversion(window.location.href);
            } else {
              this.errMessageResponse(res.message);
              this.is_loading = false;
              (<any>window).gtag_report_conversion(window.location.href);
            }
            // this.sendVerificationOTP(registerObj.emailId);
            // if (res.status) {
            //   $('#otp').modal('hide');
            //   this.successResponse(res.msg);
            //   this.is_loading = false;
            //   this.registrationForm.reset();
            //   $('#signupActive').removeClass('active');
            //   $('#signinActive').addClass('active"');

            //   $('#signin').css('display', 'block');
            //   $('#signup').css('display', 'none');
            //   BaseComponent.isLogin = true;
            //   this.isLogin = true;
            //   BaseComponent.user = res.user_id;
            //   this.setUserId(res.user_id);
            //   BaseComponent.user = this.getUserId();
            //   var body = res.token;
            //   var base64Url = body.split('.')[1];
            //   var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            //   var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            //     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            //   }).join(''));
            //   this.refereshToken = JSON.parse(jsonPayload);

            //   var dt = new Date(this.refereshToken['exp'] * 1000);
            //   BaseComponent.userTime = moment(dt).subtract(50, 'seconds').format('HH:mm:ss').toString();

            //   this.setUserToken('Bearer ' + res.token);
            //   this.is_loading = false;
            //   this.headerService.reloadHeader.emit(true);
            //   this.headerService.setLogin.emit(true);
            //   this.router.navigate(['', 'home']);
            // } else {
            //   this.errMessageResponse(res.msg);
            //   this.is_loading = false;
            // }
          }
        },
        err => {
          this.is_loading = false;
        }
      );
  }

  profileDetailsUser(): void {
    this.is_loading = true;
    const profileDetailsObj = new profileDetailsRequest();
    profileDetailsObj.id = this.user_id,
      profileDetailsObj.emailId = this.profileDetailsForm.value['email'],
      // profileDetailsObj.password = this.profileDetailsForm.value['password'],
      profileDetailsObj.firstName = this.profileDetailsForm.value['first_name'],
      profileDetailsObj.lastName = this.profileDetailsForm.value['last_name'],
      profileDetailsObj.dateOfBirth = moment(this.profileDetailsForm.value['dob']).format('YYYY-MM-DD'),
      profileDetailsObj.resetPassword = this.profileDetailsForm.value['confirm_password']
    this.authenticationService.profileDetails(profileDetailsObj).subscribe(
      res => {
        if (res !== undefined) {
          if (res.success == true) {
            this.otpEmail = this.profileDetailsForm.value['email'];
            this.successResponse(res.message);
            this.is_loading = false;
            this.router.navigate(['/home']);
            window.location.reload();
          } else {
            this.errMessageResponse(res.message);
            this.is_loading = false;
          }
        }
      },
      err => {
        this.is_loading = false;
      }
    );
  }

  veryfyOTP(): void {
    this.is_loading = true;
    const veryfyOTPObj = new VerifyOtp();
    // verifyOtp.email = this.registrationForm.value['email'];
    // verifyOtp.device_type = '2';
    // verifyOtp.first_name = this.registrationForm.value['first_name'];
    // verifyOtp.last_name = this.registrationForm.value['last_name'];
    // verifyOtp.password = this.registrationForm.value['password'];
    // verifyOtp.dob = moment(this.registrationForm.value['dob']).format('MM DD YYYY');
    // verifyOtp.otp = this.otpform.value['otp'];

    // const registerRequestObject = {
    veryfyOTPObj.emailId = this.otpEmail,
      veryfyOTPObj.otp = this.otpform.value['otp'],
      // } 
      this.authenticationService.verifyOTP(veryfyOTPObj).subscribe(
        res => {
          if (res !== undefined) {
            if (res.success == true) {
              $('#otp').modal('hide');
              this.successResponse(res.message);
              this.is_loading = false;
              this.registrationForm.reset();
              $('#signupActive').removeClass('active');
              $('#signinActive').addClass('active"');

              $('#signin').css('display', 'block');
              $('#signup').css('display', 'none');
              BaseComponent.isLogin = true;
              this.isLogin = true;
              BaseComponent.user = res.data[0].userId;
              this.setUserId(res.data[0].userId);
              BaseComponent.user = this.getUserId();
              var body = res.data[0].token;
              var base64Url = body.split('.')[1];
              var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
              var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
              }).join(''));
              this.refereshToken = JSON.parse(jsonPayload);

              var dt = new Date(this.refereshToken['exp'] * 1000);
              BaseComponent.userTime = moment(dt).subtract(50, 'seconds').format('HH:mm:ss').toString();

              this.setUserToken('Bearer ' + res.data[0].token);
              this.is_loading = false;
              this.headerService.reloadHeader.emit(true);
              this.headerService.setLogin.emit(true);
              if (this.lastDestinationUrl != '/set-new-password' && this.lastDestinationUrl != '/forgot-password' && this.lastDestinationUrl != '/auth' && this.lastDestinationUrl != null) {
                this.router.navigate([this.lastDestinationUrl]);
              } else {
                this.router.navigate(['', 'home']);
              }

            } else {
              this.errMessageResponse(res.message);
              this.is_loading = false;
            }
          }
        },
        err => {
          this.is_loading = false;
        }
      );
  }

  loginUser(loginform: FormGroup): void {
    this.is_loading = true;
    const signin = new SignIn();
    const loginRequest = {
      emailId: this.loginform.value.email_id,
      password: this.loginform.value.password,
      loginSource: '3'
    }
    // signin.emailId = this.loginform.value['email_id'];
    // signin.password = this.loginform.value['password'];
    // signin.loginSource = '3';

    this.authenticationService.userLogin(loginRequest).subscribe(
      res => {
        if (res !== undefined) {
          if (res.success == true) {
            this.loginform.reset();
            console.log('test', res);
            BaseComponent.isLogin = true;
            BaseComponent.profileComplete = true;
            this.isLogin = true;
            BaseComponent.user = res.data[0].id;
            this.setUserId(res.data[0].id);
            BaseComponent.user = this.getUserId();
            var body = res.data[0].token;
            var base64Url = body.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            this.refereshToken = JSON.parse(jsonPayload);

            var dt = new Date(this.refereshToken['exp'] * 1000);
            BaseComponent.userTime = moment(dt).subtract(50, 'seconds').format('HH:mm:ss').toString();
            console.log('res.data[0].token', res.data[0].token);
            console.log('res.data', res.data);
            this.setUserToken('Bearer ' + res.data[0].token);
            this.user_id = res.data[0].id;
            this.successWithoutTitleResponse(res.message);
            // res.data[0].status="Incomplete";
            if (res.data[0].status == "Incomplete") {
              this.profileStatus = false;
            }
            else {
              this.profileStatus = true;
            }
            if (!this.profileStatus) {
              this.is_loading = false;
              BaseComponent.profileComplete = false;
            }
            else if (this.profileStatus) {
              // this.profileStatus="Incomplete";
              this.is_loading = false;
              this.headerService.reloadHeader.emit(true);
              this.headerService.setLogin.emit(true);
              setTimeout(() => {
                if (this.profileStatus) {
                  if (this.lastDestinationUrl != '/set-new-password' && this.lastDestinationUrl != '/forgot-password' && this.lastDestinationUrl != '/auth' && this.lastDestinationUrl != null) {
                    this.router.navigate([this.lastDestinationUrl]);
                  } else {
                    this.router.navigate(['', 'home']);
                  }
                }
              })
            }

          } else {
            this.errMessageResponse(res.message);
            this.is_loading = false;
          }
        }
      },
      error => {
        if (error.status == 400) {
          this.is_loading = false;
          this.otpEmail = this.loginform.value.email_id;
          // this.sendVerificationOTP();
          this.errMessageResponse('Invalid Credentials');
        } else if (error.status == 401) {
          this.errMessageResponse('Invalid Credentials');
        }
        // console.log(error);
        this.is_loading = false;
      }
    );
  }

  public socialSignIn(socialPlatform: string, originFrom: string): void {
    // let socialPlatformProvider;
    // let loginSource;
    // this.authService.signOut();
    if (socialPlatform == 'facebook') {
      this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(userData => {
        if (userData !== undefined) {
          this.socialLogin(userData, originFrom, '2');
        }
      });
    } else if (socialPlatform == 'google') {
      this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(userData => {
        if (userData !== undefined) {
          this.socialLogin(userData, originFrom, '1');
        }
      });
    }
  }

  public socialLogin(data, originFrom: string, loginSource: string): void {
    this.is_loading = true;
    const pageRequest = { emailId: data.email };
    this.authenticationService.checkRegistreUser(pageRequest).subscribe(res => {
      if (
        res !== undefined &&
        res.success === true &&
        originFrom != 'social_login'
      ) {
        this.errMessageResponse(res.message);
        this.is_loading = false;
        return;
      } else if (
        res !== undefined &&
        res.success === true &&
        originFrom == 'social_login'
      ) {
        this.sendSocialLogin(data, loginSource);
      } else if (
        res !== undefined &&
        res.success === false &&
        originFrom == 'social_register'
      ) {
        this.sendSocialRegister(data, loginSource);
      } else if (
        res !== undefined &&
        res.success === false &&
        originFrom == 'social_login'
      ) {
        this.errMessageResponse(res.message);
        this.is_loading = false;
        return;
      }
    });
  }


  public sendSocialRegister(data, loginSource) {

    const registerRequest = {
      emailId: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      // dateOfBirth: "",
      loginSource: loginSource,
      deviceType: '1',
      accessToken: data.authToken,
      city: this.getLocation.city,
      state: this.getLocation.region,
      country: this.getLocation.country,
      zip: this.getLocation.postal,
      // roleId: 
    }

    this.authenticationService.register(registerRequest).subscribe(
      res => {
        if (res !== undefined) {
          if (res.success === true) {
            BaseComponent.isLogin = true;
            this.isLogin = true;
            BaseComponent.user = res.data[0].id;
            this.setUserId(res.data[0].id);
            BaseComponent.user = this.getUserId();
            var body = res.data[0].token;
            var base64Url = body.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            this.refereshToken = JSON.parse(jsonPayload);

            var dt = new Date(this.refereshToken['exp'] * 1000);
            BaseComponent.userTime = moment(dt).subtract(50, 'seconds').format('HH:mm:ss').toString();
            this.setUserToken('Bearer ' + res.data[0].token);
            this.successResponse(res.message);
            this.is_loading = false;
            this.headerService.reloadHeader.emit(true);
            this.headerService.setLogin.emit(true);
            if (this.lastDestinationUrl != '/set-new-password' && this.lastDestinationUrl != '/forgot-password' && this.lastDestinationUrl != '/auth' && this.lastDestinationUrl != null) {
              this.router.navigate([this.lastDestinationUrl]);
            } else {
              this.router.navigate(['', 'home']);
            }
            this.is_loading = false;
          } else {
            this.errMessageResponse(res.message);
            this.is_loading = false;
          }
        }
      },
      err => {
        if (err.status == 400) {
          this.is_loading = false;
          var base64Url = err.error.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
          var response = JSON.parse(jsonPayload);
          this.errMessageResponse(response.message);
          // this.otpEmail = this.loginform.value.email_id;
          // this.sendVerificationOTP();
        }
      })
  }

  public sendSocialLogin(data, loginSource) {
    const loginRequest = {
      emailId: data.email,
      loginSource: loginSource,
      accessToken: data.authToken
    }

    this.authenticationService.userLogin(loginRequest).subscribe(res => {
      if (res !== undefined) {
        if (res.success === true) {
          BaseComponent.isLogin = true;
          this.isLogin = true;
          BaseComponent.user = res.data[0].id;
          this.setUserId(res.data[0].id);
          BaseComponent.user = this.getUserId();
          var body = res.data[0].token;
          var base64Url = body.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
          this.refereshToken = JSON.parse(jsonPayload);

          var dt = new Date(this.refereshToken['exp'] * 1000);
          BaseComponent.userTime = moment(dt).subtract(50, 'seconds').format('HH:mm:ss').toString();
          this.setUserToken('Bearer ' + res.data[0].token);
          this.successResponse(res.message);
          // this.resetPasswordAfterLogin=res.data[0].resetPassword;
          // this.updateProfileAfterLogin=res.data[0].updateProfile;
          this.is_loading = false;
          this.headerService.reloadHeader.emit(true);
          this.headerService.setLogin.emit(true);
          if (this.lastDestinationUrl != '/set-new-password' && this.lastDestinationUrl != '/forgot-password' && this.lastDestinationUrl != '/auth' && this.lastDestinationUrl != null) {
            this.router.navigate([this.lastDestinationUrl]);
          }
          // else if(this.resetPasswordAfterLogin || this.updateProfileAfterLogin)
          // {
          //   this.is_loading = false;
          // }
          else {
            this.router.navigate(['', 'home']);
          }
          this.is_loading = false;
        } else {
          this.errMessageResponse(res.message);
          this.is_loading = false;
        }
      }
    },
      err => {
        if (err.status == 400) {
          this.is_loading = false;
          var base64Url = err.error.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
          var response = JSON.parse(jsonPayload);
          this.errMessageResponse(response.message);
          // this.otpEmail = this.loginform.value.email_id;
          // this.sendVerificationOTP();
        } else if (err.status == 401) {
          this.sendSocialRegister(data, loginSource);
        }
        console.log(err);
        this.is_loading = false;
      }
    );
  }

  public goToHomePage() {
    if (this.navigationData != undefined) {
      if (this.lastDestinationUrl == '/set-new-password'){
        this.location.back();
      }
      for (const navigate of this.navigationData.data) {
        if (navigate.title.trim().toUpperCase() === 'HOME') {
          // this.router.navigate([''], {
          //   queryParams: { path: navigate.path, type: navigate.type }
          // });
          this.router.navigate(['', navigate.title.trim().toLowerCase()]);
          return;
        }
      }
    } else {
      this.getNavigation();
    }
  }

  public getNavigation(): void {
    // this.NavigationParams.set('deviceTypeId', '1');
    // this.NavigationParams.set('langaugeCode', '1');
    const navigationObject = {
      deviceTypeId: 1,
      langaugeCode: 1
    }
    this.headerService.getNavigation(navigationObject).subscribe(
      res => {
        if (res !== undefined) {
          this.navigationData = res.data;
          this.logo = res.data.logo;
          if (this.logo != undefined) {
            this.footerService.reloadFooter.emit(res.data.logo);
          }
          this.footerService.reloadFooter.subscribe(res => {
            console.log('reloadFooter');
          });
          BaseComponent.globalNavigationData = res.data;

          // var data = btoa(res);
          // console.log(data);
          // var jsondata = atob(data);
          // console.log(JSON.parse(JSON.stringify(jsondata)));

          for (const navigate of this.navigationData.data) {
            if (navigate.title.toUpperCase() === 'HOME') {
              var url = this.router.url.split('?')[0];
              if (url == '/' || url == '')
                // this.router.navigate([''], {
                //   queryParams: { path: navigate.path, type: navigate.type }
                // });
                this.router.navigate(['', navigate.title.toLowerCase()]);
              // this.getPageCategorie(navigate, false);
              // const caarousalRequest = {
              //   path: navigate.path,
              //   type: navigate.type
              // };
              // this.headerService.setHomePagePath.emit(caarousalRequest);
              return;
            }
          }
        }
      },
      err => {
        console.log(err);
      }
    );
  }
}
