import { OrderByPipe } from './../pipe/order-by.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VuuzleTvComponent } from '@vuuzle-tv/component/vuuzle-tv.component';
import { currentTimePipe } from '@vuuzle-tv/pipe/current_time.pipe';
import { DateFilterPipe } from '@vuuzle-tv/pipe/show_date_filter.pipe';
import { StaticDate } from '@vuuzle-tv/pipe/main_date.pipe';
import { UtcToLocalTimePipe } from '@vuuzle-tv/pipe/utc_to_local_time.pipe';

@NgModule({
  declarations: [
    VuuzleTvComponent,
    OrderByPipe,
    currentTimePipe,
    DateFilterPipe,
    StaticDate,
    UtcToLocalTimePipe
  ],
  imports: [CommonModule],
  exports: [VuuzleTvComponent]
})
export class VuuzleTvModule { }
