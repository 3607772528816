import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from '../component/search.component';
import { SearchService } from '../services/search.service';




@NgModule({
  declarations: [SearchComponent],
  imports: [
    CommonModule
  ], exports: [SearchComponent],
  providers: [SearchService]
})
export class SearchModule { }
