import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector } from '@angular/core';
import { AppComponent } from '@app/component/app.component';
import { AppRoutingModule } from '@app/module/app.routing.module';
import { BaseModule } from '@base/module/base.module';
import { FooterModule } from '@footer/module/footer.module';
import { ProfileModule } from '@profile/module/profile.module';
import { HeaderModule } from '@header/module/header.module';
import { LiveConcertModule } from '@live-concert/module/live-concert.module';
import { VideoPageModule } from '@video-page/module/video-page.module';
import { VuuzleTvModule } from '@vuuzle-tv/module/vuuzle-tv.module';
import { ServiceLocator } from '@base/constants/service-locator';
import { EditProfileModule } from '@profile/edit-profile/module/edit-profile.module';
import { HomeModule } from '@home/module/home.module';
import { PremiumModule } from '@premium/module/premium.module';
import { AuthenticationModule } from '@authentication/module/authentication.module';
import { ToastService } from '@base/services/toast.service';
import { AssetGroupDetailsModule } from '@asset-detail/module/asset-group-details.module';
import { GenresListingModule } from '@genres-listing/module/genres-listing.component';
import { SearchModule } from 'src/app/search/module/search.module';
import { FootergenreModule } from '@footer/footergenre/module/footergenre.module';
import { FooterLinkContentModule } from '../../footerLinkContent/module/footerLinkContent.module';
import { InterceptorService } from '@base/services/interceptor.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { WatchlistModule } from '@watchlist/module/watchlist.module';
import { FavoritesModule } from '@favorite/module/favorites.module';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BaseModule,
    HeaderModule,
    HomeModule,
    FooterModule,
    ProfileModule,
    LiveConcertModule,
    VideoPageModule,
    VuuzleTvModule,
    EditProfileModule,
    PremiumModule,
    AuthenticationModule,
    AssetGroupDetailsModule,
    GenresListingModule,
    SearchModule,
    FootergenreModule,
    FooterLinkContentModule,
    WatchlistModule,
    FavoritesModule
  ],
  providers: [
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
  }
}
