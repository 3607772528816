import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { ProfileService } from '@profile/service/profile.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { GetWatchlistData } from '@profile/model/profile.model';
import { PageData } from '@home/model/pagecategory.model';

@Component({
  selector: 'app-watchlist',
  templateUrl: '../view/watchlist.component.html',
  styleUrls: ['../css/watchlist.component.css']
})
export class WatchlistComponent extends BaseComponent implements OnInit {

  public profileService: ProfileService;
  watchlistData: GetWatchlistData;
  loading: boolean;


  constructor(private rendrer: Renderer2,
              @Inject(DOCUMENT) private document: Document) {
    super();
    this.profileService = ServiceLocator.injector.get(ProfileService);
  }

  ngOnInit() {
    this.rendrer.removeClass(this.document.body, 'transperent-layout');
    this.rendrer.removeClass(this.document.body, 'hide-header');
    this.rendrer.removeClass(this.document.body, 'hide-footer');
    window.scroll(0, 0);
    this.getWatchlist();
  }


  public getWatchlist(): void {
    this.loading = true;
    const requestWatchlistData = {
      "deviceTypeId": "1"
    };
    let fiterWatchlistString = "?filter="+encodeURI(JSON.stringify(requestWatchlistData));
    this.profileService.getWatchlist(fiterWatchlistString).subscribe(
      res => {
        if (res !== undefined && res.success === true) {
          this.watchlistData = res.data;
          this.loading = false;
        } else if (res !== undefined && res.success === false) {
          this.loading = false;
        }
      },
      err => {
        console.log(err);
        this.loading = false;
      }
    );
  }

  public gotoVideoPage(pagedata: PageData): void {
      this.router.navigate(['/video', pagedata.title.split(/\s/).join('').toLowerCase(), pagedata.path]);
  }

}
