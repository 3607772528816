import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'staticdate',
    pure: true
})

export class StaticDate implements PipeTransform {
    transform(value: any, format: string): any {
        return moment(value, 'ddd | MMM Do YYYY').format(format);
    }
}
