import { BaseComponent } from "@base/component/base.component";
import { ServiceLocator } from "@base/constants/service-locator";
import { Injectable, Input, EventEmitter } from "@angular/core";
import { ApiService } from "@base/services/base.api.service";
import { Observable } from "rxjs";
import {
  METHOD_REFERENCE,
  API_REFERENCE,
  API_PATH
} from "@base/constants/base.constants";
import { VerifyOtp } from "@header/model/header.model";
import { SignIn, SignInResponse } from "@header/model/signin";
import { ForgotPassword } from "@authentication/model/authentication.model";
import { environment } from '@environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class HeaderService {
  public apiService: ApiService;
  public baseComponent: BaseComponent;
  public apiUrl = environment.apiUrl;

  @Input()
  public setHomePagePath: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public reloadHeader: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public setLogin: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public setSearchPath: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public setProfileImg: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public setActivePath: EventEmitter<any> = new EventEmitter<any>();

  constructor(private http: HttpClient) {
    this.apiService = ServiceLocator.injector.get(ApiService);
    this.baseComponent = ServiceLocator.injector.get(BaseComponent);
  }

  public getGeo(): Observable<any> {
    return this.http.get(this.apiUrl+'geo');
  }

  public sendOTPEmail(email: string): Observable<any> {
    const options = this.baseComponent.getRequestOptions(
      METHOD_REFERENCE["GET"],
      API_REFERENCE["authentication"]["sendOTP"] + "?email=" + email,
      null,
      null,
      null,
      null,
      false,
      true,
      API_PATH
    );
    return this.apiService.apiCall(options);
  }

  public signupVerificationOTP(verify: VerifyOtp): Observable<any> {
    const options = this.baseComponent.getRequestOptions(
      METHOD_REFERENCE["GET"],
      API_REFERENCE["authentication"]["verfyOTP"] +
        "?device_type=" +
        verify.device_type +
        "&otp=" +
        verify.otp +
        "&email=" +
        verify.email +
        "&first_name=" +
        verify.first_name +
        "&last_name=" +
        verify.last_name +
        "&dob=" +
        verify.dob +
        "&password=" +
        verify.password +
        "",
      {},
      null,
      null,
      null,
      false,
      true,
      API_PATH
    );
    return this.apiService.apiCall(options);
  }

  public userLogin(signin: SignIn): Observable<SignInResponse> {
    const options = this.baseComponent.getRequestOptions(
      METHOD_REFERENCE["POST"],
      API_REFERENCE["user"]["login"],
      signin,
      null,
      null,
      null,
      false,
      true,
      API_PATH
    );
    return this.apiService.apiCall(options);
  }

  public forgotPassword(forgotpassword: ForgotPassword): Observable<any> {
    const options = this.baseComponent.getRequestOptions(
      METHOD_REFERENCE["POST"],
      API_REFERENCE["user"]["forgotPassword"],
      forgotpassword
    );
    return this.apiService.apiCall(options);
  }

  public getNavigation(navigationObject): Observable<any> {
    const filter =  encodeURI(JSON.stringify(navigationObject));
    const options = this.baseComponent.getRequestOptions(
      METHOD_REFERENCE["GET"],
      API_REFERENCE["homepage"]["navigation"] +"?filter=" + filter,
      {},
      null,
      null,
      null,
      false,
      true,
      API_PATH
    );
    return this.apiService.apiCall(options);
  }

  public getToken(): Observable<any> {
    const options = this.baseComponent.getRequestOptions(
      METHOD_REFERENCE["GET"],
      API_REFERENCE["user"]["usertoken"],
      {},
      null,
      null,
      null,
      false,
      true,
      API_PATH
    );
    return this.apiService.apiCallWihtourtEncryption(options);
  }

  public getUserToken(): Observable<any> {
    const options = this.baseComponent.getRequestOptions(
      METHOD_REFERENCE["GET"],
      API_REFERENCE["profile"]["usertoken"],
      {},
      null,
      null,
      null,
      false,
      true,
      API_PATH
    );
    return this.apiService.apiCall(options);
  }
}
