import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WatchlistComponent } from '@watchlist/component/watchlist.component';
import { ProfileService } from '@profile/service/profile.service';


@NgModule({
  declarations: [WatchlistComponent],
  imports: [
    CommonModule
  ],
  providers: [ProfileService],
  exports: [WatchlistComponent]
})
export class WatchlistModule { }
