import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { FooterService } from '@footer/services/footer.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { FooterModule } from '@footer/module/footer.module';
import { FooterData } from '@footer/model/footer.model';
import { CookieService } from 'ngx-cookie-service';
import { FormGroup, FormControl, Validators, PatternValidator } from '@angular/forms';
import { HeaderService } from '@header/services/header.service';
import * as moment from 'moment';
declare var $: any;
@Component({
  selector: 'app-footer',
  templateUrl: '../view/footer.component.html',
  styleUrls: ['../css/footer.component.css']
})

export class FooterComponent extends BaseComponent implements OnInit {

  footerService: FooterService;
  footerData: FooterData;
  headerService: HeaderService;
  footerLinksData: any;
  footerLinks: any;
  is_loading: boolean;
  year: any = new Date().getFullYear();
  disClaimerFlag = '';
  logo: string;

  public contactUsForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    // mobile: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(10) , Validators.pattern("^[0-9]*$")]),
    message: new FormControl('', [Validators.required])
  });
  refereshToken: any;

  constructor(private cookieService: CookieService) {
    super();
    this.headerService = ServiceLocator.injector.get(HeaderService);
    this.footerService = ServiceLocator.injector.get(FooterService);
  }

  ngOnInit() {
    this.disClaimerFlag = this.cookieService.get('readDisclaimer');
    this.footerService.reloadCookies.subscribe(res => {
      console.log(res);
      if (res) {
        this.disClaimerFlag = '';
      }
    });
    this.footerService.reloadFooter.subscribe(res => {
      if(res) {
        this.logo = res;
      }
    })
    this.logo = BaseComponent.logo;
    setTimeout(() => {
    
      this.getFooterLinksData();
    }, 2000);

  }


  public sendContact(contactform: FormGroup): void {
    this.is_loading = true;
    const pageRequest = {
      fullName: contactform.value['name'],
      email: contactform.value['email'],
      // mobile: contactform.value['mobile'],
      message: contactform.value['message']
    };
    this.footerService.sendContactUs(pageRequest).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          this.successResponse(res.message);
          this.contactUsForm.reset();
          $('#contact-form').modal('hide');
          this.is_loading = false;
        } else if (res !== undefined && res.success == false) {
          this.errMessageResponse(res.message);
          this.is_loading = false;
        }
      },
      err => {
        console.log(err);
        this.is_loading = false;
      }
    );
  }

  public getFooterLinksData(): void {
    // const pageRequest = {
    //   device_type: '2',
    // };
    this.footerService.getFooterLinks().subscribe(
      res => {
        if (res !== undefined) {
          this.footerLinksData = res;
          this.footerLinks = res.data.data;
        }
      },
      err => {
        console.log(err);
      }
    );

  }

  public goToFooterLinkData(footerlink: string, path: string): void {
    if (footerlink === 'About Us') {
      this.router.navigate(['AboutUs', path]);
      // window.open(window.location.origin + '/footer' + '/'+ footerlink.split(" ").join("") +'/' + path, '_blank');
    } 
    else if (footerlink === 'Terms & Conditions') {
      this.router.navigate(['Terms&Conditions', path]);
      // window.open(window.location.origin + '/terms-of-use/' + path, '_blank');
    } else if (footerlink === 'Privacy Policy') {
      // window.open(window.location.origin + '/privacy-policy/' + path, '_blank');
      this.router.navigate(['privacy-policy', path]);
    }
  }

  public openGenresListing(data): void {
    this.router.navigate(['/category', data.title.split(/\s/).join('').toLowerCase(), data.path]);
  }

  setcookie() {
    this.cookieService.set('readDisclaimer', 'true' );
    this.disClaimerFlag = this.cookieService.get('readDisclaimer');
  }

  public getAuthenticateToken(): void {
    this.headerService.getToken().subscribe(
      res => {
        if (res !== undefined) {
          this.setGuestToken('Bearer ' + res);
          var body = res;
          var base64Url = body.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));
          this.refereshToken = JSON.parse(jsonPayload);

          var dt = new Date(this.refereshToken['exp'] * 1000);
          BaseComponent.guestTime = moment(dt).subtract(50, 'seconds').format('HH:mm:ss').toString();
         

        }
      },
      err => {
        console.log(err);
      }
    );
  }

}
