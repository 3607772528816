import { DOCUMENT } from '@angular/common';
import { BaseComponent } from '@base/component/base.component';
import { OnInit, Component, Inject, Renderer2 } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UpdatePassword, ForgotPassword } from '@authentication/model/authentication.model';
import { AuthenticationService } from '@authentication/service/authentication.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { HeaderService } from '@header/services/header.service';
declare var $: any;
@Component({
    selector: 'app-authentication',
    templateUrl: '../view/setnewpassword.component.html',
    styleUrls: ['../css/authentication.component.css']
})
export class SetNewPasswordComponent extends BaseComponent implements OnInit {
    public authenticationService: AuthenticationService;
    headerService: HeaderService;
    is_loading: boolean;

    public updatePasswordform: FormGroup = new FormGroup({
        otp: new FormControl('', [Validators.required, Validators.maxLength(6)]),
        new_password: new FormControl('', [Validators.required,Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,15}')]),
        confirm_new_password: new FormControl('', [Validators.required,Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,15}')])
    }, this.passwordMatchValidator);

    forgotPasswordform: FormGroup = new FormGroup({
        email_id: new FormControl('', [Validators.required, Validators.email])
    });

    public passwordMatchValidator(g: FormGroup) {
        return g.get('new_password').value === g.get('confirm_new_password').value ? null : { 'mismatch': true };
    }

    constructor(private rendrer: Renderer2,
                @Inject(DOCUMENT) private document: Document) {
        super();
        this.headerService = ServiceLocator.injector.get(
            HeaderService
        );
        this.authenticationService = ServiceLocator.injector.get(AuthenticationService);
    }

    ngOnInit() {
        this.rendrer.removeClass(this.document.body, 'transperent-layout');
        this.rendrer.addClass(this.document.body, 'hide-header');
        this.rendrer.addClass(this.document.body, 'hide-footer');
        this.updatePasswordform.reset();
        this.authenticationService = ServiceLocator.injector.get(
            AuthenticationService
        );
    }

    setNewUserPassword(updatePasswordform: FormGroup): void {
        this.is_loading = true;
        const updatePassword = new UpdatePassword();
        updatePassword.emailId = BaseComponent.forgotPasswordEmail;
        updatePassword.otp = this.updatePasswordform.value['otp'];
        updatePassword.password = this.updatePasswordform.value['new_password'];
        this.authenticationService.setNewPassword(updatePassword).subscribe(res => {
            if (res !== undefined) {
                if (res.success == true) {
                    localStorage.removeItem('videoUrl');
                    this.successResponse(res.message);
                    this.router.navigate(['auth']);
                    this.headerService.reloadHeader.emit(true);
                    this.is_loading = false;
                } else {
                    this.errMessageResponse(res.message);
                    this.is_loading = false;
                }
            }
        }, err => {
            console.log(err);
        });
    }

    // public resendOTP(): void {
    //     // this.forgotPasswordform.reset();
    //     $('#resend-otp').modal('show');
    // }

    resetUserPassword(): void {
        this.is_loading = true;
        // const forgot = new ForgotPassword();
        const resendOtpObject = {
            emailId: BaseComponent.forgotPasswordEmail
          }
        BaseComponent.forgotPasswordEmail = BaseComponent.forgotPasswordEmail;
        this.authenticationService.sendOTPEmail(resendOtpObject).subscribe(
            response => {
              if (response !== undefined) {
                if (response.success == true) {
                  $('#otp').modal('show');
                  this.successResponse(response.message);
                  this.is_loading = false;
                } else {
                  this.errMessageResponse(response.message);
                  this.is_loading = false;
                }
              } else {
                console.log(response);
              }
            },
            err => { this.is_loading = false; }
          );
    }

    public gotToLoginPage(): void {
        this.router.navigate(['auth']);
    }
}
