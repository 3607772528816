export const environment = {
  production: false,
  imagePrefix: 'assets/images/',
  //  apiUrl: 'https://vuuzleapi.dcafecms.com/', // node prod
  apiUrl: 'https://bboz5rn1mf.execute-api.us-east-1.amazonaws.com/stage/', //segitv
  //apiUrl: 'https://segitvapi.dcafecms.com/', //segitv
  apiUrlDollywood: 'https://dollywoodplay.admin.demo.dctinc.net/api/v1/',
  apiUrlCarousal: 'https://hungamakids.admin.stage.dctinc.net/api/v1/',
  // googleAuthKey: '247746380086-fbrglglfl6lvcn575u82248khpbef1j2.apps.googleusercontent.com', //prod
  // googleAuthKey:
  //   "695989545489-ehsia82b9l31723f82dfilfm9rtfbo41.apps.googleusercontent.com", //dev
  googleAuthKey:'245596584722-272qddelvcm5j6u7r2tumtvjveaiq271.apps.googleusercontent.com', // dev  // facebookAuthId: "2473374139418803", //prod
  // facebookAuthId: "2473374139418803", //prod
  // facebookAuthId: "2239905339471156", // dev
  theoplayerAddurl: 'https://www10.smartadserver.com/ac?siteid=412186&pgid=1384397&fmtid=100892&ab=2&tgt=&oc=1&out=vast3&ps=1&pb=0&visit=S&vcn=s&vph=1080&vpw=1920&vpmt=2&vpaidv=0&vpaidt=0&srl=www.segi.tv.stage.dctinc.net&buid=com.segi.tv&appname=segi_tv&uid=560288&tmstp=1622227563',
  facebookAuthId: "725353188480585", // nation traffic dev
  stripeKey:"pk_test_51Knni8IjCiXCrbBqXK2ITE5H86ylBBWpwgecJ5NwkzNDqc9OHnyn38i7Xn98YVLNVbZOjolBynwL4nzYImEXlunE00i9xUrah4",
  nationTraffic : "Nation Traffic"
};
