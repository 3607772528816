import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileService } from '@profile/service/profile.service';
import { FavoritesComponent } from '@favorite/component/favorites.component';
import { VideoPageService } from '@video-page/services/videopage.service';


@NgModule({
  declarations: [FavoritesComponent],
  imports: [
    CommonModule
  ],
  providers: [ProfileService, VideoPageService],
  exports: [FavoritesComponent]
})
export class FavoritesModule { }
