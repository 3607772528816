import { Component } from '@angular/core';
import { environment } from '@environments/environment';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: '../view/app.component.html',
  styleUrls: ['../css/app.component.css']
})
export class AppComponent {
  hideDiv = false;
  title = 'vuuzle-frontend';

  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.hideDiv = true;
        // (<any>window).ga('set', 'page', event.urlAfterRedirects);
        // (<any>window).ga('send', 'pageview');
      }
    });
  }
}
