import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPageComponent } from '@video-page/component/video-page.component';
import { VideoPageService } from '@video-page/services/videopage.service';
import { HttpClientModule } from '@angular/common/http';
import { ShareButtonModule } from '@ngx-share/button';
import { VideoPagePlayerComponent } from '@video-page/component/video-page-player.component';


@NgModule({
  declarations: [VideoPageComponent, VideoPagePlayerComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    ShareButtonModule
  ],
  exports: [VideoPageComponent, VideoPagePlayerComponent],
  providers: [VideoPageService]
})
export class VideoPageModule { }
