import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { HeaderService } from '@header/services/header.service';
import { ServiceLocator } from '@base/constants/service-locator';
import { SearchService } from '../services/search.service';
import { SearchData, DataList } from '../model/search.model';
import { Params, NavigationEnd } from '@angular/router';
import { ActivatedRoute, Router } from "@angular/router";
import { tick } from '@angular/core/testing';

@Component({
  selector: 'app-search',
  templateUrl: '../view/search.component.html',
  styleUrls: ['../css/search.component.css']
})
export class SearchComponent extends BaseComponent implements OnInit {


  constructor(_activatedRoute: ActivatedRoute,
              private rendrer: Renderer2,
              @Inject(DOCUMENT) private document: Document) {
    super();
    this.headerService = ServiceLocator.injector.get(HeaderService);
    this.searchService = ServiceLocator.injector.get(SearchService);


    this.route = _activatedRoute;

  }


  headerService: HeaderService;
  searchService: SearchService;
  searchData: SearchData;
  public searchSubscription: any;
  searchText: string;
  public is_loading: boolean;
  skip: number;
  viewmore: boolean;

  ngOnInit() {
    this.rendrer.removeClass(this.document.body, 'transperent-layout');
    this.rendrer.removeClass(this.document.body, 'hide-header');
    this.rendrer.removeClass(this.document.body, 'hide-footer');
    this.route.params.subscribe((params: Params) => {
      var query = params['query'];
      this.searchData = null;
      window.scroll(0, 0);
      this.searchData = null;

      this.skip = 0;
      this.getSearchResult(query, 0, false);

      // this.headerService.setSearchPath.subscribe(res => {
      //   if (res !== undefined) {

      //   } else {
      //     console.log('Error');
      //   }
      // });

    });

  }

  public getSearchResult(searchpath: string, skip: number, setdata: boolean): void {
    this.searchText = searchpath;
    if (setdata) {
      this.is_loading = true;
    }

    this.skip = this.skip + skip;

    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    const SearchObject = {"keyword":searchpath}

    let fiterSearchString = "?filter="+encodeURI(JSON.stringify(SearchObject)); 

    this.searchSubscription = this.searchService.getSearchResult(fiterSearchString).subscribe(
      res => {
        if (res !== undefined && res.success === true) {
          if (res.data.data != null) {

            if (res.data.data.length === 50) {
              this.viewmore = true;
            } else {
              this.viewmore = false;
            }
            if (setdata) {
              res.data.data.forEach(record => {
                this.searchData.data.push(record);
              });
              this.is_loading = false;
            } else {
              this.searchData = res.data.data;
            }
            this.is_loading = false;
          } else {
            this.searchData = new SearchData();
            this.searchData.data = [];
            this.is_loading = false;
          }
        } else if ((res !== undefined && res.status === false)) {
          if (res['msg'] != '') {
            this.searchData = new SearchData();
            this.searchData.data = [];
            this.is_loading = false;
          } else {
            this.is_loading = false;
            this.searchData = null;
          }
        }
      },
      err => {
        this.is_loading = false;
        console.log('errror');
        console.log(err);
      }
    );
  }


  public gotoVideoPage(pagedata: DataList): void {
    if (pagedata.type === 'asset' && pagedata.asset_group_id == null) {
      this.router.navigate(['/video', pagedata.title.split(/\s/).join(''), pagedata.path]);
    } else if (pagedata.type === 'asset_group') {
      this.router.navigate(['/season', pagedata.path]);
    }
    else if (pagedata.asset_group_id != null &&  pagedata.asset_group_id > 0) {
      localStorage.setItem('searchUrl', pagedata.path);
      localStorage.setItem('searchUrlCat', pagedata.asset_category_id);
      this.router.navigate(['/season', pagedata.asset_group_id]);
    }
  }

}
