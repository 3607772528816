import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from '@profile/component/profile.component';
import { ProfileService } from '@profile/service/profile.service';
import { RouterModule } from '@angular/router';
import { BaseModule } from '@base/module/base.module';
import { UtcToLocalDateProfilePipe } from '@profile/pipe/UtcToLocalDateProfilePipe';

@NgModule({
  declarations: [ProfileComponent, UtcToLocalDateProfilePipe],
  imports: [CommonModule, RouterModule, BaseModule],
  exports: [ProfileComponent],
  providers: [ProfileService]
})
export class ProfileModule { }
