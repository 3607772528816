import { CookieService } from 'ngx-cookie-service';
import { DOCUMENT } from '@angular/common';
import { Component, OnInit, OnDestroy, ViewChild, Renderer2, Inject } from '@angular/core';
import { BaseComponent } from '@base/component/base.component';
import { VideoPageService } from '@video-page/services/videopage.service';
import { Params, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ServiceLocator } from '@base/constants/service-locator';
import {
  VideoPage,
  VideoPageData,
  RelatedVideo,
  CastCrewsData,
  CastCrews
} from '@video-page/model/video-page';
import { Url } from 'url';
import { PageData, DataGenre } from '@home/model/pagecategory.model';
import { HeaderService } from '@header/services/header.service';
import { FavoritesModule } from '@favorite/module/favorites.module';
import * as _ from 'underscore';
import { environment } from '@environments/environment';
import { error } from 'protractor';

declare var $: any;
declare const bitmovin: any;
declare var THEOplayer:any;

@Component({
  selector: 'app-video-page',
  templateUrl: '../view/video-page.component.html',
  styleUrls: ['../css/video-page.component.css']
})
export class VideoPageComponent extends BaseComponent implements OnInit, OnDestroy {
  public videopageService: VideoPageService;
  assetPath: string;
  genrepath: any = [];
  primaryNavigatiopath: any = [];
  menuCategotypath: any = [];
  videopageData: VideoPageData;
  videodata: VideoPage;
  relatedVideoData: any;
  suggestedvideos: any;
  playerVisible: boolean;
  videoTitle = '';
  public videoURL: string;
  public videoPoster: string;
  playerObject: any;
  castcrewData: CastCrews;
  director: string;
  cast: string;
  subScriptionData = [];
  handler: any;
  isPremium: boolean;
  casUserWatchAsset: boolean;
  rented: boolean;
  csaiAdServer: string;

  watchListTimeBitmovin: number = 5;
  addedToWatchList: any = 0;
  bookmark_duration: any;
  headerService: HeaderService;
  trailerPlayBackUrl: string;
  isShowVideo: boolean = false;
  isShowImage: boolean = false;
  watchMovie: boolean = false;
  setBookMark: boolean = false;
  same_for_all_geo_zone: boolean = false;
  showVideoError: boolean = false;
  geoStatus: boolean = false;
  user_can_see_asset: boolean = false;
  isIncreaseCount: boolean = false;
  showButton: boolean;
  playTimeOut: any;
  bitMovieUnsubsribe: any;
  noGenre = false;
  isUserLikes: any;
  showTrailerWithoutPayment = false;
  user: boolean;

  SelectedPath: any;
  genreTitle = '';
  videoFinish: any;
  seconds = 5;
  replay: any;
  secondInterval: any;
  secondsTimeout: any;
  clickOntrailer = false;

  noRelatedData = false;
  tvodasset=false;
  subscriptionType = '';
  expiryDate : any;
  tvodRate: any;
  tvodDays: any;

  constructor(activateRoute: ActivatedRoute,
    private rendrer: Renderer2,
    private cookieService: CookieService,
    @Inject(DOCUMENT) private document: Document) {
    super();
    this.videopageService = ServiceLocator.injector.get(VideoPageService);
    this.headerService = ServiceLocator.injector.get(HeaderService);
    this.route = activateRoute;
  }

  public reloadedVideoPlayer(): void { }

  ngOnInit() {
    this.rendrer.removeClass(this.document.body, 'transperent-layout');
    this.rendrer.removeClass(this.document.body, 'hide-header');
    this.rendrer.removeClass(this.document.body, 'hide-footer');
    if (localStorage.getItem('user_token')) {
      this.user = true;
} else {
      this.user = false;
    }
    $(document).ready(function () {
      $(this).scrollTop(0);

    });

    this.route.params.subscribe((params: Params) => {
      this.videoFinish = false;
      this.replay = false;
      this.clickOntrailer = false;
      this.seconds = 5;
      this.csaiAdServer = '';
      this.videopageData = null;
      this.suggestedvideos = null;
      this.relatedVideoData = null;
      this.subScriptionData = null;
      this.showTrailerWithoutPayment = false;
      this.genreTitle = '';
      this.videoURL = '';
      this.addedToWatchList = 0;
      if (this.videopageData != null) {
        this.videopageData[0].playbackUrl = '';
        this.videopageData[0].trailerPlayBackUrl = '';
        this.videopageData[0].horizontalFilePath = '';
      }
      this.trailerPlayBackUrl = '';
      this.videoPoster = '';
      this.playerObject = undefined;
      this.casUserWatchAsset = false;

      window.scroll(0, 0);
      this.assetPath = params['path'];
      this.getPremiumForSubscriptionList();
      const assetDetailRequest = { deviceTypeId: 1 };
       const cookie = this.cookieService.get('readDisclaimer');
      const assetDetailFiterString = "?filter=" + encodeURI(JSON.stringify(assetDetailRequest));
      //debugger;
      this.videopageService.getAssetDetails(this.assetPath, assetDetailFiterString).subscribe(
        res => {
          if (res !== undefined && res.success == true) {
            $('.video-js').css('display', 'none');
            $('.DESCDiv').css('display', 'none');
            this.videodata = res;
            this.videopageData = res.data;
            this.addedToWatchList = this.videopageData[0].isUserWatched;
            this.videoURL = this.videopageData[0].playbackUrl;
            this.videoPoster = this.videopageData[0].vodOrLivePosterImageFilePath;
            this.bookmark_duration = this.videopageData[0].bookmarkDuration;
            if (this.bookmark_duration == null) {
              this.bookmark_duration = '0';
            }
            this.trailerPlayBackUrl = this.videopageData[0].mapTrailerVideo;
            if (this.trailerPlayBackUrl == '') {
              this.showButton = false;
            } else if (this.trailerPlayBackUrl == null) {
              this.showButton = false;
            } else if (!this.trailerPlayBackUrl) {
              this.showButton = false;
            } else {
              this.showButton = true;
            }
            this.isUserLikes = this.videopageData[0].isUserLikes;
            this.isPremium = this.videopageData[0].isPremium;
            this.casUserWatchAsset = this.videopageData[0].casUserWatchAsset;
            this.csaiAdServer = this.videopageData[0].csaiAdServer;
            this.geoStatus = this.videopageData[0].geoStatus;

            this.getRelatedSuggestedVideos();
            this.getCastCrew(this.videopageData[0].path);
            this.getGenreTitle(this.videopageData);

            $('#posterImage').css('display', '');
            if (this.videopageData[0].isPremium) {
              if (this.videopageData[0].subscription_type) {
                this.subscriptionType = this.videopageData[0].subscription_type;
              }
           }

      
          }
        },
        err => {
          console.log(err);
        }
      );
    });

    $(document).ready(function () {
      $('.vid-description a').click(function () {
        window.open(this.href);
        return false;
      });
    });
  }

  seekPlayer(duration, videoDuration: boolean, setBookMark: boolean) {

    if (videoDuration) {
      if (setBookMark) {
        if ((this.playerObject.getDuration() - this.bookmark_duration) > 30) {
          this.playerObject.seek(this.playerObject.getCurrentTime() + this.bookmark_duration);
        } else {
          this.playerObject.seek(this.playerObject.getCurrentTime() + duration);
        }
      }
    } else {
      this.playerObject.seek(this.playerObject.getCurrentTime() + duration);
    }


  }

  nextVideo() {
    for (let relatedVideo of this.suggestedvideos) {
      if (relatedVideo.path != this.assetPath) {
        if (relatedVideo.type === 'asset') {
          this.router.navigate(['/video', relatedVideo.title.split(/\s/).join(''), relatedVideo.path]);
          break;
        } else if (relatedVideo.type === 'asset_group') {
          this.router.navigate(['/season', relatedVideo.path]);
        }
      }
    }
  }

  replayVideo() {
    this.seconds = 5;
    $('#premium-id').removeClass('premium-vod');
    this.replay = true;
    this.videoFinish = false;
    clearInterval(this.secondInterval);
    clearTimeout(this.secondsTimeout);
  }

  refreshHorizontalCarousel(carouselId: string): void {
    $('#' + carouselId).owlCarousel({
      loop: false,
      margin: 21,
      nav: true,
      navText: [
        '<img src="./assets/images/arrow-back.png">',
        '<img src="./assets/images/arrow-next.png">'
      ],
      autoplay: false,
      autoHeight: false,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 2,
          nav: false
        },
        768: {
          items: 4
        },
        1000: {
          items: 6
        }
      }
    });
  }

  refreshVerticalCarousel(carouselId: string): void {
    $('#' + carouselId).owlCarousel({
      loop: false,
      margin: 13,
      nav: true,
      navText: [
        '<img src="./assets/images/arrow-back.png">',
        '<img src="./assets/images/arrow-next.png">'
      ],
      autoplay: false,
      autoHeight: false,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 2,
          nav: false
        },
        768: {
          items: 3
        },
        1000: {
          items: 5
        }
      }
    });
  }

  public getAssetDetails(path: string): void {

  }

  public addTofavourite(path: string): void {
    let userId: any = this.getUserId();
    if (userId > 0) {
      if (this.geoStatus) {
        const pageRequest = {
          assetId: path
        }

        this.videopageService.addTofavourite(pageRequest).subscribe(
          res => {
            if (res !== undefined && res.success === true) {
              this.isUserLikes = 1;
              this.successResponse(res.message);
            } else if (res !== undefined && res.success === false) {
              this.errMessageResponse(res.message);
            }
          },
          err => {
            console.log(err);
          }
        );

      }
    } else {
      this.router.navigate(['auth']);
    }
  }

  public removeTofavourite(path): void {
    let userId: any = this.getUserId();
    if (userId > 0) {
      if (this.geoStatus) {
        const pageRequest = {
          assetId: path
        };

        this.videopageService.removeTofavourite(pageRequest).subscribe(
          res => {
            if (res !== undefined && res.success === true) {
              this.isUserLikes = 0;
              this.successResponse(res.message);
            }
          },
          err => {
            console.log(err);
          }
        );
      }

    }

  }

  public addToWatchlist(): void {
    if (this.watchMovie == true || this.showTrailerWithoutPayment == true) {
      const pageRequest = {
        assetId: this.videopageData[0].path
      };
      this.videopageService.addToWatchlist(pageRequest).subscribe(
        res => {
          if (res !== undefined && res.success == true) {
          }
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  public getRelatedSuggestedVideos(): void {
    this.genrepath = [];
    this.primaryNavigatiopath = [];
    this.menuCategotypath = [];
    console.log("this.videopageData", this.videopageData);
    if (this.videopageData[0].genre.length > 0) {
      // this.genrepath = _.pluck(this.videopageData[0].genre,"genreId");
      const genres = this.videopageData[0].genre
      for (let genre of genres) {
        this.genrepath.push(Number(genre.genreId));
        // this.genreName.push(genre.genreName);
        // this.genreNameList = this.genreName.toString();
      }
    }
    if (this.videopageData[0].primaryNavigation.length > 0) {
      // this.primaryNavigatiopath = this.videopageData[0].primaryNavigation[0].primaryNavigationId;
      const primaryNavigation = this.videopageData[0].primaryNavigation
      for (let navigation of primaryNavigation) {
        this.primaryNavigatiopath.push(Number(navigation.primaryNavigationId));
        // this.genreName.push(genre.genreName);
        // this.genreNameList = this.genreName.toString();
      }
    }
    if (this.videopageData[0].menuCategory.length > 0) {
      // this.menuCategotypath = _.pluck(this.videopageData[0].menuCategory,'menuCategoryId');
      const menuCategory = this.videopageData[0].menuCategory
      for (let category of menuCategory) {
        this.menuCategotypath.push(Number(category.menuCategoryId));
        // this.genreName.push(genre.genreName);
        // this.genreNameList = this.genreName.toString();
      }
    }
    const pageRequest = {
      deviceTypeId: 1,
      genre: this.genrepath,
      languages: this.videopageData[0].langaugeId,
      primaryNavigation: this.primaryNavigatiopath,
      menuCategory: this.menuCategotypath
    };
    let filterString = "?filter=" + encodeURI(JSON.stringify(pageRequest)) + "&start=0&limit=15";  
    this.videopageService.getRelatedVideo(filterString).subscribe(
      res => {
        console.log("res", res);
        if (res !== undefined && res.success === true) {
          this.relatedVideoData = null;
          this.relatedVideoData = res.data.data.filter(v =>
            v.path != this.assetPath);
          setTimeout(() => {
            $('#recommended_carousel_list').trigger('destroy.owl.carousel');
            setTimeout(() => {
              this.refreshHorizontalCarousel('recommended_carousel_list');
            }, 50);
          }, 300);
          if (this.relatedVideoData.length == 0) {
            this.relatedVideoData = null;
            this.noRelatedData = true;
          }
        }
      },
      err => {
        this.noRelatedData = true;
        console.log(err);
      }
    );
  }

  public getCastCrew(assetpath: string): void {
    const pageRequest = { "assetId": assetpath };
    let fiterString = "?filter=" + encodeURI(JSON.stringify(pageRequest));
    this.videopageService.getCastCrews(fiterString).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
          this.castcrewData = res.data;
          this.cast = '';
          this.director = '';
          if (this.castcrewData !== null && this.castcrewData.data) {
            for (const castcrew of this.castcrewData.data) {
              if (castcrew.type == 'Director') {
                if (this.director === '') {
                  this.director = ' ' + this.director + castcrew.firstName + ' ' + castcrew.lastName;;
                } else {
                  this.director = this.director + ', ' + castcrew.firstName + ' ' + castcrew.lastName;;
                }
              } else if (castcrew.type == 'cast') {
                if (this.cast === '') {
                  this.cast = ' ' + this.cast + castcrew.firstName + ' ' + castcrew.lastName;
                } else {
                  this.cast = this.cast + ', ' + castcrew.firstName + ' ' + castcrew.lastName;
                }
              }
            }
          }
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  public gotoVideoPage(pagedata: PageData): void {
    if (pagedata.type.toLowerCase() == 'asset') {
      this.router.navigate(['/video', pagedata.title.split(/\s/).join(''), pagedata.path]);
    } else if (pagedata.type.toLowerCase() == 'asset_group') {
      this.router.navigate(['/season', pagedata.path]);
    }
  }

  public getGenreTitle(videopageData: VideoPageData): string {
    var genreTitle = '';
    const genres = videopageData[0].genre
    if (genres.length > 0) {
      for (let orientation of genres) {
        if (genreTitle != '') {
          genreTitle += ', ';
        }
        genreTitle += orientation.genreName;
      }
    } else {
      this.noGenre = true;
    }
    this.genreTitle = genreTitle + ' ';
    return this.genreTitle;
  }

  public addToBookmark(assetDuration: string): void {
    const pageRequest = {
      assetId: this.videopageData[0].path,
      duration: assetDuration
    };
    this.videopageService.addToBookmark(pageRequest).subscribe(
      res => {
        if (res !== undefined && res.success == true) {
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  public goToHomePage(): void {
    this.headerService.reloadHeader.emit(true);
    this.router.navigate(['']);
  }

  public gotToLoginPage(): void {
    this.warningMessageResponse('Inicia sesión para ver este contenido');
    this.router.navigate(['auth']);
  }

  gotoPlayerPage(pagedata) {
    if (pagedata.assetType.toLowerCase() == 'asset') {
      this.router.navigate(['/video-page', pagedata.title.split(/\s/).join(''), pagedata.path]);
    } else if (pagedata.assetType.toLowerCase() == 'asset_group') {
      this.router.navigate(['/season', pagedata.path]);
    } else {
      this.router.navigate(['/video-page', pagedata.title.split(/\s/).join(''), pagedata.path]);
    }
  }

  public facebookShare(e) {
    var facebookShare = 'https://vuuzle.dcafecms.com';

    e.preventDefault();
    var facebookWindow = window.open('https://www.facebook.com/sharer/sharer.php?u=' + window.location.href,
      'facebook-popup', 'height=350,width=600');
    if (facebookWindow.focus) {
      facebookWindow.focus();

    }
    return false;
  }

  public twitterShare(e) {
    var twitterShare = 'https://vuuzle.dcafecms.com';

    e.preventDefault();
    var twitterWindow = window.open('https://twitter.com/share?url=' + window.location.href,
      'twitter-popup', 'height=350,width=600');
    if (twitterWindow.focus) { twitterWindow.focus(); }
    return false;
  }

  goRent() {
    if (BaseComponent.isLogin == true) {
      $('#rent-list').modal('show');
      this.getPremiumForSubscriptionList();
    } else {
      localStorage.setItem('videoUrl', this.router.url)
      this.router.navigate(['auth']);
    }
  }
  confirmOk(){
    $('#confirm-payment').modal('hide');
    this.ngOnInit();
  }


  getPremiumForSubscriptionList() {
    this.subScriptionData = null;
    const subscriptionRequest = { "deviceTypeId": "1", "assetId": this.assetPath };
    const subscriptionFiterString = "?filter=" + encodeURI(JSON.stringify(subscriptionRequest));
    this.videopageService.getSubScriptionList(subscriptionFiterString).subscribe(res => {
      if (res != undefined && res.success == true) {
        this.subScriptionData = res.data.data;
        if(this.subScriptionData.length>0){
          this.tvodasset=true;
          this.tvodRate = this.subScriptionData[0].rate;
          this.tvodDays = this.subScriptionData[0].validityDays;         
        }
      }
    })
  }

  checkout(item, e) {
    this.handler = (<any>window).StripeCheckout.configure(
      {
        key: environment.stripeKey,
        locale: "auto",
        token: (token: any) => {
          let cardToken = token.id;
          const orderReq = {
            orderId: cardToken,
            assetId: this.assetPath,
            userId: this.getUserId(),
            subscriptionId: item.path,
            isRecurring: 1,
            billingName: BaseComponent.userFName,
            billingEmail: BaseComponent.userEmail,
            status: "Active",
            usageType: "Paid",
            isActive: 1,
            paymentMethod: "stripe"
          };
          this.videopageService.placeOrder(orderReq).subscribe(
            res => {
              if (res !== undefined && res.success === true) {
             this.expiryDate=res.data.expirydate;
                $('#rent-list').modal('hide');
                $('#confirm-payment').modal('show');             
              } else if (res !== undefined && res.success === false) {
                this.errMessageResponse(res.message);
              }
            },
            err => {
              console.log(err);
            }
          );
        },
      }
    );
    let totalAmount = item.rate * 100;
    if (totalAmount > 0) {
      this.handler.open({
        name: "Segi TV",
        description: item.title,
        amount: totalAmount,
        email: BaseComponent.userEmail
      });
    }
    console.log(this.handler);

    e.preventDefault();
  }

  ngOnDestroy() {
    clearTimeout(this.secondsTimeout);
    clearInterval(this.secondInterval);
    if (this.playerObject !== undefined) {
    }
  }
}
